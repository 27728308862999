<template>
    <NavBars />
    <AboutTab />
</template>

<script>
import { defineComponent } from 'vue'

// Components
import NavBars from '@/components/NavBars.vue'
import AboutTab from '@/components/AboutTab.vue'


export default defineComponent({
  components: {
    NavBars, AboutTab
  },
});
</script>