import { initializeApp } from 'firebase/app'
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyCHyhxq51htOUik7MGHxDRk55QJcCWN7mk",
  authDomain: "rpg-troll.firebaseapp.com",
  projectId: "rpg-troll",
  storageBucket: "rpg-troll.appspot.com",
  messagingSenderId: "228686025942",
  appId: "1:228686025942:web:748e52633aa8e86a1e6577"
  }

const app = initializeApp(firebaseConfig)
const storage = getStorage(app)
export { storage }