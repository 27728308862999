<template>
    <h1 class="custom-h1" block>Spielleiter</h1>
    <v-container d-flex>
        <h2 class="custom-h2" block>Kampagnen</h2>
        <v-row no-gutters>
            <v-col cols="12" xs="12" sm="12" md="6" v-for="kampagne in kampagnen" :key="kampagne.id">
                <v-card link style="margin: 8px" @click="OpenKampagne(kampagne.id)">
                    <v-card-title class="d-flex justify-space-between align-center">
                        <div>{{ kampagne.Name }}</div>
                        <v-avatar size="55">
                            <v-img :src="imagePath(kampagne)" />
                        </v-avatar>
                    </v-card-title>
                    <v-card-subtitle>{{ kampagne.Beschreibung }}</v-card-subtitle>
                    <v-card-text style="font-size: 0.5em; text-align: right;">ID: {{ kampagne.id }}</v-card-text>
                </v-card>
            </v-col>
        </v-row>
        <h2 class="custom-h2" block>Neue Kampagne anlegen</h2>
        <v-row>
            <v-col>
                <v-card style="margin: 8px; margin-top: 25px; display: flex; flex-direction: column;">
                    <v-card-title>Neue Kampagne</v-card-title>
                    <v-text-field label="Name" v-model="newKampagneName" />
                    <v-btn @click="newKampagne" :disabled="!newKampagneName" class="custom-button"
                        style="margin: 10px;">Neu</v-btn>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { getFirestore, collection, query, where, /*orderBy,*/ onSnapshot, addDoc, serverTimestamp } from 'firebase/firestore';

export default {
    data() {
        return {
            kampagnen: [],
            newKampagneName: '',
        };
    },

    mounted() {
        if (!this.$store.state.User) {
            console.log('Auf Willkommensseite umgeleitet.')
            this.$router.push({ name: 'home' })
        } else {
            this.loadKampagnen()
        }
    },

    methods: {
        loadKampagnen() {
            const db = getFirestore()
            const q = query(
                collection(db, "Kampagnen"),
                where("User", "==", this.$store.state.User.uid),
                //       orderBy("CreatedAt", "desc")
            )
            onSnapshot(q, (querySnapshot) => {
                this.kampagnen = querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    ...doc.data()
                }));
            });
        },

        async newKampagne() {
            const db = getFirestore();
            const colRefKampagnen = collection(db, 'Kampagnen')

            const contents = [

                ]

            await addDoc(colRefKampagnen, {
                Name: this.newKampagneName,
                User: this.$store.state.User.uid,
                CreatedAt: serverTimestamp(),
                Contents: contents 
            })

            this.$store.commit('callSnackbar', "Kampagne erfolgreich angelegt.")
            this.newKampagneName = ""
        },

        OpenKampagne(id) {
            this.$store.commit('setState', { key: 'activeKampagneID', value: id })
            this.$router.push({ name: 'KampagneView' })
        },

        imagePath(kampagne) {
            try {
                let result = ''
                if (kampagne.picURL) {
                    result = kampagne.picURL
                }
                else {
                    result = require(`@/assets/logo.png`)
                }
                return result;
            } catch (e) {
                console.error(e);
                return '';
            }
        }

    }
}
</script>