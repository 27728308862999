<template>
  <h1 class="custom-h1" block>Würfeltool</h1>
  <v-container style="margin: 2%;">
    <v-row class="centered-content">
      <v-col cols="3">

        <v-btn block @click="this.selectedNumber++" style="margin: 5px; margin-bottom: 25px;">+</v-btn>
        <v-text-field block v-model="selectedNumber" label="Anzahl Würfel:" density="compact" variant="outlined"
          type="Number" />
        <v-btn block @click="this.selectedNumber--" style="margin: 5px;">-</v-btn>

      </v-col>
      <v-col cols="1"></v-col>
      <v-col cols="3">
        <div>
          <v-row class="flex-column">
            <v-col cols="12" v-for="diceType in diceTypes" :key="diceType" class='custom-small-padding'>
              <v-btn block class='custom-small-padding' @click="selectDice(diceType)"
                :outlined="selectedDice !== diceType" :color="selectedDice === diceType ? 'rgba(0, 75, 0, 0.5)' : ''">
                {{ diceType }}
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </v-col>
      <v-col cols="1"></v-col>
      <v-col cols="4">
        <v-btn block @click="rollDice" class="custom-button">Würfeln</v-btn>
        <v-btn block @click="clear()" class="custom-button">Clear</v-btn>
      </v-col>
    </v-row>
    <v-row style="margin-bottom: -25px;">
      <v-col>
        <pre>Optionen:</pre>
      </v-col>
    </v-row>
    <v-row style="margin-bottom: -45px;">
      <v-col cols="2">
        <v-checkbox label="  Bezeichnung verwenden" v-model="BezeichnungVerwenden" density="compact"></v-checkbox>
      </v-col>
      <v-col cols="2" v-show="!this.BezeichnungVerwenden">
        <pre
          style="margin-top: 10px;"><i>Wird verwendet, um eine Bezeichnung mit dem Ergebnis zu veröffentlichen.</i></pre>
      </v-col>
      <v-col cols="6" v-show="this.BezeichnungVerwenden"><v-text-field block v-model="Bezeichnung" label="Bezeichnung" density="compact"
          variant="outlined"></v-text-field>
      </v-col>
    </v-row>
    <v-row style="margin-bottom: -25px;">
      <v-col cols="2">
        <v-checkbox label="  Ziel für Erfolge" v-model="cZiel" density="compact"></v-checkbox>
      </v-col>
      <v-col cols="2" v-show="!this.cZiel">
        <pre
          style="margin-top: 10px;"><i>Wird verwendet, wenn Erfolge gezählt und ausgewiesen werden sollen, z.B. 5en und 6en als Erfolge ausgewiesen werden sollen.</i></pre>
      </v-col>
      <v-col cols="1" v-show="this.cZiel">
        <v-btn block @click="switchZiel()" style="margin: 5px; margin-bottom: 25px;">{{ this.lZiel }}</v-btn>
      </v-col>
      <v-col cols="2" v-show="this.cZiel">
        <v-text-field block v-model="nZiel" label="Zielwert" density="compact" variant="outlined" type="Number" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" v-show="this.rollResult">
        <pre>Würfelergebnis: {{ rollResult }}</pre>

      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12" v-show="this.rollHistory">
        <pre style="color: rgba(120,120,120,0.75);"><i>Historie: {{ rollHistory }}</i></pre>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { sendMessageToDisc } from '../composables/useDiscord.js'

export default {
  data() {
    return {
      selectedNumber: 1,
      selectedDice: 'W6',
      diceTypes: ['W3', 'W4', 'W6', 'W8', 'W10', 'W12', 'W20'],
      rollResult: '',
      rollHistory: '',

      cZiel: false,
      nZiel: +5,
      lZiel: ">",

      BezeichnungVerwenden: false,
      Bezeichnung: "Angriff!"

    };
  },

  setup() {
    return {
      sendMessageToDisc
    };
  },

  methods: {
    selectDice(dice) {
      this.selectedDice = dice;
    },

    switchZiel() {
      if (this.lZiel == "<") { this.lZiel = ">" }
      else { this.lZiel = "<" }
    },

    clear() {
      this.rollHistory = ''
      this.rollResult = ''
    },

    rollDice() {
      if (!this.selectedDice || !this.selectedNumber) {
        alert('Bitte wählen Sie die Würfelart und die Anzahl der Würfe aus.');
        return;
      }

      this.rollHistory = this.rollResult + this.rollHistory

      const max = parseInt(this.selectedDice.substring(1));
      let total = 0
      let erfolge = 0
      this.rollResult = ''

      
      this.rollResult += '\n'

      if(this.BezeichnungVerwenden) { this.rollResult += "Wirft " + this.Bezeichnung + ": " }

      for (let i = 0; i < this.selectedNumber; i++) {
        var roll = Math.floor(Math.random() * max) + 1
        total += roll;
        this.rollResult += roll + ' + '
        if (this.lZiel == ">" && roll >= this.nZiel) { erfolge++ }
        else if (this.lZiel == "<" && roll <= this.nZiel) { erfolge++ }
      }

      this.rollResult = this.rollResult.slice(0, -2)
      if (this.cZiel) { total += " | Erfolge: " + erfolge }
      this.$store.commit('callDicer', { result: this.rollResult, total: total })
      this.rollResult += '= ' + total



      if (this.$store.state.Einstellungen && this.$store.state.Einstellungen.useDiscordWebhook) {
        sendMessageToDisc(this.rollResult)
      }

    },
  },
};
</script>

<style>
.custom-small-padding {
  margin: 2px;
  padding: 0;
}

.centered-content {
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
}
</style>