<template>
  <h1 class="custom-h1" block v-if="this.$store.state.activeChar" style="height: 80px; padding: 0;">
    <v-container style="padding: 0;">
      <v-row class="centered-content" style="padding: 0;">
        <v-col cols="7" style="padding: 0; margin-bottom: 15px;">
          {{ Name }}
        </v-col>
        <v-col cols="1" v-if="this.$store.state.User">
          <v-tooltip text="Speichern" location="bottom">
            <template v-slot:activator="{ props }">
              <v-img :src="require('@/assets/OIcon/SpeichernIcon.png')" alt="Speichern" height="45" class="icon-img"
                v-bind="props" @click="saveChar" />
            </template>
          </v-tooltip>
        </v-col>
        <v-col cols="1" v-if="this.$store.state.User">
          <v-tooltip text="zum Löschen ALT gedrückt halten" location="bottom">
            <template v-slot:activator="{ props }">
              <v-img :src="require('@/assets/OIcon/LöschenIcon.png')" alt="Löschen" height="45" class="icon-img"
                v-bind="props" @click.alt="deleteChar" v-show="this.Bearbeitungsmodus" />
            </template>
          </v-tooltip>
        </v-col>
        <v-col cols="3">
          <v-switch label="Bearbeitungsmodus" color="green" v-model="Bearbeitungsmodus" />
        </v-col>
      </v-row>
    </v-container>
  </h1>

  <div class="custom-sitebuttons">
    <v-container>
      <v-row>
        <v-col>
          <v-btn @click="this.site = 1" class="custom-pointer"
            :style="this.site == 1 ? 'background-color: rgba(0,50,0,0.5)' : 'background-color: rgba(50,50,50,0.5)'">1</v-btn>
        </v-col>
        <v-col>
          <v-btn @click="this.site = 2" class="custom-pointer"
            :style="this.site == 2 ? 'background-color: rgba(0,50,0,0.5)' : 'background-color: rgba(50,50,50,0.5)'">2</v-btn>
        </v-col>
        <v-col>
          <v-btn @click="this.site = 3" class="custom-pointer"
            :style="this.site == 3 ? 'background-color: rgba(0,50,0,0.5)' : 'background-color: rgba(50,50,50,0.5)'">3</v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>

  <!-- SEITE 1 -->
  <v-container v-show="this.site == 1">
    <v-row v-show="this.Bearbeitungsmodus">
      <v-col cols="6">
        <v-text-field label="Name" v-model="Name" variant="outlined" />
      </v-col>
      <v-col cols="4">
        <v-file-input label="Charakter Bild hochladen" v-model="fileHandle" ref="fileInput"></v-file-input>
      </v-col>
      <v-col cols="2">
        <v-btn @click="fileHandler('Bild')" style="margin-bottom: 15px;">Upload</v-btn>
      </v-col>
    </v-row>
    <v-row v-show="this.Bearbeitungsmodus" style="margin-bottom: -50px; margin-top: -50px">
      <v-col cols="6">
        <v-checkbox v-model="cbHatResonanz" label="Verwendet Resonanz"></v-checkbox>
      </v-col>
      <v-col cols="6">
        <v-checkbox v-model="cbHatMagie" label="Verwendet Magie"></v-checkbox>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="10"></v-col>
      <v-col cols="2">
        <v-img :src='charPicurl' style="margin-bottom: -999px; margin-top: -25px; margin-right: -80px;" once
          max-height="145px" max-width="250px"></v-img>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="2">
        <v-container>
          <v-row style="margin-bottom: -50px;">
            <v-col>
              <v-text-field label="Konstitution" v-model="Konstitution" density="compact" variant="outlined"
                type="Number" />
            </v-col>
          </v-row>
          <v-row style="margin-bottom: -50px;">
            <v-col>
              <v-text-field label="Geschicklichkeit" v-model="Geschicklichkeit" density="compact" variant="outlined"
                type="Number" />
            </v-col>
          </v-row>
          <v-row style="margin-bottom: -50px;">
            <v-col>
              <v-text-field label="Reaktion" v-model="Reaktion" density="compact" variant="outlined" type="Number" />
            </v-col>
          </v-row>
          <v-row style="margin-bottom: -50px;">
            <v-col>
              <v-text-field label="Stärke" v-model="Stärke" density="compact" variant="outlined" type="Number" />
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col cols="2">
        <v-container>
          <v-row style="margin-bottom: -50px;">
            <v-col>
              <v-text-field label="Charisma" v-model="Charisma" variant="outlined" density="compact" type="Number" />
            </v-col>
          </v-row>
          <v-row style="margin-bottom: -50px;">
            <v-col>
              <v-text-field label="Intuition" v-model="Intuition" variant="outlined" density="compact" type="Number" />
            </v-col>
          </v-row>
          <v-row style="margin-bottom: -50px;">
            <v-col>
              <v-text-field label="Logik" v-model="Logik" variant="outlined" density="compact" type="Number" />
            </v-col>
          </v-row>
          <v-row style="margin-bottom: -50px;">
            <v-col>
              <v-text-field label="Willenskraft" v-model="Willenskraft" variant="outlined" density="compact"
                type="Number" />
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col cols="2">
        <v-container>
          <v-row style="margin-bottom: -50px;">
            <v-col>
              <v-text-field label="Edge" v-model="Edge" density="compact" variant="outlined" type="Number" />
            </v-col>
          </v-row>
          <v-row style="margin-bottom: -50px;" v-if="this.cbHatMagie">
            <v-col>
              <v-text-field label="Magie" v-model="Magie" density="compact" variant="outlined" type="Number" />
            </v-col>
          </v-row>
          <v-row style="margin-bottom: -50px;" v-if="this.cbHatResonanz">
            <v-col>
              <v-text-field label="Resonanz" v-model="Resonanz" density="compact" variant="outlined" type="Number" />
            </v-col>
          </v-row>
          <v-row style="margin-bottom: -50px;">
            <v-col>
              <v-text-field label="Panzerung" v-model="Panzerung" density="compact" variant="outlined" type="Number" />
            </v-col>
          </v-row>
        </v-container>
      </v-col>
      <v-col cols="2">
        <v-container>
          <v-row style="margin-bottom: -50px;">
            <v-col>
              <v-text-field label="Gerätestufe" v-model="Gerätestufe" density="compact" variant="outlined"
                type="Number" />
            </v-col>
          </v-row>
          <v-row style="margin-bottom: -50px;">
            <v-col>
              <v-text-field label="Datenverarbeitung" v-model="Datenverarbeitung" density="compact" variant="outlined"
                type="Number" />
            </v-col>
          </v-row>
          <v-row style="margin-bottom: -50px;">
            <v-col>
              <v-text-field label="Firewall" v-model="Firewall" density="compact" variant="outlined" type="Number" />
            </v-col>
          </v-row>
          <v-row style="margin-bottom: -50px;">
            <v-col>
              <v-text-field label="Angriff" v-model="Angriff" density="compact" variant="outlined" type="Number" />
            </v-col>
          </v-row>
          <v-row style="margin-bottom: -50px;">
            <v-col>
              <v-text-field label="Schleicher" v-model="Schleicher" density="compact" variant="outlined"
                type="Number" />
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-container>
          <v-row style="margin-bottom: -25px;">
            <v-col cols="4">
              <v-text-field label="Suche" v-model="Suche" density="compact" append-inner-icon="mdi-magnify"
                variant="outlined" hint="Suche" clearable></v-text-field>
            </v-col>
            <v-col cols="2">
              <v-checkbox label="Favoriten" v-model="showFav" density="compact"
                @click="toggleFilter('Fav')"></v-checkbox>
            </v-col>
            <v-col cols="2">
              <v-checkbox label="Aktive" v-model="showAktiv" density="compact"
                @click="toggleFilter('Akt')"></v-checkbox>
            </v-col>
            <v-col cols="2">
              <v-checkbox label="Alle" v-model="showAll" density="compact" @click="toggleFilter('All')"></v-checkbox>
            </v-col>
          </v-row>
          <v-container style="border: 1px solid #555; border-radius: 8px;
                          padding: 15px; margin-top: 5px;
                          padding-bottom: 25px; padding-right: 20px;">
            <v-row style="margin-bottom: -45px;">
              <v-col cols="3">
                <v-text-field label="Name" v-model="cName" density="compact" variant="outlined" type="text" />
              </v-col>
              <v-col cols="2">
                <v-checkbox label="Aktiv" v-model="cAktiv" density="compact" v-show="this.showDetails"></v-checkbox>
                <v-checkbox label="Favorit" v-model="cFavorit" density="compact" v-show="this.showDetails"></v-checkbox>
              </v-col>
              <v-col cols="2">
                <v-checkbox label="Grenzen Sprengen" v-model="grenzenSprengen" density="compact" @click="toggleGrenzen"
                  v-show="this.tw6"></v-checkbox>
              </v-col>
              <v-col cols="3">
                <v-btn class="custom-button" @click="roll" color="success" v-show="this.tw6 || this.ww6">Würfeln</v-btn>
                <v-btn class="custom-button" @click="rollsecondchance" color="success" v-show="this.missedDice > 0">{{
    this.zweiteChance }}</v-btn>
                <v-btn class="custom-button" @click="rollgrenzensprengen" color="success"
                  v-show="this.missedDice > 0 && !this.grenzenSprengen">Grenzen Sprengen</v-btn>
                <v-btn class="custom-button" @click="saveProbe" v-show="this.showDetails">Wurf speichern</v-btn>
                <v-btn class="custom-button" @click="saveProbeNew" v-show="this.showDetails">Wurf speichern als
                  neu</v-btn>
                <v-btn class="custom-button" @click="deleteProbe" v-show="this.showDetails">Wurf löschen</v-btn>
              </v-col>
              <v-col cols="1">
                <v-btn class="custom-button" @click="clearActiveProbe" v-show="this.activeProbeId">Leeren</v-btn>
              </v-col>
              <v-col cols="1">
                <v-img :src="require('@/assets/OIcon/plus.png')" alt="DetailsEinblenden" height="30" class="icon-img"
                  @click="toggleDetails" v-show="!this.showDetails" />
                <v-img :src="require('@/assets/OIcon/minus.png')" alt="DetailsEinblenden" height="30" class="icon-img"
                  @click="toggleDetails" v-show="this.showDetails" />
              </v-col>
            </v-row>
            <v-row style="margin-bottom: -30px;">
              <v-col cols="2" v-show="!this.ww6">
                <v-text-field label="W6" v-model="tw6" density="compact" variant="outlined" type="Number" />
              </v-col>
              <v-col cols="2" v-show="this.ww6">
                <v-text-field label="W6" v-model="ww6" density="compact" variant="outlined" type="Number" />
              </v-col>
              <v-col cols="2" v-show="this.ww6">
                <v-text-field label="" density="compact" value="+" variant="plain" type="Text" disabled />
              </v-col>
              <v-col cols="2" v-show="this.wBonus">
                <v-text-field label="z" v-model="wBonus" density="compact" variant="outlined" type="Number" />
              </v-col>
              <v-col>
                <v-text-field label="Text" v-model="tRollText" density="compact" variant="plain" disabled
                  class="italictext" type="Text" />
              </v-col>
            </v-row>
            <v-row style="margin-bottom: -30px;" v-show="this.showDetails">
              <v-col cols="12">
                <v-text-field label="Bemerkung" v-model="cBemerkung" density="compact" variant="outlined" type="text" />
              </v-col>
            </v-row>
            <v-row style="margin-bottom: -50px;" v-show="this.showDetails">
              <v-col cols="4">
                <v-text-field label="Attribute +w" v-model="cProbe" density="compact" variant="outlined" type="text" />
              </v-col>
              <v-col cols="4">
                <v-text-field label="Fertigkeitswert +w" v-model="cFertigkeitswert" density="compact" variant="outlined"
                  type="number" />
              </v-col>
              <v-col cols="4">
                <v-text-field label="Bonus +w" v-model="cBonus" density="compact" variant="outlined" type="number" />
              </v-col>
            </v-row>
            <v-row style="margin-bottom: -30px; text-align: center;" v-show="this.showDetails">
              <v-col cols="12" style="letter-spacing: 22px ">O D E R</v-col>
            </v-row>
            <v-row style="margin-bottom: -30px;" v-show="this.showDetails">
              <v-col cols="4">
                <v-text-field label="w6" v-model="cW6" density="compact" variant="outlined" type="number" />
              </v-col>
              <v-col cols="4">
                <v-text-field label="Attribute +z" v-model="cAttributsbonus" density="compact" variant="outlined"
                  type="text" />
              </v-col>
              <v-col cols="4">
                <v-text-field label="Bonus +z" v-model="cBonusz" density="compact" variant="outlined" type="number" />
              </v-col>
            </v-row>
          </v-container>
          <v-row>
            <v-data-table density="compact" @click:row="trigger" @contextmenu.prevent="trigger"
              :headers="gefilterteHeader" :items="gefilterteProben" item-key="id"
              :sort-by="[{ key: 'name', order: 'asc' }]">
              <template v-slot:[`item.combined`]="{ item }">
                {{ item.probe }} {{ item.attributsbonus }}
              </template>
            </v-data-table>
          </v-row>
          <v-row v-if="this.Bearbeitungsmodus || this.showDetails">
            <v-col>
              <v-spacer></v-spacer>
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
  </v-container>

  <!-- SEITE 2 -->
  <v-container v-show="this.site == 2">
    <h2 class="custom-h2" block>Notizen</h2>
    <v-row>
      <v-col cols="12">
        <v-btn @click="saveNotizen">speichern</v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-textarea label="Notiz" v-model="Notizen" auto-grow rows="1" />
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="8">
        <v-textarea label="Notiz" v-model="Notizen1" auto-grow />
      </v-col>
      <v-col cols="3">
        <v-textarea label="Notiz" v-model="Notizen2" auto-grow />
      </v-col>
      <v-col cols="1">
        <v-textarea label="Notiz" v-model="Notizen3" auto-grow />
      </v-col>
    </v-row>
  </v-container>

  <!-- SEITE 3 -->
  <v-container v-show="this.site == 3">
    <h2 class="custom-h2" block>Charakterbogen</h2>
    <v-row v-show="this.Bearbeitungsmodus || !this.charPDFurl">
      <v-col>
        <v-file-input label="Charakterbogen hochladen" v-model="fileHandle" ref="fileInput"></v-file-input>
      </v-col>
      <v-col>
        <v-btn @click="fileHandler('Char')" style="margin-bottom: 15px;">Upload</v-btn>
      </v-col>
    </v-row>
    <iframe v-if="charPDFurl" :src="charPDFurl" width="100%" height="1200px"></iframe>
  </v-container>
</template>

<script>
import { getFirestore, onSnapshot, doc, deleteDoc, updateDoc, setDoc } from 'firebase/firestore';

import { sendMessageToDisc } from '../composables/useDiscord.js'
import { sendMessageToHistory } from '../composables/useSendHistory.js'

import { storage } from '@/firebase.js'
import { getDownloadURL, ref as storageRef, uploadBytes } from 'firebase/storage'

export default {
  data() {
    return {
      unsubscribeActiveChar: null,
      site: 1,
      Bearbeitungsmodus: false,

      Name: '',
      System: '',
      User: '',
      CreatedAt: '',

      Notizen: '',
      Notizen1: '',
      Notizen2: '',
      Notizen3: '',

      fileHandle: null,
      charPDFurl: '',
      charPicurl: '',

      Konstitution: 0,
      Geschicklichkeit: 0,
      Reaktion: 0,
      Stärke: 0,
      Charisma: 0,
      Intuition: 0,
      Logik: 0,
      Willenskraft: 0,
      Edge: 0,
      Magie: 0,
      Resonanz: 0,
      Panzerung: 0,
      Gerätestufe: 0,
      Datenverarbeitung: 0,
      Firewall: 0,
      Schleicher: 0,
      Angriff: 0,

      cbHatMagie: false,
      cbHatResonanz: false,

      Überschriften: [
        { title: 'ID', key: 'id', visible: false },
        { title: 'Name', key: 'name', sortable: true },
        { title: 'Gruppe', key: 'fertigkeitsgruppe', sortable: true, visible: false },
        { title: 'Attribute', key: 'probe', sortable: false, visible: false },
        { title: 'FW6', key: 'talentwert', sortable: true, visible: false },
        { title: 'Verwendete Attribute', key: 'combined' },
        { title: 'BW6', key: 'bonus', sortable: false, visible: false },
        { title: 'BonusA', key: 'attributsbonus', sortable: false, visible: false },
        { title: 'Bemerkung', key: 'bemerkung', sortable: false },
        { title: 'Aktiv', key: 'aktiv', visible: false }
      ],
      Proben: [],

      activeProbeId: '',

      Suche: '',
      showAktiv: true,
      showAll: false,
      showFav: false,
      showDetails: false,
      grenzenSprengen: false,
      missedDice: 0,
      zweiteChance: 'Zweite Chance ()',

      cName: '',
      cAktiv: true,
      cBemerkung: '',
      cProbe: '',
      cBonus: 0,
      cFertigkeitswert: 0,
      cW6: 0,
      cAttributsbonus: '',
      cBonusz: 0,
      cFavorit: false,

      tw6: 0,
      tBonusz: 0,
      tRollText: '',
      ww6: 0,
      wBonus: 0,


    };
  },

  computed: {
    gefilterteProben() {
      return this.Proben.filter(Probe => {
        if (!this.Suche) {
          if (this.showAktiv) {
            return Probe.aktiv
          } else if (this.showFav) {
            return Probe.favorit
          } else {
            return true
          }
        }
        else {
          if (this.showAktiv) {
            return (Probe.name.toLowerCase().includes(this.Suche.toLowerCase()) || Probe.bemerkung.toLowerCase().includes(this.Suche.toLowerCase())) && Probe.aktiv
          } else if (this.showFav) {
            return (Probe.name.toLowerCase().includes(this.Suche.toLowerCase()) || Probe.bemerkung.toLowerCase().includes(this.Suche.toLowerCase())) && Probe.favorit
          } else {
            return (Probe.name.toLowerCase().includes(this.Suche.toLowerCase()) || Probe.bemerkung.toLowerCase().includes(this.Suche.toLowerCase()))
          }
        }
      });
    },


    gefilterteHeader() {
      return this.Überschriften.filter(header => header.visible !== false);
    },
  },

  mounted() {
    this.loadChar()
  },

  beforeUnmount() {
    if (this.unsubscribeActiveChar) {
      this.unsubscribeActiveChar();
    }
  },

  watch: {
    cFertigkeitswert(newValue) {
      if (newValue) {
        this.cAktiv = true
      }
    },

    cAktiv(newValue) {
      if (newValue && !this.cFertigkeitswert) {
        this.cFertigkeitswert = 1
      }
      if (!newValue) {
        this.cFertigkeitswert = ''
      }
    }
  },

  methods: {
    loadChar() {
      if (this.unsubscribeActiveChar) {
        this.unsubscribeActiveChar();
      }
      if (!this.$store.state.activeCharID) {
        this.$router.push({ name: 'home' })
        console.log("activeCharID ist nicht gesetzt. Umgeleitet an Home.");
        return;
      }
      const db = getFirestore();
      const docRef = doc(db, "Charaktere", this.$store.state.activeCharID);
      this.unsubscribeActiveChar = onSnapshot(docRef, (docSnapshot) => {
        if (docSnapshot.exists()) {
          this.$store.commit('setState', { key: 'activeChar', value: { id: docSnapshot.id, ...docSnapshot.data() } })
          this.Name = docSnapshot.data().Name
          this.System = docSnapshot.data().System
          this.CreatedAt = docSnapshot.data().CreatedAt
          this.User = docSnapshot.data().User

          if (docSnapshot.data().Konstitution == undefined) { this.Konstitution = 0 } else { this.Konstitution = docSnapshot.data().Konstitution }
          if (docSnapshot.data().Geschicklichkeit == undefined) { this.Geschicklichkeit = 0 } else { this.Geschicklichkeit = docSnapshot.data().Geschicklichkeit }
          if (docSnapshot.data().Reaktion == undefined) { this.Reaktion = 0 } else { this.Reaktion = docSnapshot.data().Reaktion }
          if (docSnapshot.data().Stärke == undefined) { this.Stärke = 0 } else { this.Stärke = docSnapshot.data().Stärke }
          if (docSnapshot.data().Charisma == undefined) { this.Charisma = 0 } else { this.Charisma = docSnapshot.data().Charisma }
          if (docSnapshot.data().Intuition == undefined) { this.Intuition = 0 } else { this.Intuition = docSnapshot.data().Intuition }
          if (docSnapshot.data().Logik == undefined) { this.Logik = 0 } else { this.Logik = docSnapshot.data().Logik }
          if (docSnapshot.data().Willenskraft == undefined) { this.Willenskraft = 0 } else { this.Willenskraft = docSnapshot.data().Willenskraft }
          if (docSnapshot.data().Edge == undefined) { this.Edge = 0 } else { this.Edge = docSnapshot.data().Edge }
          if (docSnapshot.data().Magie == undefined) { this.Magie = 0 } else { this.Magie = docSnapshot.data().Magie }
          if (docSnapshot.data().HatMagie == undefined) { this.HatMagie = false } else { this.cbHatMagie = docSnapshot.data().HatMagie }
          if (docSnapshot.data().Resonanz == undefined) { this.Resonanz = 0 } else { this.Resonanz = docSnapshot.data().Resonanz }
          if (docSnapshot.data().HatResonanz == undefined) { this.HatResonanz = false } else { this.cbHatResonanz = docSnapshot.data().HatResonanz }
          if (docSnapshot.data().Panzerung == undefined) { this.Panzerung = 0 } else { this.Panzerung = docSnapshot.data().Panzerung }
          if (docSnapshot.data().Gerätestufe == undefined) { this.Gerätestufe = 0 } else { this.Gerätestufe = docSnapshot.data().Gerätestufe }
          if (docSnapshot.data().Datenverarbeitung == undefined) { this.Datenverarbeitung = 0 } else { this.Datenverarbeitung = docSnapshot.data().Datenverarbeitung }
          if (docSnapshot.data().Firewall == undefined) { this.Firewall = 0 } else { this.Firewall = docSnapshot.data().Firewall }
          if (docSnapshot.data().Angriff == undefined) { this.Angriff = 0 } else { this.Angriff = docSnapshot.data().Angriff }
          if (docSnapshot.data().Schleicher == undefined) { this.Schleicher = 0 } else { this.Schleicher = docSnapshot.data().Schleicher }

          if (docSnapshot.data().Notizen == undefined) { this.Notizen = '' } else { this.Notizen = docSnapshot.data().Notizen }
          if (docSnapshot.data().Notizen1 == undefined) { this.Notizen1 = '' } else { this.Notizen1 = docSnapshot.data().Notizen1 }
          if (docSnapshot.data().Notizen2 == undefined) { this.Notizen2 = '' } else { this.Notizen2 = docSnapshot.data().Notizen2 }
          if (docSnapshot.data().Notizen3 == undefined) { this.Notizen3 = '' } else { this.Notizen3 = docSnapshot.data().Notizen3 }
          if (docSnapshot.data().pdfURL == undefined) { this.charPDFurl = '' } else { this.charPDFurl = docSnapshot.data().pdfURL }
          if (docSnapshot.data().picURL == undefined) { this.charPicurl = '' } else { this.charPicurl = docSnapshot.data().picURL }

          this.Proben = docSnapshot.data().Proben

        } else {
          console.log("Kein Dokument mit dieser ID gefunden.");
          this.$store.commit('setState', { key: 'activeChar', value: null })
        }
      }, (error) => {
        console.error("Fehler beim Empfangen des Charakters:", error);
      });
    },

    saveChar() {
      if (!this.$store.state.activeCharID) {
        console.error("Keine activeCharID verfügbar.")
        return
      }
      try {
        const db = getFirestore();
        const docRef = doc(db, "Charaktere", this.$store.state.activeCharID);
        updateDoc(docRef, {
          Name: this.Name,
          Konstitution: this.Konstitution,
          Geschicklichkeit: this.Geschicklichkeit,
          Reaktion: this.Reaktion,
          Stärke: this.Stärke,
          Charisma: this.Charisma,
          Intuition: this.Intuition,
          Logik: this.Logik,
          Willenskraft: this.Willenskraft,
          Edge: this.Edge,
          Magie: this.Magie,
          HatMagie: this.cbHatMagie,
          Resonanz: this.Resonanz,
          HatResonanz: this.cbHatResonanz,
          Panzerung: this.Panzerung,
          Gerätestufe: this.Gerätestufe,
          Firewall: this.Firewall,
          Datenverarbeitung: this.Datenverarbeitung,
          Angriff: this.Angriff,
          Schleicher: this.Schleicher,
          Proben: this.Proben,
        })
        this.$store.commit('callSnackbar', this.Name + " wurde gespeichert.")
      }
      catch (err) {
        console.error("Fehler beim Speichern des Dokuments: ", err)
      }
    },

    async deleteChar() {
      if (!this.$store.state.activeCharID) {
        console.error("Keine activeCharID verfügbar.")
        return
      }
      try {
        const db = getFirestore();
        const docRef = doc(db, "Charaktere", this.$store.state.activeCharID)
        await deleteDoc(docRef)
        this.$router.push({ name: 'home' })
        this.$store.commit('callSnackbar', this.Name + " wurde gelöscht.")
      }
      catch (err) {
        console.error("Fehler beim Löschen des Charakters: ", err)
      }
    },

    trigger(event, data) {
      if (event && data && data.item) {

        this.clearActiveProbe(false)

        this.activeProbeId = data.item.id
        this.cName = data.item.name
        this.cAktiv = data.item.aktiv
        this.cBemerkung = data.item.bemerkung
        this.cProbe = data.item.probe
        this.cFertigkeitswert = data.item.talentwert
        this.cBonus = data.item.bonus
        this.cW6 = data.item.w6
        this.cBonusz = data.item.bonusz
        this.cAttributsbonus = data.item.attributsbonus
        this.cFavorit = data.item.favorit

        if (data.item.probe.includes('Konstitution')) {
          this.tw6 += +this.Konstitution
          this.tRollText += " KON (" + this.Konstitution + ")"
        }
        if (data.item.probe.includes('Geschicklichkeit')) {
          this.tw6 += +this.Geschicklichkeit
          this.tRollText += " GES (" + this.Geschicklichkeit + ")"
        }
        if (data.item.probe.includes('Reaktion')) {
          this.tw6 += +this.Reaktion
          this.tRollText += " REA (" + this.Reaktion + ")"
        }
        if (data.item.probe.includes('Stärke')) {
          this.tw6 += +this.Stärke
          this.tRollText += " STÄ (" + this.Stärke + ")"
        }
        if (data.item.probe.includes('Charisma')) {
          this.tw6 += +this.Charisma
          this.tRollText += " CHA (" + this.Charisma + ")"
        }
        if (data.item.probe.includes('Intuition')) {
          this.tw6 += +this.Intuition
          this.tRollText += " INT (" + this.Intuition + ")"
        }
        if (data.item.probe.includes('Logik')) {
          this.tw6 += +this.Logik
          this.tRollText += " LOG (" + this.Logik + ")"
        }
        if (data.item.probe.includes('Willenskraft')) {
          this.tw6 += +this.Willenskraft
          this.tRollText += " WIL (" + this.Willenskraft + ")"
        }
        if (data.item.probe.includes('Edge')) {
          this.tw6 += +this.Edge
          this.tRollText += " Edge (" + this.Edge + ")"
        }
        if (data.item.probe.includes('Magie')) {
          this.tw6 += +this.Magie
          this.tRollText += " MAG (" + this.Magie + ")"
        }
        if (data.item.probe.includes('Resonanz')) {
          this.tw6 += +this.Resonanz
          this.tRollText += " RES (" + this.Resonanz + ")"
        }
        if (data.item.probe.includes('Panzerung')) {
          this.tw6 += +this.Panzerung
          this.tRollText += " Panzerung (" + this.Panzerung + ")"
        }
        if (data.item.probe.includes('Gerätestufe')) {
          this.tw6 += +this.Gerätestufe
          this.tRollText += " Gerätestufe (" + this.Gerätestufe + ")"
        }
        if (data.item.probe.includes('Datenverarbeitung')) {
          this.tw6 += +this.Datenverarbeitung
          this.tRollText += " Datenverarbeitung (" + this.Datenverarbeitung + ")"
        }
        if (data.item.probe.includes('Firewall')) {
          this.tw6 += +this.Firewall
          this.tRollText += " Firewall (" + this.Firewall + ")"
        }
        if (data.item.probe.includes('Angriff')) {
          this.tw6 += +this.Angriff
          this.tRollText += " Angriff (" + this.Angriff + ")"
        }
        if (data.item.probe.includes('Schleicher')) {
          this.tw6 += +this.Schleicher
          this.tRollText += " Schleicher (" + this.Schleicher + ")"
        }

        if (this.cFertigkeitswert) {
          this.tw6 += +this.cFertigkeitswert
          this.tRollText += " " + this.cName + " (" + this.cFertigkeitswert + ")"
        }

        if (!this.cAktiv) {
          this.tw6--
          this.tRollText += " " + this.cName + " (0) Improvisation (-1)"
        }

        if (this.cBonus) {
          this.tw6 += +this.cBonus
          this.tRollText += " B (" + this.cBonus + ")"
        }

        if (this.cW6) {
          this.ww6 = +this.cW6
          this.tRollText += " " + this.ww6 + "w6 + "
        }

        if (this.cAttributsbonus.includes('Konstitution, Konstitution')) {
          this.wBonus += +this.Konstitution
          this.wBonus += +this.Konstitution
          this.tRollText += " KON (" + this.Konstitution + ")"
          this.tRollText += " KON (" + this.Konstitution + ")"
        } else if (this.cAttributsbonus.includes('Konstitution')) {
          this.wBonus += +this.Konstitution
          this.tRollText += " KON (" + this.Konstitution + ")"
        }

        if (this.cAttributsbonus.includes('Geschicklichkeit, Geschicklichkeit')) {
          this.wBonus += +this.Geschicklichkeit
          this.wBonus += +this.Geschicklichkeit
          this.tRollText += " GES (" + this.Geschicklichkeit + ")"
          this.tRollText += " GES (" + this.Geschicklichkeit + ")"
        } else if (this.cAttributsbonus.includes('Geschicklichkeit')) {
          this.wBonus += +this.Geschicklichkeit
          this.tRollText += " GES (" + this.Geschicklichkeit + ")"
        }

        if (this.cAttributsbonus.includes('Reaktion, Reaktion')) {
          this.wBonus += +this.Reaktion
          this.wBonus += +this.Reaktion
          this.tRollText += " REA (" + this.Reaktion + ")"
          this.tRollText += " REA (" + this.Reaktion + ")"
        } else if (this.cAttributsbonus.includes('Reaktion')) {
          this.wBonus += +this.Reaktion
          this.tRollText += " REA (" + this.Reaktion + ")"
        }

        if (this.cAttributsbonus.includes('Stärke, Stärke')) {
          this.wBonus += +this.Stärke
          this.wBonus += +this.Stärke
          this.tRollText += " STÄ (" + this.Stärke + ")"
          this.tRollText += " STÄ (" + this.Stärke + ")"
        } else if (this.cAttributsbonus.includes('Stärke')) {
          this.wBonus += +this.Stärke
          this.tRollText += " STÄ (" + this.Stärke + ")"
        }

        if (this.cAttributsbonus.includes('Charisma, Charisma')) {
          this.wBonus += +this.Charisma
          this.wBonus += +this.Charisma
          this.tRollText += " CHA (" + this.Charisma + ")"
          this.tRollText += " CHA (" + this.Charisma + ")"
        } else if (this.cAttributsbonus.includes('Charisma')) {
          this.wBonus += +this.Charisma
          this.tRollText += " CHA (" + this.Charisma + ")"
        }

        if (this.cAttributsbonus.includes('Intuition, Intuition')) {
          this.wBonus += +this.Intuition
          this.wBonus += +this.Intuition
          this.tRollText += " INT (" + this.Intuition + ")"
          this.tRollText += " INT (" + this.Intuition + ")"
        } else if (this.cAttributsbonus.includes('Intuition')) {
          this.wBonus += +this.Intuition
          this.tRollText += " INT (" + this.Intuition + ")"
        }

        if (this.cAttributsbonus.includes('Logik, Logik')) {
          this.wBonus += +this.Logik
          this.wBonus += +this.Logik
          this.tRollText += " LOG (" + this.Logik + ")"
          this.tRollText += " LOG (" + this.Logik + ")"
        } else if (this.cAttributsbonus.includes('Logik')) {
          this.wBonus += +this.Logik
          this.tRollText += " LOG (" + this.Logik + ")"
        }

        if (this.cAttributsbonus.includes('Willenskraft, Willenskraft')) {
          this.wBonus += +this.Willenskraft
          this.wBonus += +this.Willenskraft
          this.tRollText += " WIL (" + this.Willenskraft + ")"
          this.tRollText += " WIL (" + this.Willenskraft + ")"
        } else if (this.cAttributsbonus.includes('Willenskraft')) {
          this.wBonus += +this.Willenskraft
          this.tRollText += " WIL (" + this.Willenskraft + ")"
        }

        if (this.cAttributsbonus.includes('Edge, Edge')) {
          this.wBonus += +this.Edge
          this.wBonus += +this.Edge
          this.tRollText += " EDG (" + this.Edge + ")"
          this.tRollText += " EDG (" + this.Edge + ")"
        } else if (this.cAttributsbonus.includes('Edge')) {
          this.wBonus += +this.Edge
          this.tRollText += " EDG (" + this.Edge + ")"
        }

        if (this.cAttributsbonus.includes('Magie, Magie')) {
          this.wBonus += +this.Magie
          this.wBonus += +this.Magie
          this.tRollText += " MAG (" + this.Magie + ")"
          this.tRollText += " MAG (" + this.Magie + ")"
        } else if (this.cAttributsbonus.includes('Magie')) {
          this.wBonus += +this.Magie
          this.tRollText += " MAG (" + this.Magie + ")"
        }

        if (this.cAttributsbonus.includes('Resonanz, Resonanz')) {
          this.wBonus += +this.Resonanz
          this.wBonus += +this.Resonanz
          this.tRollText += " RES (" + this.Resonanz + ")"
          this.tRollText += " RES (" + this.Resonanz + ")"
        } else if (this.cAttributsbonus.includes('Resonanz')) {
          this.wBonus += +this.Resonanz
          this.tRollText += " RES (" + this.Resonanz + ")"
        }

        if (this.cAttributsbonus.includes('Gerätestufe, Gerätestufe')) {
          this.wBonus += +this.Gerätestufe
          this.wBonus += +this.Gerätestufe
          this.tRollText += " GST (" + this.Gerätestufe + ")"
          this.tRollText += " GST (" + this.Gerätestufe + ")"
        } else if (this.cAttributsbonus.includes('Gerätestufe')) {
          this.wBonus += +this.Gerätestufe
          this.tRollText += " GST (" + this.Gerätestufe + ")"
        }

        if (this.cAttributsbonus.includes('Datenverarbeitung, Datenverarbeitung')) {
          this.wBonus += +this.Datenverarbeitung
          this.wBonus += +this.Datenverarbeitung
          this.tRollText += " DV (" + this.Datenverarbeitung + ")"
          this.tRollText += " DV (" + this.Datenverarbeitung + ")"
        } else if (this.cAttributsbonus.includes('Datenverarbeitung')) {
          this.wBonus += +this.Datenverarbeitung
          this.tRollText += " DV (" + this.Datenverarbeitung + ")"
        }

        if (this.cAttributsbonus.includes('Firewall, Firewall')) {
          this.wBonus += +this.Firewall
          this.wBonus += +this.Firewall
          this.tRollText += " FW (" + this.Firewall + ")"
          this.tRollText += " FW (" + this.Firewall + ")"
        } else if (this.cAttributsbonus.includes('Firewall')) {
          this.wBonus += +this.Firewall
          this.tRollText += " FW (" + this.Firewall + ")"
        }

        if (this.cAttributsbonus.includes('Angriff, Angriff')) {
          this.wBonus += +this.Angriff
          this.wBonus += +this.Angriff
          this.tRollText += " WIL (" + this.Angriff + ")"
          this.tRollText += " ANG (" + this.Angriff + ")"
        } else if (this.cAttributsbonus.includes('Angriff')) {
          this.wBonus += +this.Angriff
          this.tRollText += " ANG (" + this.Angriff + ")"
        }

        if (this.cAttributsbonus.includes('Schleicher, Schleicher')) {
          this.wBonus += +this.Schleicher
          this.wBonus += +this.Schleicher
          this.tRollText += " SLC (" + this.Schleicher + ")"
          this.tRollText += " SLC (" + this.Schleicher + ")"
        } else if (this.cAttributsbonus.includes('Schleicher')) {
          this.wBonus += +this.Schleicher
          this.tRollText += " SLC (" + this.Schleicher + ")"
        }

        if (this.cBonusz) {
          this.wBonus += +this.cBonusz
          this.tRollText += " B (" + this.cBonusz + ")"
        }
      }
    },

    roll() {
      let result = 'Wirft '
      let sixes = +0
      let ones = +0
      let successes = +0
      let misses = +0
      let patzer = false
      let kritpatzer = false

      if (this.cName) {
        result += this.cName
        if (this.tw6) { result += " (" + this.tw6 + "):" }
        result += " "
      }

      if (this.ww6) {
        let total = +0
        result += "(" + this.ww6 + "w6"
        if (this.wBonus) {
          result += "+" + this.wBonus
          total += +this.wBonus
        }
        result += "): "

        for (let i = 0; i < this.ww6; i++) {
          var rollwx = Math.floor(Math.random() * 6) + 1
          total += +rollwx
          result += rollwx + ", "
        }
        result = result.substring(0, result.length - 2);
        result += " | Ergebnis: " + total
      }

      if (this.tw6) {
        for (let i = 0; i < this.tw6; i++) {
          var roll = Math.floor(Math.random() * 6) + 1
          result += roll + ', '
          if (roll == 1) {
            ones++
            misses++
          }
          if (roll == 2) {
            misses++
          }
          if (roll == 3) {
            misses++
          }
          if (roll == 4) {
            misses++
          }
          if (roll == 5) {
            successes++
          }
          if (roll == 6) {
            successes++
            sixes++
          }
        }
        result = result.substring(0, result.length - 2);

        if (this.grenzenSprengen) {
          if (sixes > 0) {
            result += " | "
          }
          while (sixes > 0) {
            var rollex = Math.floor(Math.random() * 6) + 1
            sixes--
            if (rollex == 1) {
              ones++
              misses++
            }
            if (rollex == 2) {
              misses++
            }
            if (rollex == 3) {
              misses++
            }
            if (rollex == 4) {
              misses++
            }
            if (rollex == 5) {
              successes++
            }
            if (rollex == 6) {
              successes++
              sixes++
            }
            result += "!" + rollex + ", "
          }
          result = result.substring(0, result.length - 2);
        }


        if (ones > (this.tw6 / 2)) {
          patzer = true
          if (successes == 0) {
            kritpatzer = true
          }
        }

        result += " | Erfolge: " + successes
        if (patzer) { result += " \u{1F4A9} Patzer" } // emoji poop
        if (kritpatzer) { result += " \u{1F4A9} \u{1F4A9} \u{1F4A9} Kritischer Patzer" } // emoji poop
        this.missedDice = misses
        this.zweiteChance = 'Zweite Chance (' + misses + ")"
      }
      console.log(result)
      this.$store.commit('callDicer', { result: result })
      sendMessageToDisc(result, this.Name)
      sendMessageToHistory(result)
    },

    rollsecondchance() {
      var result = "Nutzt eine zweite Chance: "
      var successes = +0
      for (let i = 0; i < this.missedDice; i++) {
        var roll = Math.floor(Math.random() * 6) + 1
        result += roll + ", "
        if (roll == 5) {
          successes++
        }
        if (roll == 6) {
          successes++
        }
      }
      result = result.substring(0, result.length - 2);
      this.missedDice = ''
      if (successes > 0) {
        result += " | Zusätzliche Erfolge: +" + successes
      } else {
        result += " | Keine zusätzlichen Erfolge \u{1F4A9}"
      }
      console.log(result)
      this.$store.commit('callDicer', { result: result })
      sendMessageToDisc(result, this.Name)
      sendMessageToHistory(result)
    },

    rollgrenzensprengen() {
      var result = "Sprengt noch kurz die Grenzen (" + this.Edge + "): "
      var successes = +0
      var rolls = +this.Edge
      while (rolls > 0) {
        var roll = Math.floor(Math.random() * 6) + 1
        if (roll == 6) {
          successes++
          rolls++
          result += "!"
        }
        if (roll == 5) {
          successes++
        }
        result += roll + ", "
        rolls--
      }
      result = result.substring(0, result.length - 2);
      if (successes > 0) {
        result += " | Zusätzliche Erfolge: +" + successes
      } else {
        result += " | Keine zusätzlichen Erfolge \u{1F4A9}"
      }
      this.missedDice = ''
      console.log(result)
      this.$store.commit('callDicer', { result: result })
      sendMessageToDisc(result, this.Name)
      sendMessageToHistory(result)
    },

    toggleGrenzen() {
      this.missedDice = 0
      if (!this.grenzenSprengen) {
        this.tw6 += +this.Edge
        this.tRollText += " EDG (" + this.Edge + "!)"
      } else {
        this.tw6 -= +this.Edge
        this.tRollText = this.tRollText.substring(0, this.tRollText.length - 9)
      }
    },

    saveProbe() {
      if (!this.$store.state.activeCharID) {
        console.error("Keine activeCharID verfügbar.")
        return
      }

      if (!this.activeProbeId) {
        console.error("Keine Aktive Probe ID verfügbar.")
        return
      }

      try {
        const db = getFirestore()
        const docRef = doc(db, "Charaktere", this.$store.state.activeCharID)

        let Proben = this.Proben || []
        let index = Proben.findIndex(obj => obj.id === this.activeProbeId)

        Proben[index].name = this.cName
        Proben[index].probe = this.cProbe
        Proben[index].bemerkung = this.cBemerkung
        Proben[index].bonus = this.cBonus
        Proben[index].bonusz = this.cBonusz
        Proben[index].attributsbonus = this.cAttributsbonus
        Proben[index].aktiv = this.cAktiv
        Proben[index].talentwert = this.cFertigkeitswert
        Proben[index].w6 = this.cW6
        Proben[index].favorit = this.cFavorit

        updateDoc(docRef, {
          Proben: Proben
        })
        this.$store.commit('callSnackbar', 'Wurf ' + Proben[index].name + ' gespeichert.')
      } catch (err) {
        console.error("Fehler beim Speichern des Dokuments: ", err);
      }
    },

    saveProbeNew() {
      if (!this.$store.state.activeCharID) {
        console.error("Keine activeCharID verfügbar.")
        return
      }

      try {
        const db = getFirestore()
        const docRef = doc(db, "Charaktere", this.$store.state.activeCharID)
        const nid = Math.random().toString(36).substr(2, 20) + Date.now().toString()
        this.activeProbeId = nid
        let proben = this.Proben || []
        let neueProbe = {
          id: nid,
          name: this.cName,
          probe: this.cProbe,
          fertigkeitsgruppe: '',
          bemerkung: this.cBemerkung,
          bonus: this.cBonus,
          bonusz: this.cBonusz,
          attributsbonus: this.cAttributsbonus,
          aktiv: this.cAktiv,
          talentwert: this.cFertigkeitswert,
          w6: this.cW6,
          favorit: this.cFavorit,
        }
        proben.push(neueProbe)

        updateDoc(docRef, {
          Proben: proben
        })
        this.$store.commit('callSnackbar', neueProbe.name + " wurde hinzugefügt.")

        this.clearActiveProbe()
        this.Suche = neueProbe.name

      } catch (err) {
        console.error("Fehler beim Speichern des Dokuments: ", err);
      }
    },

    deleteProbe() {
      try {
        const db = getFirestore()
        const docRef = doc(db, "Charaktere", this.$store.state.activeCharID)
        let proben = this.Proben
        let index = proben.findIndex(obj => obj.id === this.activeProbeId)
        if (index !== -1) {
          proben.splice(index, 1)
        }
        this.Proben = proben
        updateDoc(docRef, {
          Proben: proben
        })

        this.clearActiveProbe()

      } catch (err) {
        console.error("Fehler beim Löschen der Geispeicherten Probe: ", err)
      }
    },

    clearActiveProbe(clearall = true) {
      this.activeProbeId = null
      this.cName = ''
      this.cProbe = ''
      this.cBemerkung = ''
      this.cBonus = +0
      this.cBonusz = +0
      this.cAttributsbonus = ''
      this.cAktiv = true
      this.cFertigkeitswert = +0
      this.cW6 = +0
      this.ww6 = +0
      this.tw6 = +0
      this.wBonus = +0
      this.tRollText = ''
      this.cFavorit = false
      this.missedDice = +0
      this.grenzenSprengen = false
      if (clearall) {
        this.Suche = ''
      }
    },

    toggleDetails() {
      this.showDetails = !this.showDetails
    },

    toggleFilter(wert) {
      this.showFav = false
      this.showAll = false
      this.showAktiv = false

      if (wert == 'fav') {
        this.showFav = true
      }
      if (wert == 'all') {
        this.showAll = false
      }
      if (wert == 'nul') {
        this.showAktiv = true
      }

    },

    saveNotizen() {
      if (!this.$store.state.activeCharID) {
        console.error("Keine activeCharID verfügbar.")
        return
      }
      try {
        const db = getFirestore()
        const docRef = doc(db, "Charaktere", this.$store.state.activeCharID)
        console.log(this.Notizen)
        setDoc(docRef, {
          Notizen: this.Notizen,
          Notizen1: this.Notizen1,
          Notizen2: this.Notizen2,
          Notizen3: this.Notizen3,
        }, { merge: true })
        this.$store.commit('callSnackbar', "Notiz gespeichert.")
      } catch (err) {
        console.error("Fehler beim Speichern der Notiz: ", err)
      }
    },

    async fileHandler(Dateiart) {
      if (!this.fileHandle) return
      const file = this.fileHandle
      const fileRef = storageRef(storage, `${this.$store.state.activeCharID}/${file.name}`)
      try {
        await uploadBytes(fileRef, file)
        const url = await getDownloadURL(fileRef)
        this.fileHandle = null
        if (Dateiart == 'Char') { this.charPDFurl = url }
        else if (Dateiart == 'Bild') { this.charPicurl = url }
        //    this.$refs.fileInput.reset();
      } catch (err) {
        console.error('Fehler beim Hochladen der PDF', err)
      }

      if (!this.$store.state.activeCharID) {
        console.error("Keine activeCharID verfügbar.")
        return
      }
      try {
        const db = getFirestore()
        const docRef = doc(db, "Charaktere", this.$store.state.activeCharID)
        setDoc(docRef, {
          pdfURL: this.charPDFurl,
          picURL: this.charPicurl,
        }, { merge: true })
      } catch (err) {
        console.error("Fehler beim Speichern des Dokuments: ", err)
      }
    },
  }
}
</script>

<style>
.button-wuerfel {
  margin-bottom: 12px;
  cursor: pointer;
  height: 32px;
  opacity: 0.6;
  transition: opacity 0.2s ease;
}

.button-wuerfel:hover {
  opacity: 1.0;
  cursor: pointer;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-to,
.fade-leave-from {
  opacity: 1;
}

.v-col {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.italictext {
  font-style: italic;
}
</style>