<template>
    <NavBars />
    <AdminTab />
</template>

<script>
import { defineComponent } from 'vue';

// Components
import NavBars from '@/components/NavBars.vue';
import AdminTab from '@/components/AdminTab.vue';

export default defineComponent({
  name: 'wTool',

  components: {
    NavBars, AdminTab
  },
});
</script>