<template>
  <NavBars />
  <WelcomeTab />
</template>

<script>
import { defineComponent } from 'vue';

// Components
import NavBars from '@/components/NavBars.vue';
import WelcomeTab from '@/components/WelcomeTab.vue';

export default defineComponent({
  name: 'HomeView',

  components: {
    NavBars, WelcomeTab
  },
});
</script>
