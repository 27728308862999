<template>
    <NavBars />
    <CharTab />
</template>

<script>
import { defineComponent } from 'vue';

// Components
import NavBars from '@/components/NavBars.vue';
import CharTab from '@/components/CharTab.vue';

export default defineComponent({
  name: 'wTool',

  components: {
    NavBars, CharTab
  },
});
</script>