<template>
    <NavBars />
    <GamemasterTab />
</template>

<script>
import { defineComponent } from 'vue';

// Components
import NavBars from '@/components/NavBars.vue';
import GamemasterTab from '@/components/GamemasterTab.vue';

export default defineComponent({
  name: 'mGamemaster',

  components: {
    NavBars, GamemasterTab
  },
});
</script>