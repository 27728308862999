<template>
    <h1 class="custom-h1" block v-if="this.$store.state.activeChar">
        <v-container>
            <v-row class="centered-content">
                <v-col cols="7">
                    {{ Name }}
                </v-col>
                <v-col cols="1" v-if="this.$store.state.User">
                    <v-tooltip text="Speichern" location="bottom">
                        <template v-slot:activator="{ props }">
                            <v-img :src="require('@/assets/OIcon/SpeichernIcon.png')" alt="Speichern" height="45"
                                class="icon-img" v-bind="props" @click="saveChar" v-show="this.Bearbeitungsmodus" />
                        </template>
                    </v-tooltip>
                </v-col>
                <v-col cols="1" v-if="this.$store.state.User">
                    <v-tooltip text="zum Löschen ALT gedrückt halten" location="bottom">
                        <template v-slot:activator="{ props }">
                            <v-img :src="require('@/assets/OIcon/LöschenIcon.png')" alt="Löschen" height="45"
                                class="icon-img" v-bind="props" @click.alt="deleteChar"
                                v-show="this.Bearbeitungsmodus" />
                        </template>
                    </v-tooltip>
                </v-col>
                <v-col cols="3">
                    <v-switch label="Bearbeitungsmodus" color=green v-model="Bearbeitungsmodus" />
                </v-col>
            </v-row>
        </v-container>
    </h1>

    <!-- DSA 4.1 -->
    <div label="DSA 4.1" v-if="this.$store.state.activeChar && this.$store.state.activeChar.System == 'DSA 4.1'">
        Die Umsetzung für DSA ist noch in Arbeit.
    </div>
    <!-- Shadowrun 5e -->
    <div label="Shadowrun 5e"
        v-if="this.$store.state.activeChar && this.$store.state.activeChar.System == 'Shadowrun 5e'">
        Die Umsetzung für Shadowrun ist noch in Arbeit.
    </div>
</template>

<script>
import { getFirestore, onSnapshot, doc, deleteDoc, updateDoc } from 'firebase/firestore';

export default {
    data() {
        return {
            unsubscribeActiveChar: null,
            Bearbeitungsmodus: false,

            Name: '',
            System: '',
            User: '',
            CreatedAt: '',
        };
    },

    mounted() {
        this.loadChar()
    },

    beforeUnmount() { // beforeDestroy() für Vue 2
        if (this.unsubscribeActiveChar) {
            this.unsubscribeActiveChar();
        }
    },

    methods: {
        loadChar() {
            if (this.unsubscribeActiveChar) {
                this.unsubscribeActiveChar();
            }
            if (!this.$store.state.activeCharID) {
                console.warn("activeCharID ist nicht gesetzt.");
                return;
            }
            const db = getFirestore();
            const docRef = doc(db, "Charaktere", this.$store.state.activeCharID);
            this.unsubscribeActiveChar = onSnapshot(docRef, (docSnapshot) => {
                if (docSnapshot.exists()) {
                    this.$store.commit('setState', { key: 'activeChar', value: { id: docSnapshot.id, ...docSnapshot.data() } })
                    this.Name = docSnapshot.data().Name
                    this.System = docSnapshot.data().System
                    this.CreatedAt = docSnapshot.data().CreatedAt
                    this.User = docSnapshot.data().User
                } else {
                    console.log("Kein Dokument mit dieser ID gefunden.");
                    this.$store.commit('setState', { key: 'activeChar', value: null })
                }
            }, (error) => {
                console.error("Fehler beim Empfangen des Charakters:", error);
            });
        },

        saveChar() {
            if (!this.$store.state.activeCharID) {
                console.error("Keine activeCharID verfügbar.")
                return
            }
            try {
                const db = getFirestore();
                const docRef = doc(db, "Charaktere", this.$store.state.activeCharID);
                updateDoc(docRef, {
                    Name: this.Name,
                })
                this.$store.commit('callSnackbar', this.Name + " wurde gespeichert.")
            }
            catch (err) {
                console.error("Fehler beim Speichern des Dokuments: ", err)
            }
        },

        async deleteChar() {
            if (!this.$store.state.activeCharID) {
                console.error("Keine activeCharID verfügbar.")
                return
            }
            try {
                const db = getFirestore();
                const docRef = doc(db, "Charaktere", this.$store.state.activeCharID)
                await deleteDoc(docRef)
                this.$router.push({ name: 'home' })
                this.$store.commit('callSnackbar', this.Name + " wurde gelöscht.")
            }
            catch (err) {
                console.error("Fehler beim Löschen des Charakters: ", err)
            }
        },
    },
}

</script>

<style>
.button-wuerfel {
    margin-bottom: 12px;
    cursor: pointer;
    height: 32px;
    opacity: 0.6;
    transition: opacity 0.2s ease;
}

.button-wuerfel:hover {
    opacity: 1.0;
    cursor: pointer;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.fade-enter-to,
.fade-leave-from {
    opacity: 1;
}
</style>