<template>
  <h1 class="custom-h1" block>Willkommen</h1>
  <v-container>
    <v-row justify="center" align="center">
      <v-col cols="4"></v-col>
      <v-col cols="4" Style="text-align: center;">
        <v-img style="margin-bottom: 22px; margin-top: 22px;" :src="require('@/assets/Bilder/rpgTrolls.png')" />
      </v-col>
      <v-col cols="4"></v-col>
    </v-row>
    <v-row>
      <v-col cols="12" style="text-align: center;">
        <p style="font-size: 34px; letter-spacing: 5px;">
          <i>"Viel Glück beim Würfeln!"</i>
        </p>
        <p style="font-size: 20px; margin-top: 33px;">
          rpg-troll.de - Das webbasierte Würfeltool für D&D, DSA und andere RPGs.
        </p>
        <p style="font-size: 26px; margin-top: 33px;">
          <i>"Denkt dran, es ist noch Beta!"</i>
        </p>
        <p style="font-size: 20px; margin-top: 33px; text-align: right;">
          <i> - - - Timo - - - </i>
        </p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>


export default {
  data() {
    return {

    };
  },

  setup() {

  },

  mounted() {

  },

  methods: {

  }

};

</script>