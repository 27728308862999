export function srProben() {
    const Proben = [
        { id: 's0001', fertigkeitsgruppe: '', name: 'Initiative', probe: '', talentwert: '', aktiv: true, bemerkung: '', bonus: '', attributsbonus: 'Reaktion, Intuition', bonusz: '', w6: 1, favorit: true },
        { id: 's0002', fertigkeitsgruppe: '', name: 'Astrale Initiative', probe: '', talentwert: '', aktiv: true, bemerkung: '', bonus: '', attributsbonus: 'Intuition, Intuition', bonusz: '', w6: 3, favorit: false },
        { id: 's0004', fertigkeitsgruppe: '', name: 'Selbsbeherrschung', probe: 'Willenskraft, Charisma', talentwert: '', aktiv: true, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: true },
        { id: 's0005', fertigkeitsgruppe: '', name: 'Menschenkenntnis', probe: 'Intuition, Charisma', talentwert: '', aktiv: true, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: true },
        { id: 's0006', fertigkeitsgruppe: '', name: 'Heben und Tragen', probe: 'Konstitution, Stärke', talentwert: '', aktiv: true, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: true },
        { id: 's0007', fertigkeitsgruppe: '', name: 'Erinnerungsvermögen', probe: 'Logik, Willenskraft', talentwert: '', aktiv: true, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: true },
        { id: 's0008', fertigkeitsgruppe: '', name: 'Verteidigung', probe: 'Reaktion, Intuition', talentwert: '', aktiv: true, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: true },
        { id: 's0009', fertigkeitsgruppe: '', name: 'Initiative Blitzangriff', probe: '', talentwert: '', aktiv: true, bemerkung: 'Kostet 1 Edge, dafür 5w6 Initiative', bonus: '', attributsbonus: 'Reaktion, Intuition', bonusz: '', w6: 5, favorit: false },
        { id: 's0010', fertigkeitsgruppe: '', name: 'Schadenswiderstand', probe: 'Konstitution, Panzerung', talentwert: '', aktiv: true, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: true },
        { id: 'p0001', fertigkeitsgruppe: 'Athletik', name: 'Akrobatik', probe: 'Geschicklichkeit', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0002', fertigkeitsgruppe: '', name: 'Entfesseln', probe: 'Geschicklichkeit', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0003', fertigkeitsgruppe: '', name: 'Exotische Fernkampfwaffe', probe: 'Geschicklichkeit', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0004', fertigkeitsgruppe: '', name: 'Exotische Nahkampfwaffe', probe: 'Geschicklichkeit', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0005', fertigkeitsgruppe: 'Heimlichkeit', name: 'Fingerfertigkeit', probe: 'Geschicklichkeit', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0006', fertigkeitsgruppe: '', name: 'Geschütze', probe: 'Geschicklichkeit', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0007', fertigkeitsgruppe: 'Feuerwaffen', name: 'Gewehre', probe: 'Geschicklichkeit', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0008', fertigkeitsgruppe: 'Nahkampf', name: 'Klingenwaffen', probe: 'Geschicklichkeit', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0009', fertigkeitsgruppe: 'Nahkampf', name: 'Knüppel', probe: 'Geschicklichkeit', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0010', fertigkeitsgruppe: 'Feuerwaffen', name: 'Pistolen', probe: 'Geschicklichkeit', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0011', fertigkeitsgruppe: '', name: 'Projektilwaffen', probe: 'Geschicklichkeit', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0012', fertigkeitsgruppe: 'Heimlichkeit', name: 'Schleichen', probe: 'Geschicklichkeit', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0013', fertigkeitsgruppe: '', name: 'Schlosser', probe: 'Geschicklichkeit', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0014', fertigkeitsgruppe: 'Feuerwaffen', name: 'Schnellfeuerwaffen', probe: 'Geschicklichkeit', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0015', fertigkeitsgruppe: '', name: 'Schwere Waffen', probe: 'Geschicklichkeit', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0016', fertigkeitsgruppe: 'Nahkampf', name: 'Waffenloser Kampf', probe: 'Geschicklichkeit', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0017', fertigkeitsgruppe: '', name: 'Wurfwaffen', probe: 'Geschicklichkeit', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0018', fertigkeitsgruppe: '', name: 'Freifall', probe: 'Konstitution', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0019', fertigkeitsgruppe: '', name: 'Tauchen', probe: 'Konstitution', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0020', fertigkeitsgruppe: '', name: 'Bodenfahrzeuge', probe: 'Reaktion', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0021', fertigkeitsgruppe: '', name: 'Exotisches Fahrzeug', probe: 'Reaktion', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0022', fertigkeitsgruppe: '', name: 'Flugzeuge', probe: 'Reaktion', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0023', fertigkeitsgruppe: '', name: 'Läufer', probe: 'Reaktion', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0024', fertigkeitsgruppe: '', name: 'Raumfahrzeuge', probe: 'Reaktion', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0025', fertigkeitsgruppe: '', name: 'Schiffe', probe: 'Reaktion', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0026', fertigkeitsgruppe: 'Athletik', name: 'Laufen', probe: 'Stärke', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0027', fertigkeitsgruppe: 'Athletik', name: 'Schwimmen', probe: 'Stärke', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0028', fertigkeitsgruppe: '', name: 'Einschüchtern', probe: 'Charisma', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0029', fertigkeitsgruppe: 'Einfluss', name: 'Führung', probe: 'Charisma', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0030', fertigkeitsgruppe: 'Einfluss', name: 'Gebräuche', probe: 'Charisma', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0031', fertigkeitsgruppe: '', name: 'Tierführung', probe: 'Charisma', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0032', fertigkeitsgruppe: 'Schauspielerei', name: 'Überreden', probe: 'Charisma', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0033', fertigkeitsgruppe: '', name: 'Unterricht', probe: 'Charisma', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0034', fertigkeitsgruppe: 'Einfluss', name: 'Verhandlung', probe: 'Charisma', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0035', fertigkeitsgruppe: 'Schauspielerei', name: 'Verkörperung', probe: 'Charisma', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0036', fertigkeitsgruppe: 'Schauspielerei', name: 'Vorführung', probe: 'Charisma', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0037', fertigkeitsgruppe: '', name: 'Askennen', probe: 'Intuition', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0038', fertigkeitsgruppe: '', name: 'Handwerk', probe: 'Intuition', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0039', fertigkeitsgruppe: '', name: 'Hobbywissen', probe: 'Intuition', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0040', fertigkeitsgruppe: 'Natur', name: 'Navigation', probe: 'Intuition', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0041', fertigkeitsgruppe: '', name: 'Sprachen', probe: 'Intuition', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0042', fertigkeitsgruppe: 'Natur', name: 'Spurenlesen', probe: 'Intuition', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0043', fertigkeitsgruppe: '', name: 'Straßenwissen', probe: 'Intuition', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0044', fertigkeitsgruppe: 'Heimlichkeit', name: 'Verkleiden', probe: 'Intuition', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0045', fertigkeitsgruppe: '', name: 'Wahrnehmung', probe: 'Intuition', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0046', fertigkeitsgruppe: '', name: 'Akademisches Wissen', probe: 'Logik', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0047', fertigkeitsgruppe: '', name: 'Arkana', probe: 'Logik', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0048', fertigkeitsgruppe: '', name: 'Berufswissen', probe: 'Logik', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0049', fertigkeitsgruppe: 'Biotech', name: 'Biotechnologie', probe: 'Logik', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0050', fertigkeitsgruppe: '', name: 'Chemie', probe: 'Logik', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0051', fertigkeitsgruppe: 'Elektronik', name: 'Computer', probe: 'Logik', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0052', fertigkeitsgruppe: 'Cracken', name: 'Elektronische Kriegsführung', probe: 'Logik', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0053', fertigkeitsgruppe: 'Biotech', name: 'Erste Hilfe', probe: 'Logik', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0054', fertigkeitsgruppe: 'Mechanik', name: 'Fahrzeugmechanik', probe: 'Logik', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0055', fertigkeitsgruppe: '', name: 'Fälschen', probe: 'Logik', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0056', fertigkeitsgruppe: 'Cracken', name: 'Hacking', probe: 'Logik', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0057', fertigkeitsgruppe: 'Elektronik', name: 'Hardware', probe: 'Logik', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0058', fertigkeitsgruppe: 'Mechanik', name: 'Industriemechanik', probe: 'Logik', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0059', fertigkeitsgruppe: 'Biotech', name: 'Kybernetik', probe: 'Logik', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0060', fertigkeitsgruppe: 'Mechanik', name: 'Luftfahrtmechanik', probe: 'Logik', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0061', fertigkeitsgruppe: 'Cracken', name: 'Matrixkampf', probe: 'Logik', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0062', fertigkeitsgruppe: 'Biotech', name: 'Medizin', probe: 'Logik', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0063', fertigkeitsgruppe: 'Mechanik', name: 'Seefahrtmechanik', probe: 'Logik', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0064', fertigkeitsgruppe: 'Elektronik', name: 'Software', probe: 'Logik', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0065', fertigkeitsgruppe: '', name: 'Sprengstoffe', probe: 'Logik', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0066', fertigkeitsgruppe: '', name: 'Waffenbau', probe: 'Logik', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0067', fertigkeitsgruppe: '', name: 'Astralkampf', probe: 'Willenskraft', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0068', fertigkeitsgruppe: 'Natur', name: 'Survival', probe: 'Willenskraft', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0069', fertigkeitsgruppe: 'Verzaubern', name: 'Alchemie', probe: 'Magie', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0070', fertigkeitsgruppe: 'Hexerei', name: 'Antimagie', probe: 'Magie', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0071', fertigkeitsgruppe: 'Beschwören', name: 'Binden', probe: 'Magie', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0072', fertigkeitsgruppe: 'Verzaubern', name: 'Entzaubern', probe: 'Magie', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0073', fertigkeitsgruppe: 'Verzaubern', name: 'Fokusherstellung', probe: 'Magie', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0074', fertigkeitsgruppe: 'Beschwören', name: 'Herbeirufen', probe: 'Magie', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0075', fertigkeitsgruppe: 'Hexerei', name: 'Ritualzauberei', probe: 'Magie', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0076', fertigkeitsgruppe: 'Hexerei', name: 'Spruchzauberei', probe: 'Magie', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0077', fertigkeitsgruppe: 'Beschwören', name: 'Verbannen', probe: 'Magie', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0078', fertigkeitsgruppe: 'Tasken', name: 'Dekompilieren', probe: 'Resonanz', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0079', fertigkeitsgruppe: 'Tasken', name: 'Kompilieren', probe: 'Resonanz', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
        { id: 'p0080', fertigkeitsgruppe: 'Tasken', name: 'Registrieren', probe: 'Resonanz', talentwert: '', aktiv: false, bemerkung: '', bonus: '', attributsbonus: '', bonusz: '', w6: '', favorit: false },
    ]
    return Proben
}