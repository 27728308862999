<template>
    <NavBars />
    <CharTabSR />
  </template>
  
  <script>
  import { defineComponent } from 'vue';
  
  // Components
  import NavBars from '@/components/NavBars.vue';
  import CharTabSR from '@/components/CharTabSR.vue';
  
  export default defineComponent({
    components: {
      NavBars, CharTabSR
      },
  });
  </script>