<template>
    <h1 class="custom-h1" block>Über...</h1>
    <v-card>
        <v-tabs v-model="tab" align-tabs="center" color="rgba(200,255,200,0.4)">
            <v-tab v-for="item in tabs" :key="item">{{ item }}</v-tab>
        </v-tabs>

        <v-window v-model="tab">
            <v-window-item>
                <h2 class="custom-h2" block>Beta Versionshinweise</h2>
                <v-container>
                    <v-row align="center">
                        <v-col>
                            <p class="body-1">
                                Die aktuelle Version ist <b>{{ this.$store.state.Version }}</b><i> (vom {{
            this.$store.state.VersionVon }})</i>
                            </p>
                            <p class="body-1">
                                RPG-Troll ist aktuell noch in der Beta-Version. Der Funktionsumfang ist noch nicht
                                vollständig und es kann vereinzelt zu Problemen in einzelnen Funktionen kommen. Sollte
                                euch ein Fehler angezeigt werden könnt ihr mir gerne einen E-Mail schreiben mit einer
                                Beschreibung, was passiert ist und ich versuche es zu adressieren.
                            </p>
                            <p class="body-1">
                                Bitte beachte, dass es sich bei RPG Troll um ein Hobby-Projekt in der Entwicklung
                                handelt. Ich versuche es, so konsistent und nutzbar wie möglich zu halten, kann aber in
                                dieser Entwicklungsstufe nicht ausschließen, dass z.B. Nachrichtenverläufe, gespeicherte
                                Charaktere oder geispeicherte Proben auch mal verschwinden. Wenn das passieren sollte:
                                Tretet gegen einen Mülleimer, nicht gegen mich.
                            </p>
                            <p class="body-2">
                                Der aktuelle Funktionsumfang in groben Stichpunkten ist:
                            </p>
                            <p class="body-2">
                                - Allgemeines, einfaches Würfeltool
                            </p>
                            <p class="body-2">
                                - Aufrufen, Anlegen, Löschen von Charakteren (aktuell sind nur D&D 5e, DSA 4.1 und
                                Shadowrun 5e verfügbar)
                            </p>
                            <p class="body-2">
                                -- Speichern von Attributswerten und gespeicherten Proben
                            </p>
                            <p class="body-2">
                                - Discord Webhook Implementierung zum automatischen pushen von Proben und Nachrichten in
                                Discord
                            </p>
                            <p class="body-1">
                                - Live-Chat funktion mit öffentlichen Session IDs (tragen mehrere Personen die gleiche
                                Session ID ein, sehen sie alle Proben und Würfe)
                            </p>

                            <p class="body-2">
                                Weitere, konkret geplante Funktionen bevor Version 1 veröffentlicht wird:
                            </p>
                            <p class="body-2">
                                - Das Würfeltool soll noch um einige Funktionen wie Zielerfolgswerte, Misserfolge,
                                Würfelexplosion usw. umgesetzt werden
                            </p>
                            <p class="body-2">
                                - Spielleiter-Bereich in dem für die unterstützten Systeme bestimmte funktionen für den
                                Spielleiter zur Verfügung stehen
                            </p>
                            <p class="body-1">
                                <br>Dein Feedback und Deine Ideen sind herzlich willkommen, gerne kannst du Kontakt zu
                                mir aufnehmen unter: <a
                                    href="mailto:t.novotny.tnds@gmail.com">t.novotny.tnds@gmail.com</a>
                            </p>
                        </v-col>
                    </v-row>
                </v-container>
            </v-window-item>
            <v-window-item>
                <h2 class="custom-h2" block>RPG Troll</h2>
                <v-container>
                    <v-row align="center">
                        <v-col>
                            <p class="body-1">
                                Willkommen auf rpg-troll.de, deiner digitalen Anlaufstelle für Pen-&-Paper-Rollenspiele!
                            </p>
                            <p class="body-1">
                                Als leidenschaftlicher Hobbyentwickler und Rollenspieler habe ich diese Seite ins Leben
                                gerufen, um Gleichgesinnten eine Plattform zu bieten, die das digitale Spielen
                                erleichtern und bereichern soll.
                                RPG Troll ist als intuitives Würfel-Tool, das speziell dafür konzipiert wurde, das Würfeln von
                                Proben und anderen spielrelevanten Aktionen zu unterstützen.
                            </p>
                            <p class="body-1">
                                Hier kannst du deine Charaktere rudimentär anlegen und speichern, um dein Spielerlebnis
                                zu vereinfachen und zu verbessern.
                                Aktuell ist die Seite auf Dungeons & Dragons 5e zugeschnitten, befindet sich aber noch
                                im Beta-Status, da ich stets darauf bedacht bin, die Funktionalität und
                                Benutzererfahrung zu optimieren.
                            </p>
                            <p class="body-1">
                                Die Vision hinter rpg-troll.de ist es, eine umfassende Unterstützung für verschiedene
                                Pen-&-Paper-Systeme anzubieten.
                                In der Zukunft plane ich, das Angebot um "Das Schwarze Auge (DSA) 4.1" und "Shadowrun
                                5e" zu erweitern, um eine noch breitere Community von Rollenspielern zu erreichen.
                            </p>
                        </v-col>
                    </v-row>
                </v-container>
            </v-window-item>
            <v-window-item>
                <h2 class="custom-h2" block>mich</h2>
                <v-container>
                    <v-row>
                        <v-col cols="10">
                            <p class="body-1">
                                Mein Name ist Timo, ich bin 37 Jahre alt, stolzer Vater von zwei Kindern und lebe in
                                Stuttgart.
                                Meine Leidenschaft für Pen-&-Paper-Rollenspiele entflammte bereits in der dritten
                                Klasse, und seitdem hat sie mich nicht mehr losgelassen.
                            </p>
                            <p class="body-1">
                                Durch die Verantwortung als Vater finde ich mich oft in der Situation wieder, dass ich
                                meine Rollenspielabenteuer digital erleben muss.
                                Diese Notwendigkeit hat mich dazu motiviert, ständig nach Wegen zu suchen, um die
                                digitale Rollenspielerfahrung zu verbessern.
                            </p>
                            <p class="body-1">
                                Als Hobby-Informatiker stelle ich mich der Herausforderung, Programmierprojekte zu
                                realisieren, um Lösungen für mich und die Community zu entwickeln.
                                Auch wenn jedes Projekt für mich eine große Herausforderung darstellt, treibt mich die
                                Leidenschaft für das Rollenspiel und die Freude am Lernen und Entwickeln immer wieder
                                an, Neues zu erschaffen und zu teilen.
                            </p>
                        </v-col>
                        <v-col cols="2">
                            <v-img :src="require('@/assets/Bilder/TNo2.png')" />
                        </v-col>
                    </v-row>
                </v-container>
            </v-window-item>
            <v-window-item>
                <h2 class="custom-h2" block>Impressum</h2>
                <v-container>
                    <v-row>
                        <v-col>
                            <p class="body-1">
                                <b>Timo Novotny Digitale Service</b>
                                <br><br>
                                Timo Novotny
                                <br>
                                Steigstr. 48, 70565 Stuttgart
                                <br><br>
                                <a href="mailto:t.novotny.tnds@gmail.com">E-Mail</a>
                            </p>
                        </v-col>
                    </v-row>
                </v-container>
            </v-window-item>
            <v-window-item>
                <h2 class="custom-h2" block>Spenden</h2>
                <v-container>
                    <v-row>
                        <v-col>
                            <p class="body-1">
                                Hi, mich freut es, wenn Du rpg-troll gut findest. Für die Entwicklung geht jede Menge
                                Cafe drauf.
                            </p>
                            <p style="margin-bottom: 33px;">
                                Falls Du Lust hast, mir eine kleine Spende zukommen zu lassen kannst du das gerne
                                machen. Ich freue mich über jeden kleinen Beitrag.
                            </p>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col>
                            <p>
                                Paypal.me Link: <a href="https://www.paypal.com/paypalme/timonovotny">Paypal</a>
                            </p>
                            <v-img width="250" style="margin-top: 25px;"
                                :src="require('@/assets/Bilder/paypalqr.png')" />
                        </v-col>
                        <v-col>
                            <p>
                                Bitcoin Lightning Wallet Adresse: timonovotny@walletofsatoshi.com
                            </p>
                            <v-img width="250" style="margin-top: 25px;"
                                :src="require('@/assets/Bilder/lnurlqr.png')" />
                        </v-col>
                    </v-row>
                </v-container>
            </v-window-item>
            <v-window-item>
                <h2 class="custom-h2" block>Datenschutzerklärung</h2>
                <v-container>
                    <v-row>
                        <v-col>
                            <h3 class="custom-h3">Verantwortlicher</h3>
                            <p class="body-1">
                                Der Verantwortliche für die Datenverarbeitung auf dieser Website ist Timo Novotny,
                                Steigstr. 48, 70565 Stuttgart. Bei Fragen zum Datenschutz erreichen Sie uns per <a
                                    href="mailto:t.novotny.tnds@gmail.com">t.novotny.tnds@gmail.com</a> oder Telefon
                                +49-176-23735517.
                            </p>
                            <h3 class="custom-h3">Art der erhobenen Daten</h3>
                            <p class="body-1">
                                Wir erheben und speichern lediglich Ihre E-Mail-Adresse, die Sie bei der Registrierung
                                oder Authentifizierung auf unserer Website angeben. Darüber hinaus werden nur fiktive
                                Charakterinformationen gespeichert. Es sollen ausdrücklich nur fiktive
                                Charakterinformationen angegeben werden. Bitte hinterlegen Sie keine Informationen über
                                echte Personen.
                            </p>
                            <h3 class="custom-h3">Hintergrund</h3>
                            <p class="body-1">
                                Die Erfassung der E-Mail-Adresse dient ausschließlich der Authentifizierung von Nutzern,
                                um den Zugang zu bestimmten Bereichen der Website zu ermöglichen.
                            </p>
                            <h3 class="custom-h3">Umgang</h3>
                            <p class="body-1">
                                Ihre E-Mail-Adresse wird vertraulich behandelt und auf keinen Fall für Werbezwecke
                                verwendet oder an Dritte weitergegeben.
                            </p>
                            <h3 class="custom-h3">Rechtsgrundlage</h3>
                            <p class="body-1">
                                Die Verarbeitung der E-Mail-Adresse basiert auf Ihrer Einwilligung gemäß Art. 6 Abs. 1
                                lit. a DSGVO. Durch die Nutzung der Funktionen der Website willigen Sie automatisch der
                                Verarbeitung der angegeben Daten ein.
                            </p>
                            <h3 class="custom-h3">Speicherdauer</h3>
                            <p class="body-1">
                                Ihre E-Mail-Adresse wird gespeichert, solange Ihr Konto aktiv ist. Nach der Löschung
                                Ihres Kontos werden Ihre Daten umgehend gelöscht.
                            </p>
                            <h3 class="custom-h3">Betroffenenrechte</h3>
                            <p class="body-1">
                                Ihnen stehen gemäß DSGVO verschiedene Rechte zu, darunter das Recht auf Auskunft,
                                Berichtigung, Löschung Ihrer Daten sowie das Recht, die Einwilligung zur
                                Datenverarbeitung zu widerrufen.
                            </p>
                            <h3 class="custom-h3">Beschwerdenrechte</h3>
                            <p class="body-1">
                                Sie haben das Recht, sich bei der zuständigen Aufsichtsbehörde zu beschweren, wenn Sie
                                der Ansicht sind, dass die Verarbeitung Ihrer Daten nicht rechtmäßig erfolgt.
                            </p>
                            <h3 class="custom-h3">Gewährleistung des Schutzes</h3>
                            <p class="body-1">
                                Wir ergreifen technische und organisatorische Maßnahmen, um die Sicherheit Ihrer Daten
                                zu gewährleisten und sie vor unbefugtem Zugriff zu schützen. Beachten Sie, dass
                                rpg-troll.de eine Hobby-Seite ist.
                            </p>
                            <h3 class="custom-h3">Tools</h3>
                            <h4 class="custom-h4">Google Firebase</h4>
                            <p class="body-1">
                                Auf unserer Website nutzen wir Google Firebase, insbesondere den Firebase Authentication
                                Service, für die Authentifizierung von Nutzern. Dies macht es notwendig, dass Ihre
                                E-Mail-Adresse und Ihr Passwort auf Servern von Google gespeichert werden, um Ihnen den
                                Zugang zu unserem Dienst zu ermöglichen. Google Firebase ist eine Entwicklungsplattform
                                für mobile und Webanwendungen, die von Google LLC angeboten wird. Die Verwendung von
                                Firebase Authentication hilft uns, eine sichere Authentifizierung zu gewährleisten und
                                betrügerische Nutzungsversuche zu minimieren. Weitere Informationen zum Datenschutz bei
                                der Verwendung von Google Firebase finden Sie in der Datenschutzerklärung von Google:
                                https://www.google.com/policies/privacy/.
                            </p>
                            <h4 class="custom-h4">Discrod Web API</h4>
                            <p class="body-1">
                                Zusätzlich zur Nutzung von Google Firebase setzen wir die Discord Web API ein, um
                                Nachrichten in Textkanäle auf Discord-Server zu pushen. Dies ermöglicht es unseren
                                Usern, Würfelergebnisse direkt in Discord Textkanäle zu pushen.. Bei der Nutzung der
                                Discord Web API kann es zur Übermittlung von Daten an die Server von Discord kommen,
                                unter Umständen einschließlich Informationen, die Sie uns zur Verfügung stellen. Discord
                                ist ein Kommunikationsdienst, der von Discord Inc. betrieben wird. Weitere Informationen
                                zum Datenschutz bei Discord finden Sie in der Datenschutzerklärung von Discord:
                                https://discord.com/privacy.
                            </p>
                            <h4 class="custom-h4">Hosting by https://www.netlify.com/</h4>
                            <p class="body-1">
                                Unsere Website wird gehostet durch Netlify, Inc. Netlify ist ein Hosting- und
                                Backend-Service, der speziell für Webprojekte entwickelt wurde und die Einhaltung hoher
                                Datenschutz- und Sicherheitsstandards gewährleistet. Als Hosting-Dienstleister bietet
                                Netlify die notwendige Infrastruktur und Technologie, um unsere Website im Internet
                                verfügbar und zugänglich zu machen.
                                <br><br>
                                Im Rahmen des Hostings können durch Netlify technische Daten erfasst werden, wie z.B.
                                IP-Adressen von Besuchern, zum Zwecke der Netzwerksicherheit und zur Bereitstellung und
                                Verbesserung ihres Services. Diese Datenverarbeitung erfolgt im Einklang mit den
                                geltenden Datenschutzgesetzen und der Datenschutzerklärung von Netlify.
                                <br><br>
                                Weitere Informationen zum Datenschutz bei Netlify finden Sie in der Datenschutzerklärung
                                von Netlify unter: https://www.netlify.com/privacy/.
                            </p>
                            <h4 class="custom-h4">Domain-Registrierung by https://www.united-domains.de/</h4>
                            <p class="body-1">
                                Unsere Domain ist registriert bei United Domains AG, einem führenden Anbieter für
                                Domain-Registrierung. United Domains bietet Dienstleistungen rund um die Registrierung
                                und Verwaltung von Domains an und legt großen Wert auf Datenschutz und Datensicherheit.
                                <br><br>
                                Im Rahmen der Domain-Registrierung und -Verwaltung können durch United Domains Daten
                                erfasst werden, die für die Registrierung und technische Einrichtung der Domain
                                notwendig sind. Dies umfasst unter Umständen auch personenbezogene Daten wie
                                Kontaktinformationen des Domain-Inhabers. United Domains verarbeitet diese Daten in
                                Übereinstimmung mit den geltenden Datenschutzgesetzen.
                                <br><br>
                                Weitere Informationen zum Datenschutz bei United Domains finden Sie in deren
                                Datenschutzerklärung unter:
                                https://www.united-domains.de/datenschutz/">https://www.united-domains.de/datenschutz/
                            </p>
                            <h4 class="custom-h4">Tracking- und Analyse-Tools</h4>
                            <p>
                                Unsere Website setzt keine Tracking- oder Analyse-Tools ein.
                            </p>
                            <h3 class="custom-h3">Datenweitergabe</h3>
                            <p class="body-1">
                                Eine Weitergabe Ihrer Daten an Dritte erfolgt nicht, es sei denn, dies ist gesetzlich
                                erforderlich oder Sie haben ausdrücklich eingewilligt.
                            </p>
                            <h3 class="custom-h3">Datenübermittlung ins Ausland</h3>
                            <p class="body-1">
                                Eine Übermittlung Ihrer Daten ins Ausland findet nicht statt.
                            </p>
                            <h3 class="custom-h3">Verwendete Entwicklungstools</h3>
                            <p class="body-1">
                                Unsere Website wurde unter verwendung moderner Webentwicklungstechnologien und Framewors
                                erstellt. Beachten Sie dass die keinen Einfluss auf die Erfassung personenbezogener
                                Daten hat. Dies ist ein rein Informativer Abschnitt:
                            </p>
                            <h4 class="custom-h4">Vue.js</h4>
                            <p class="body-1">
                                <strong>Vue.js</strong> ist ein progressives JavaScript-Framework, das für den Aufbau
                                benutzerfreundlicher und interaktiver Web-Oberflächen verwendet wird. Es ermöglicht uns,
                                reaktive Komponenten in unserer Webanwendung effizient zu erstellen und zu verwalten.
                            </p>
                            <h4 class="custom-h4">Vuetify</h4>
                            <p class="body-1">
                                <strong>Vuetify</strong> ist ein Vue UI Framework, das eine Vielzahl von vorgefertigten
                                Komponenten und Material Design-Elementen bietet. Durch die Verwendung von Vuetify
                                können wir ein konsistentes und ansprechendes Design auf unserer Website sicherstellen,
                                wodurch die Benutzererfahrung verbessert wird.
                            </p>
                            <h4 class="custom-h4">Vuex</h4>
                            <p class="body-1">
                                <strong>Vuex</strong> ist ein Zustandsverwaltungs-Pattern und -Bibliothek für
                                Vue.js-Anwendungen. Es dient als zentrales Repository für alle Komponenten in einer
                                Anwendung, ermöglicht den Zustandsaustausch zwischen Komponenten und hilft uns, die
                                Zustandsverwaltung unserer Webanwendung effizient zu handhaben.
                            </p>
                        </v-col>
                    </v-row>
                </v-container>
            </v-window-item>
        </v-window>
    </v-card>
</template>

<script>
export default {
    data() {
        return {
            tab: 0,
            tabs: ['Beta Versionshinweise', 'RPG Troll', 'mich', 'Impressum', 'Spenden', 'Datenschutzerklärung']
        }
    },

    created() {
        if (this.$route.path.includes('/impressum')) {
            this.tab = 3
        }
    }

}
</script>

<style>
.body-1 {
    margin-bottom: 25px;
}

.body-2 {
    margin-bottom: 6px;
}
</style>