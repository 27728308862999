<template>
    <h1 class="custom-h1" block v-if="this.$store.state.activeChar" style="height: 80px; padding: 0;">
        <v-container style="padding: 0;">
            <v-row class="centered-content" style="padding: 0;">
                <v-col cols="7" style="padding: 0; margin-bottom: 15px;">
                    {{ Name }}
                </v-col>
                <v-col cols="1" v-if="this.$store.state.User">
                    <v-tooltip text="Speichern" location="bottom">
                        <template v-slot:activator="{ props }">
                            <v-img :src="require('@/assets/OIcon/SpeichernIcon.png')" alt="Speichern" height="45"
                                class="icon-img" v-bind="props" @click="saveChar" v-show="this.Bearbeitungsmodus" />
                        </template>
                    </v-tooltip>
                </v-col>
                <v-col cols="1" v-if="this.$store.state.User">
                    <v-tooltip text="zum Löschen ALT gedrückt halten" location="bottom">
                        <template v-slot:activator="{ props }">
                            <v-img :src="require('@/assets/OIcon/LöschenIcon.png')" alt="Löschen" height="45"
                                class="icon-img" v-bind="props" @click.alt="deleteChar"
                                v-show="this.Bearbeitungsmodus" />
                        </template>
                    </v-tooltip>
                </v-col>
                <v-col cols="3">
                    <v-switch label="Bearbeitungsmodus" color=green v-model="Bearbeitungsmodus" />
                </v-col>
            </v-row>
        </v-container>
    </h1>

    <div class="custom-sitebuttons">
        <v-container>
            <v-row>
                <v-col>
                    <v-btn @click="this.site = 1" class="custom-pointer"
                        :style="this.site == 1 ? 'background-color: rgba(0,50,0,0.5)' : 'background-color: rgba(50,50,50,0.5)'">1</v-btn>
                </v-col>
                <v-col>
                    <v-btn @click="this.site = 2" class="custom-pointer"
                        :style="this.site == 2 ? 'background-color: rgba(0,50,0,0.5)' : 'background-color: rgba(50,50,50,0.5)'">2</v-btn>
                </v-col>
                <v-col>
                    <v-btn @click="this.site = 3" class="custom-pointer"
                        :style="this.site == 3 ? 'background-color: rgba(0,50,0,0.5)' : 'background-color: rgba(50,50,50,0.5)'">3</v-btn>
                </v-col>
            </v-row>
        </v-container>
    </div>

    <!-- SEITE 1 -->
    <v-container v-show="this.site == 1">
        <v-row v-show="this.Bearbeitungsmodus">
            <v-col cols="6">
                <v-text-field label="Name" v-model="Name"></v-text-field>
            </v-col>
            <v-col cols="4">
                <v-file-input label="Charakter Bild hochladen" v-model="fileHandle" ref="fileInput"></v-file-input>
            </v-col>
            <v-col cols="2">
                <v-btn @click="fileHandler('Bild')" style="margin-bottom: 15px;">Upload</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="10"></v-col>
            <v-col cols="2">
                <v-img :src='charPicurl' style="margin-bottom: -999px; margin-top: -25px; margin-right: -80px;" once
                    max-height="145px" max-width="250px"></v-img>
            </v-col>
        </v-row>
        <v-row>
            <v-col xs="12" sm="12" md="4" lg="4" xl="4">
                <v-container
                    style="border: 1px solid #555; border-radius: 8px; padding: 15px; padding-bottom: 25px; padding-right: 20px;">
                    <v-text-field box label="Wurf" v-model="this.cFertigkeit"></v-text-field>
                    <v-row style="margin-bottom: -50px;">
                        <v-col cols="8">
                            <v-text-field label="Mut" v-model="Mut" :disabled="!this.Bearbeitungsmodus"
                                type="Number"></v-text-field>
                        </v-col>
                        <v-col cols="2">
                            <v-checkbox v-model="cb1Mut" type="Number"></v-checkbox>
                        </v-col>
                        <v-col cols="2">
                            <v-checkbox v-model="cb2Mut" type="Number"></v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row style="margin-bottom: -50px;">
                        <v-col cols="8">
                            <v-text-field label="Klugheit" v-model="Klugheit" :disabled="!this.Bearbeitungsmodus"
                                type="Number"></v-text-field>
                        </v-col>
                        <v-col cols="2">
                            <v-checkbox v-model="cb1Klugheit" type="Number"></v-checkbox>
                        </v-col>
                        <v-col cols="2">
                            <v-checkbox v-model="cb2Klugheit" type="Number"></v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row style="margin-bottom: -50px;">
                        <v-col cols="8">
                            <v-text-field label="Intuition" v-model="Intuition" :disabled="!this.Bearbeitungsmodus"
                                type="Number"></v-text-field>
                        </v-col>
                        <v-col cols="2">
                            <v-checkbox v-model="cb1Intuition" type="Number"></v-checkbox>
                        </v-col>
                        <v-col cols="2">
                            <v-checkbox v-model="cb2Intuition" type="Number"></v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row style="margin-bottom: -50px;">
                        <v-col cols="8">
                            <v-text-field label="Charisma" v-model="Charisma" :disabled="!this.Bearbeitungsmodus"
                                type="Number"></v-text-field>
                        </v-col>
                        <v-col cols="2">
                            <v-checkbox v-model="cb1Charisma" type="Number"></v-checkbox>
                        </v-col>
                        <v-col cols="2">
                            <v-checkbox v-model="cb2Charisma" type="Number"></v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row style="margin-bottom: -50px;">
                        <v-col cols="8">
                            <v-text-field label="Fingerfertigkeit" v-model="Fingerfertigkeit"
                                :disabled="!this.Bearbeitungsmodus" type="Number"></v-text-field>
                        </v-col>
                        <v-col cols="2">
                            <v-checkbox v-model="cb1Fingerfertigkeit" type="Number"></v-checkbox>
                        </v-col>
                        <v-col cols="2">
                            <v-checkbox v-model="cb2Fingerfertigkeit" type="Number"></v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row style="margin-bottom: -50px;">
                        <v-col cols="8">
                            <v-text-field label="Gewandtheit" v-model="Gewandtheit" :disabled="!this.Bearbeitungsmodus"
                                type="Number"></v-text-field>
                        </v-col>
                        <v-col cols="2">
                            <v-checkbox v-model="cb1Gewandtheit" type="Number"></v-checkbox>
                        </v-col>
                        <v-col cols="2">
                            <v-checkbox v-model="cb2Gewandtheit" type="Number"></v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row style="margin-bottom: -50px;">
                        <v-col cols="8">
                            <v-text-field label="Konstitution" v-model="Konstitution"
                                :disabled="!this.Bearbeitungsmodus" type="Number"></v-text-field>
                        </v-col>
                        <v-col cols="2">
                            <v-checkbox v-model="cb1Konstitution" type="Number"></v-checkbox>
                        </v-col>
                        <v-col cols="2">
                            <v-checkbox v-model="cb2Konstitution" type="Number"></v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row style="margin-bottom: -50px;">
                        <v-col cols="8">
                            <v-text-field label="Körperkraft" v-model="Körperkraft" :disabled="!this.Bearbeitungsmodus"
                                type="Number"></v-text-field>
                        </v-col>
                        <v-col cols="2">
                            <v-checkbox v-model="cb1Körperkraft" type="Number"></v-checkbox>
                        </v-col>
                        <v-col cols="2">
                            <v-checkbox v-model="cb2Körperkraft" type="Number"></v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-btn color="success" style="margin-top: 20px; margin-left: 30px; margin-bottom: 4px;"
                            @click="roll">Würfeln</v-btn>
                        <v-btn color="success" style="margin-top: 20px; margin-left: 20px; margin-bottom: 4px;"
                            @click="cleanUpCheckboxes">Clear</v-btn>
                    </v-row>
                </v-container>
                <v-container style="border: 1px solid #555; border-radius: 8px; 
                        padding: 15px; margin-top: 5px;
                        padding-bottom: 25px; padding-right: 20px;">
                    <v-row>
                        <v-col>
                            <v-text-field label="w20 (Anzahl)" v-model="this.w20" type="number" />
                            <v-text-field label="w6 (Anzahl)" v-model="this.w6" type="number" />
                            <v-text-field style="margin-bottom: -30px;" label="+Bonus" v-model="this.wbonus"
                                type="number" />
                        </v-col>
                        <v-col class="d-flex align-center">
                            <v-btn color="success" :disabled="+this.w6 <= 0 && +this.w20 <= 0"
                                style="margin-top: 20px; margin-left: 30px; margin-bottom: 4px;" @click="rollrest">
                                Würfeln
                            </v-btn>
                        </v-col>
                    </v-row>

                </v-container>
            </v-col>
            <v-col xs="12" sm="12" md="8" lg="8" xl="8">
                <v-container>
                    <v-row>
                        <v-col>
                            <v-text-field label="Suche" v-model="Suche"></v-text-field>
                        </v-col>
                        <v-col>
                            <v-checkbox label="Zeige nur aktivierte Werte." v-model="showNull"></v-checkbox>
                        </v-col>
                    </v-row>
                    <v-row>
                        <v-col cols="2">
                            <v-text-field label="Fertigkeitswert" v-model="cFertigkeitswert"
                                type="number"></v-text-field>
                        </v-col>
                        <v-col cols="4">
                            <v-btn class="custom-button" @click="saveFertigkeitswert"
                                v-show="this.Bearbeitungsmodus">Fertigkeitswert speichern</v-btn>
                        </v-col>
                    </v-row>
                    <v-data-table density="compact" @click:row="trigger" @contextmenu.prevent="trigger"
                        :headers="Überschriften" :items="gefilterteProben" item-key="name"
                        :sort-by="[{ key: 'name', order: 'asc' }]">
                    </v-data-table>

                </v-container>
            </v-col>
        </v-row>
    </v-container>

    <!-- SEITE 2 -->
    <v-container v-show="this.site == 2">
        <h2 class="custom-h2" block>Notizen</h2>
        <v-row>
            <v-col cols="12">
                <v-btn @click="saveNotizen">speichern</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-textarea label="Notiz" v-model="Notizen" auto-grow rows="1" />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="8">
                <v-textarea label="Notiz" v-model="Notizen1" auto-grow />
            </v-col>
            <v-col cols="3">
                <v-textarea label="Notiz" v-model="Notizen2" auto-grow />
            </v-col>
            <v-col cols="1">
                <v-textarea label="Notiz" v-model="Notizen3" auto-grow />
            </v-col>
        </v-row>
    </v-container>

    <!-- SEITE 3 -->
    <v-container v-show="this.site == 3">
        <h2 class="custom-h2" block>Charakterbogen</h2>
        <v-row v-show="this.Bearbeitungsmodus || !this.charPDFurl">
            <v-col>
                <v-file-input label="Charakterbogen hochladen" v-model="fileHandle" ref="fileInput"></v-file-input>
            </v-col>
            <v-col>
                <v-btn @click="fileHandler('Char')" style="margin-bottom: 15px;">Upload</v-btn>
            </v-col>
        </v-row>
        <iframe v-if="charPDFurl" :src="charPDFurl" width="100%" height="1200px"></iframe>
    </v-container>

</template>

<script>
import { getFirestore, onSnapshot, doc, deleteDoc, updateDoc, setDoc } from 'firebase/firestore';
import { sendMessageToDisc } from '../composables/useDiscord.js'
import { sendMessageToHistory } from '../composables/useSendHistory.js'

import { storage } from '@/firebase.js'
import { getDownloadURL, ref as storageRef, uploadBytes } from 'firebase/storage'

export default {
    data() {
        return {
            unsubscribeActiveChar: null,
            site: 1,
            Bearbeitungsmodus: false,

            Name: '',
            System: '',
            User: '',
            CreatedAt: '',

            Eigenschaften: ['Mut', 'Klugheit', 'Intuition', 'Charisma', 'Fingerfertigkeit', 'Gewandheit', 'Konstitution', 'Körperkraft'],
            Mut: 1,
            Klugheit: 0,
            Intuition: 5,
            Charisma: 0,
            Fingerfertigkeit: 0,
            Gewandtheit: 0,
            Konstitution: 0,
            Körperkraft: 0,
            cb1Mut: false,
            cb1Klugheit: false,
            cb1Intuition: false,
            cb1Charisma: false,
            cb1Fingerfertigkeit: false,
            cb1Gewandtheit: false,
            cb1Konstitution: false,
            cb1Körperkraft: false,
            cb2Mut: false,
            cb2Klugheit: false,
            cb2Intuition: false,
            cb2Charisma: false,
            cb2Fingerfertigkeit: false,
            cb2Gewandtheit: false,
            cb2Konstitution: false,
            cb2Körperkraft: false,

            w6: 0,
            w20: 0,
            wbonus: 0,

            Notizen: '',
            Notizen1: '',
            Notizen2: '',
            Notizen3: '',

            fileHandle: null,
            charPDFurl: '',
            charPicurl: '',

            Suche: '',
            activeProbeId: '',
            showNull: true,
            cFertigkeit: '',
            cFertigkeitswert: 0,
            Überschriften: [
                { title: 'Name', align: 'start', sortable: true, key: 'name' },
                { title: 'Probe', key: 'wP', sortable: false },
                { title: 'Fertigkeitswert', key: 'talentwert' },
            ],
            Proben: [],

        };
    },

    computed: {
        gefilterteProben() {
            return this.Proben.filter(Probe => {
                if (this.showNull) {
                    return Probe.name.toLowerCase().includes(this.Suche.toLowerCase()) && Probe.talentwert !== null
                } else {
                    return Probe.name.toLowerCase().includes(this.Suche.toLowerCase())
                }
            })
        }
    },

    watch: {
        cb1Mut() { console.log("Mut geändert") }
    },

    mounted() {
        this.loadChar()
    },

    beforeUnmount() {
        if (this.unsubscribeActiveChar) {
            this.unsubscribeActiveChar();
        }
    },

    methods: {
        loadChar() {
            if (this.unsubscribeActiveChar) {
                this.unsubscribeActiveChar();
            }
            if (!this.$store.state.activeCharID) {
                this.$router.push({ name: 'home' })
                console.log("activeCharID ist nicht gesetzt. Umgeleitet an Home.");
                return;
            }
            const db = getFirestore();
            const docRef = doc(db, "Charaktere", this.$store.state.activeCharID);
            this.unsubscribeActiveChar = onSnapshot(docRef, (docSnapshot) => {
                if (docSnapshot.exists()) {
                    this.$store.commit('setState', { key: 'activeChar', value: { id: docSnapshot.id, ...docSnapshot.data() } })
                    this.Name = docSnapshot.data().Name
                    this.System = docSnapshot.data().System
                    this.CreatedAt = docSnapshot.data().CreatedAt
                    this.User = docSnapshot.data().User

                    this.Mut = docSnapshot.data().Mut
                    this.Klugheit = docSnapshot.data().Klugheit
                    this.Intuition = docSnapshot.data().Intuition
                    this.Charisma = docSnapshot.data().Charisma
                    this.Fingerfertigkeit = docSnapshot.data().Fingerfertigkeit
                    this.Gewandtheit = docSnapshot.data().Gewandtheit
                    this.Konstitution = docSnapshot.data().Konstitution
                    this.Körperkraft = docSnapshot.data().Körperkraft

                    if (docSnapshot.data().Notizen == undefined) { this.Notizen = '' } else { this.Notizen = docSnapshot.data().Notizen }
                    if (docSnapshot.data().Notizen1 == undefined) { this.Notizen1 = '' } else { this.Notizen1 = docSnapshot.data().Notizen1 }
                    if (docSnapshot.data().Notizen2 == undefined) { this.Notizen2 = '' } else { this.Notizen2 = docSnapshot.data().Notizen2 }
                    if (docSnapshot.data().Notizen3 == undefined) { this.Notizen3 = '' } else { this.Notizen3 = docSnapshot.data().Notizen3 }
                    if (docSnapshot.data().pdfURL == undefined) { this.charPDFurl = '' } else { this.charPDFurl = docSnapshot.data().pdfURL }
                    if (docSnapshot.data().picURL == undefined) { this.charPicurl = '' } else { this.charPicurl = docSnapshot.data().picURL }

                    this.Proben = docSnapshot.data().Proben
                } else {
                    console.log("Kein Dokument mit dieser ID gefunden.");
                    this.$store.commit('setState', { key: 'activeChar', value: null })
                }
            }, (error) => {
                console.error("Fehler beim Empfangen des Charakters:", error);
            });
        },

        saveChar() {
            if (!this.$store.state.activeCharID) {
                console.error("Keine activeCharID verfügbar.")
                return
            }
            try {
                const db = getFirestore();
                const docRef = doc(db, "Charaktere", this.$store.state.activeCharID);
                updateDoc(docRef, {
                    Name: this.Name,
                    Mut: this.Mut,
                    Klugheit: this.Klugheit,
                    Intuition: this.Intuition,
                    Charisma: this.Charisma,
                    Fingerfertigkeit: this.Fingerfertigkeit,
                    Gewandtheit: this.Gewandtheit,
                    Konstitution: this.Konstitution,
                    Körperkraft: this.Körperkraft,
                    Proben: this.Proben
                })
                this.$store.commit('callSnackbar', this.Name + " wurde gespeichert.")
            }
            catch (err) {
                console.error("Fehler beim Speichern des Dokuments: ", err)
            }
        },

        async deleteChar() {
            if (!this.$store.state.activeCharID) {
                console.error("Keine activeCharID verfügbar.")
                return
            }
            try {
                const db = getFirestore();
                const docRef = doc(db, "Charaktere", this.$store.state.activeCharID)
                await deleteDoc(docRef)
                this.$router.push({ name: 'home' })
                this.$store.commit('callSnackbar', this.Name + " wurde gelöscht.")
            }
            catch (err) {
                console.error("Fehler beim Löschen des Charakters: ", err)
            }
        },

        trigger(event, data) {
            if (event && data && data.item) {
                console.log(data.item.name, data.item.w1 + '/' + data.item.w2 + '/' + data.item.w3)
                this.cleanUpCheckboxes()
                this.cFertigkeit = data.item.name
                this.cFertigkeitswert = data.item.talentwert
                this.activeProbeId = data.item.id
                switch (data.item.w1) {
                    case 'Mut':
                        this.cb1Mut = true
                        break
                    case 'Klugheit':
                        this.cb1Klugheit = true
                        break
                    case 'Intuition':
                        this.cb1Intuition = true
                        break
                    case 'Charisma':
                        this.cb1Charisma = true
                        break
                    case 'Fingerfertigkeit':
                        this.cb1Fingerfertigkeit = true
                        break
                    case 'Gewandtheit':
                        this.cb1Gewandtheit = true
                        break
                    case 'Konstitution':
                        this.cb1Konstitution = true
                        break
                    case 'Körperkraft':
                        this.cb1Körperkraft = true
                        break
                }
                switch (data.item.w2) {
                    case 'Mut':
                        if (this.cb1Mut) {
                            this.cb2Mut = true
                        } else {
                            this.cb1Mut = true
                        }
                        break
                    case 'Klugheit':
                        if (this.cb1Klugheit) {
                            this.cb2Klugheit = true
                        } else {
                            this.cb1Klugheit = true
                        }
                        break
                    case 'Intuition':
                        if (this.cb1Intuition) {
                            this.cb2Intuition = true
                        } else {
                            this.cb1Intuition = true
                        }
                        break
                    case 'Charisma':
                        if (this.cb1Charisma) {
                            this.cb2Charisma = true
                        } else {
                            this.cb1Charisma = true
                        }
                        break
                    case 'Fingerfertigkeit':
                        if (this.cb1Fingerfertigkeit) {
                            this.cb2Fingerfertigkeit = true
                        } else {
                            this.cb1Fingerfertigkeit = true
                        }
                        break
                    case 'Gewandtheit':
                        if (this.cb1Gewandtheit) {
                            this.cb2Gewandtheit = true
                        } else {
                            this.cb1Gewandtheit = true
                        }
                        break
                    case 'Konstitution':
                        if (this.cb1Konstitution) {
                            this.cb2Konstitution = true
                        } else {
                            this.cb1Konstitution = true
                        }
                        break
                    case 'Körperkraft':
                        if (this.cb1Körperkraft) {
                            this.cb2Körperkraft = true
                        } else {
                            this.cb1Körperkraft = true
                        }
                        break
                }
                switch (data.item.w3) {
                    case 'Mut':
                        if (this.cb1Mut) {
                            this.cb2Mut = true
                        } else {
                            this.cb1Mut = true
                        }
                        break
                    case 'Klugheit':
                        if (this.cb1Klugheit) {
                            this.cb2Klugheit = true
                        } else {
                            this.cb1Klugheit = true
                        }
                        break
                    case 'Intuition':
                        if (this.cb1Intuition) {
                            this.cb2Intuition = true
                        } else {
                            this.cb1Intuition = true
                        }
                        break
                    case 'Charisma':
                        if (this.cb1Charisma) {
                            this.cb2Charisma = true
                        } else {
                            this.cb1Charisma = true
                        }
                        break
                    case 'Fingerfertigkeit':
                        if (this.cb1Fingerfertigkeit) {
                            this.cb2Fingerfertigkeit = true
                        } else {
                            this.cb1Fingerfertigkeit = true
                        }
                        break
                    case 'Gewandtheit':
                        if (this.cb1Gewandtheit) {
                            this.cb2Gewandtheit = true
                        } else {
                            this.cb1Gewandtheit = true
                        }
                        break
                    case 'Konstitution':
                        if (this.cb1Konstitution) {
                            this.cb2Konstitution = true
                        } else {
                            this.cb1Konstitution = true
                        }
                        break
                    case 'Körperkraft':
                        if (this.cb1Körperkraft) {
                            this.cb2Körperkraft = true
                        } else {
                            this.cb1Körperkraft = true
                        }
                        break
                }
            }
            if (event.altKey) {
                this.roll()
            }
        },

        roll() {
            let result = 'wirft '
            let total = +this.cFertigkeitswert

            result += this.cFertigkeit
            if (this.cFertigkeitswert) {
                result += " (" + this.cFertigkeitswert + ") \n"
            }

            if (this.cb1Mut) {
                let roll = Math.floor(Math.random() * 20) + 1
                result += "Mut: " + this.Mut + ", Gewürfelt: " + roll
                if (+roll == 20) {
                    result += " \u{1F4A9}" //poop
                }
                if (+roll == 1) {
                    result += " \u{1F929}" //starstruck
                }
                if (+roll > +this.Mut) {
                    result += " \u{1F44E}" //thumbsdown                            
                    result += (+this.Mut - +roll)
                    total += (+this.Mut - +roll)
                }
                if (+roll <= +this.Mut) {
                    result += " \u{1F44D}" //thumbsup
                }
                result += "\n"
            }
            if (this.cb2Mut) {
                let roll = Math.floor(Math.random() * 20) + 1
                result += "Mut: " + this.Mut + ", Gewürfelt: " + roll
                if (+roll == 20) {
                    result += " \u{1F4A9}" //poop
                }
                if (+roll == 1) {
                    result += " \u{1F929}" //starstruck
                }
                if (+roll > +this.Mut) {
                    result += " \u{1F44E}" //thumbsdown                            
                    result += (+this.Mut - +roll)
                    total += (+this.Mut - +roll)
                }
                if (+roll <= +this.Mut) {
                    result += " \u{1F44D}" //thumbsup
                }
                result += "\n"
            }
            if (this.cb1Klugheit) {
                let roll = Math.floor(Math.random() * 20) + 1
                result += "Klugheit: " + this.Klugheit + ", Gewürfelt: " + roll
                if (+roll == 20) {
                    result += " \u{1F4A9}" //poop
                }
                if (+roll == 1) {
                    result += " \u{1F929}" //starstruck
                }
                if (+roll > +this.Klugheit) {
                    result += " \u{1F44E}" //thumbsdown                            
                    result += (+this.Klugheit - +roll)
                    total += (+this.Klugheit - +roll)
                }
                if (+roll <= +this.Klugheit) {
                    result += " \u{1F44D}" //thumbsup
                }
                result += "\n"
            }
            if (this.cb2Klugheit) {
                let roll = Math.floor(Math.random() * 20) + 1
                result += "Klugheit: " + this.Klugheit + ", Gewürfelt: " + roll
                if (+roll == 20) {
                    result += " \u{1F4A9}" //poop
                }
                if (+roll == 1) {
                    result += " \u{1F929}" //starstruck
                }
                if (+roll > +this.Klugheit) {
                    result += " \u{1F44E}" //thumbsdown                            
                    result += (+this.Klugheit - +roll)
                    total += (+this.Klugheit - +roll)
                }
                if (+roll <= +this.Klugheit) {
                    result += " \u{1F44D}" //thumbsup
                }
                result += "\n"
            }
            if (this.cb1Intuition) {
                let roll = Math.floor(Math.random() * 20) + 1
                result += "Intuition: " + this.Intuition + ", Gewürfelt: " + roll
                if (+roll == 20) {
                    result += " \u{1F4A9}" //poop
                }
                if (+roll == 1) {
                    result += " \u{1F929}" //starstruck
                }
                if (+roll > +this.Intuition) {
                    result += " \u{1F44E}" //thumbsdown                            
                    result += (+this.Intuition - +roll)
                    total += (+this.Intuition - +roll)
                }
                if (+roll <= +this.Intuition) {
                    result += " \u{1F44D}" //thumbsup
                }
                result += "\n"
            }
            if (this.cb2Intuition) {
                let roll = Math.floor(Math.random() * 20) + 1
                result += "Intuition: " + this.Intuition + ", Gewürfelt: " + roll
                if (+roll == 20) {
                    result += " \u{1F4A9}" //poop
                }
                if (+roll == 1) {
                    result += " \u{1F929}" //starstruck
                }
                if (+roll > +this.Intuition) {
                    result += " \u{1F44E}" //thumbsdown                            
                    result += (+this.Intuition - +roll)
                    total += (+this.Intuition - +roll)
                }
                if (+roll <= +this.Intuition) {
                    result += " \u{1F44D}" //thumbsup
                }
                result += "\n"
            }
            if (this.cb1Charisma) {
                let roll = Math.floor(Math.random() * 20) + 1
                result += "Charisma: " + this.Charisma + ", Gewürfelt: " + roll
                if (+roll == 20) {
                    result += " \u{1F4A9}" //poop
                }
                if (+roll == 1) {
                    result += " \u{1F929}" //starstruck
                }
                if (+roll > +this.Charisma) {
                    result += " \u{1F44E}" //thumbsdown                            
                    result += (+this.Charisma - +roll)
                    total += (+this.Charisma - +roll)
                }
                if (+roll <= +this.Charisma) {
                    result += " \u{1F44D}" //thumbsup
                }
                result += "\n"
            }
            if (this.cb2Charisma) {
                let roll = Math.floor(Math.random() * 20) + 1
                result += "Charisma: " + this.Charisma + ", Gewürfelt: " + roll
                if (+roll == 20) {
                    result += " \u{1F4A9}" //poop
                }
                if (+roll == 1) {
                    result += " \u{1F929}" //starstruck
                }
                if (+roll > +this.Charisma) {
                    result += " \u{1F44E}" //thumbsdown                            
                    result += (+this.Charisma - +roll)
                    total += (+this.Charisma - +roll)
                }
                if (+roll <= +this.Charisma) {
                    result += " \u{1F44D}" //thumbsup
                }
                result += "\n"
            }
            if (this.cb1Fingerfertigkeit) {
                let roll = Math.floor(Math.random() * 20) + 1
                result += "Fingerfertigkeit: " + this.Fingerfertigkeit + ", Gewürfelt: " + roll
                if (+roll == 20) {
                    result += " \u{1F4A9}" //poop
                }
                if (+roll == 1) {
                    result += " \u{1F929}" //starstruck
                }
                if (+roll > +this.Fingerfertigkeit) {
                    result += " \u{1F44E}" //thumbsdown                            
                    result += (+this.Fingerfertigkeit - +roll)
                    total += (+this.Fingerfertigkeit - +roll)
                }
                if (+roll <= +this.Fingerfertigkeit) {
                    result += " \u{1F44D}" //thumbsup
                }
                result += "\n"
            }
            if (this.cb2Fingerfertigkeit) {
                let roll = Math.floor(Math.random() * 20) + 1
                result += "Fingerfertigkeit: " + this.Fingerfertigkeit + ", Gewürfelt: " + roll
                if (+roll == 20) {
                    result += " \u{1F4A9}" //poop
                }
                if (+roll == 1) {
                    result += " \u{1F929}" //starstruck
                }
                if (+roll > +this.Fingerfertigkeit) {
                    result += " \u{1F44E}" //thumbsdown                            
                    result += (+this.Fingerfertigkeit - +roll)
                    total += (+this.Fingerfertigkeit - +roll)
                }
                if (+roll <= +this.Fingerfertigkeit) {
                    result += " \u{1F44D}" //thumbsup
                }
                result += "\n"
            }
            if (this.cb1Gewandtheit) {
                let roll = Math.floor(Math.random() * 20) + 1
                result += "Gewandtheit: " + this.Gewandtheit + ", Gewürfelt: " + roll
                if (+roll == 20) {
                    result += " \u{1F4A9}" //poop
                }
                if (+roll == 1) {
                    result += " \u{1F929}" //starstruck
                }
                if (+roll > +this.Gewandtheit) {
                    result += " \u{1F44E}" //thumbsdown                            
                    result += (+this.Gewandtheit - +roll)
                    total += (+this.Gewandtheit - +roll)
                }
                if (+roll <= +this.Gewandtheit) {
                    result += " \u{1F44D}" //thumbsup
                }
                result += "\n"
            }
            if (this.cb2Gewandtheit) {
                let roll = Math.floor(Math.random() * 20) + 1
                result += "Gewandtheit: " + this.Gewandtheit + ", Gewürfelt: " + roll
                if (+roll == 20) {
                    result += " \u{1F4A9}" //poop
                }
                if (+roll == 1) {
                    result += " \u{1F929}" //starstruck
                }
                if (+roll > +this.Gewandtheit) {
                    result += " \u{1F44E}" //thumbsdown                            
                    result += (+this.Gewandtheit - +roll)
                    total += (+this.Gewandtheit - +roll)
                }
                if (+roll <= +this.Gewandtheit) {
                    result += " \u{1F44D}" //thumbsup
                }
                result += "\n"
            }
            if (this.cb1Konstitution) {
                let roll = Math.floor(Math.random() * 20) + 1
                result += "Konstitution: " + this.Konstitution + ", Gewürfelt: " + roll
                if (+roll == 20) {
                    result += " \u{1F4A9}" //poop
                }
                if (+roll == 1) {
                    result += " \u{1F929}" //starstruck
                }
                if (+roll > +this.Konstitution) {
                    result += " \u{1F44E}" //thumbsdown                            
                    result += (+this.Konstitution - +roll)
                    total += (+this.Konstitution - +roll)
                }
                if (+roll <= +this.Konstitution) {
                    result += " \u{1F44D}" //thumbsup
                }
                result += "\n"
            }
            if (this.cb2Konstitution) {
                let roll = Math.floor(Math.random() * 20) + 1
                result += "Konstitution: " + this.Konstitution + ", Gewürfelt: " + roll
                if (+roll == 20) {
                    result += " \u{1F4A9}" //poop
                }
                if (+roll == 1) {
                    result += " \u{1F929}" //starstruck
                }
                if (+roll > +this.Konstitution) {
                    result += " \u{1F44E}" //thumbsdown                            
                    result += (+this.Konstitution - +roll)
                    total += (+this.Konstitution - +roll)
                }
                if (+roll <= +this.Konstitution) {
                    result += " \u{1F44D}" //thumbsup
                }
                result += "\n"
            }
            if (this.cb1Körperkraft) {
                let roll = Math.floor(Math.random() * 20) + 1
                result += "Körperkraft: " + this.Körperkraft + ", Gewürfelt: " + roll
                if (+roll == 20) {
                    result += " \u{1F4A9}" //poop
                }
                if (+roll == 1) {
                    result += " \u{1F929}" //starstruck
                }
                if (+roll > +this.Körperkraft) {
                    result += " \u{1F44E}" //thumbsdown                            
                    result += (+this.Körperkraft - +roll)
                    total += (+this.Körperkraft - +roll)
                }
                if (+roll <= +this.Körperkraft) {
                    result += " \u{1F44D}" //thumbsup
                }
                result += "\n"
            }
            if (this.cb2Körperkraft) {
                let roll = Math.floor(Math.random() * 20) + 1
                result += "Körperkraft: " + this.Körperkraft + ", Gewürfelt: " + roll
                if (+roll == 20) {
                    result += " \u{1F4A9}" //poop
                }
                if (+roll == 1) {
                    result += " \u{1F929}" //starstruck
                }
                if (+roll > +this.Körperkraft) {
                    result += " \u{1F44E}" //thumbsdown                            
                    result += (+this.Körperkraft - +roll)
                    total += (+this.Körperkraft - +roll)
                }
                if (+roll <= +this.Körperkraft) {
                    result += " \u{1F44D}" //thumbsup
                }
                result += "\n"
            }
            this.$store.commit('callDicer', { result: result, total: total })
            result += " = " + total
            sendMessageToDisc(result, this.Name)
            sendMessageToHistory(result)
        },

        rollrest() {
            let result = 'wirft '
            let total = 0
            if (this.cFertigkeit) {
                result += this.cFertigkeit + ' '
            }
            if (+this.w20 > 0) {
                result += this.w20 + "w20 ("
                for (let i = 0; i < this.w20; i++) {
                    var roll = Math.floor(Math.random() * 20) + 1
                    result += roll + ", "
                    total += +roll
                }
                result = result.slice(0, -2)
                result += ") "
            }
            if (+this.w6 > 0) {
                if (+this.w20 > 0) { result += " + " }
                result += this.w6 + "w6 ("
                for (let i = 0; i < this.w6; i++) {
                    var roll2 = Math.floor(Math.random() * 6) + 1
                    result += roll2 + ", "
                    total += +roll2
                }
                result = result.slice(0, -2)
                result += ")"
            }
            if (this.wbonus) {
                result += " +" + this.wbonus
                total += +this.wbonus
            }
            this.$store.commit('callDicer', { result: result, total: total })
            result += " = " + total
            sendMessageToDisc(result, this.Name)
            sendMessageToHistory(result)
        },

        saveFertigkeitswert() {
            if (!this.$store.state.activeCharID) {
                console.error("Keine activeCharID verfügbar.")
                return
            }

            if (!this.activeProbeId) {
                console.error("Keine Aktive Probe ID verfügbar.")
                return
            }

            try {
                const db = getFirestore()
                const docRef = doc(db, "Charaktere", this.$store.state.activeCharID)

                let Proben = this.Proben || []
                let index = Proben.findIndex(obj => obj.id === this.activeProbeId)

                if (this.cFertigkeitswert == "") {
                    Proben[index].talentwert = null
                } else {
                    Proben[index].talentwert = this.cFertigkeitswert
                }

                updateDoc(docRef, {
                    Proben: Proben
                })
                this.$store.commit('callSnackbar', 'Der neue Fertigkeitswert ' + Proben[index].talentwert + 'wurde für ' + Proben[index].name + " gespeichert.")
            } catch (err) {
                console.error("Fehler beim Speichern des Dokuments: ", err);
            }
        },

        saveNotizen() {
            if (!this.$store.state.activeCharID) {
                console.error("Keine activeCharID verfügbar.")
                return
            }
            try {
                const db = getFirestore()
                const docRef = doc(db, "Charaktere", this.$store.state.activeCharID)
                console.log(this.Notizen)
                setDoc(docRef, {
                    Notizen: this.Notizen,
                    Notizen1: this.Notizen1,
                    Notizen2: this.Notizen2,
                    Notizen3: this.Notizen3,
                }, { merge: true })
                this.$store.commit('callSnackbar', "Notiz gespeichert.")
            } catch (err) {
                console.error("Fehler beim Speichern der Notiz: ", err)
            }
        },

        cleanUpCheckboxes() {
            this.cFertigkeitswert = ''
            this.cb1Mut = false
            this.cb1Klugheit = false
            this.cb1Intuition = false
            this.cb1Charisma = false
            this.cb1Fingerfertigkeit = false
            this.cb1Gewandtheit = false
            this.cb1Konstitution = false
            this.cb1Körperkraft = false
            this.cb2Mut = false
            this.cb2Klugheit = false
            this.cb2Intuition = false
            this.cb2Charisma = false
            this.cb2Fingerfertigkeit = false
            this.cb2Gewandtheit = false
            this.cb2Konstitution = false
            this.cb2Körperkraft = false
            this.cFertigkeit = ''
            this.w6 = 0
            this.w20 = 0
            this.wbonus = 0
        },

        async fileHandler(Dateiart) {
            if (!this.fileHandle) return
            const file = this.fileHandle
            const fileRef = storageRef(storage, `${this.$store.state.activeCharID}/${file.name}`)
            try {
                await uploadBytes(fileRef, file)
                const url = await getDownloadURL(fileRef)
                this.fileHandle = null
                if (Dateiart == 'Char') { this.charPDFurl = url }
                else if (Dateiart == 'Bild') { this.charPicurl = url }
      //          this.$refs.fileInput.reset();
            } catch (err) {
                console.error('Fehler beim Hochladen der PDF', err)
            }

            if (!this.$store.state.activeCharID) {
                console.error("Keine activeCharID verfügbar.")
                return
            }
            try {
                const db = getFirestore()
                const docRef = doc(db, "Charaktere", this.$store.state.activeCharID)
                setDoc(docRef, {
                    pdfURL: this.charPDFurl,
                    picURL: this.charPicurl,
                }, { merge: true })
            } catch (err) {
                console.error("Fehler beim Speichern des Dokuments: ", err)
            }
        }

    }
}
</script>

<style>
.button-wuerfel {
    margin-bottom: 12px;
    cursor: pointer;
    height: 32px;
    opacity: 0.6;
    transition: opacity 0.2s ease;
}

.button-wuerfel:hover {
    opacity: 1.0;
    cursor: pointer;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.fade-enter-to,
.fade-leave-from {
    opacity: 1;
}
</style>