<template>
    <NavBars />
    <CharTabDnD />
</template>

<script>
import { defineComponent } from 'vue'

// Components
import NavBars from '@/components/NavBars.vue'
import CharTabDnD from '@/components/CharTabDnD'

export default defineComponent({
name: 'wTool',

components: {
  NavBars, CharTabDnD
},
});
</script>