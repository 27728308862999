<template>
    <h1 class="custom-h1" block v-if="this.$store.state.activeKampagne" style="height: 80px; padding: 0;">
        <v-container style="padding: 0;">
            <v-row class="centered-content" style="padding: 0;">
                <v-col cols="7" style="padding: 0; margin-bottom: 15px;">
                    {{ Name }}
                </v-col>
                <v-col cols="1" v-if="this.$store.state.User">
                    <v-tooltip text="Speichern" location="bottom">
                        <template v-slot:activator="{ props }">
                            <v-img :src="require('@/assets/OIcon/SpeichernIcon.png')" alt="Speichern" height="45"
                                class="icon-img" v-bind="props" @click="saveKampagne" v-show="this.Bearbeitungsmodus" />
                        </template>
                    </v-tooltip>
                </v-col>
                <v-col cols="1" v-if="this.$store.state.User">
                    <v-tooltip text="zum Löschen ALT gedrückt halten" location="bottom">
                        <template v-slot:activator="{ props }">
                            <v-img :src="require('@/assets/OIcon/LöschenIcon.png')" alt="Löschen" height="45"
                                class="icon-img" v-bind="props" @click.alt="deleteKampagne"
                                v-show="this.Bearbeitungsmodus" />
                        </template>
                    </v-tooltip>
                </v-col>
                <v-col cols="3">
                    <v-switch label="Bearbeitungsmodus" color=green v-model="Bearbeitungsmodus" />
                </v-col>
            </v-row>
        </v-container>
    </h1>
    <v-container style="padding: 0;">
        <v-text-field v-if="this.Bearbeitungsmodus" density="compact" label="Name" v-model="Name" variant="outlined"
            style="margin-bottom: -10px;" />
        <v-text-field v-if="this.Bearbeitungsmodus" density="compact" label="Beschreibung" v-model="Beschreibung"
            variant="outlined" style="margin-bottom: -10px;" />
        <v-row>
            <v-col>
                <v-text-field label="Bezeichnung" v-model="Bezeichnung" density="compact" variant="outlined" />
                <v-text-field label="Bemerkung" v-model="Bemerkung" density="compact" variant="outlined" />
                <v-text-field label="Sortiernummer" v-model="Sortiernummer" density="compact" type="number"
                    variant="outlined" />
                <v-text-field label="Suchfeld" v-model="Suchfeld" density="compact" variant="outlined" />
                <v-select v-model="Art" label="Art" :items="this.$store.state.Arten" density="compact"
                    variant="outlined" />
                <v-textarea label="Langtext" v-model="Langtext" density="compact" variant="outlined" auto-grow />

            </v-col>
            <v-col>
                <v-text-field label="Suche" v-model="Suche" density="compact" variant="outlined" clearable
                    append-inner-icon="mdi-magnify" />

                <v-btn class="custom-button" @click="saveC" v-show="this.AktiveContentID" density="compact"
                    color="rgb(0,50,0)">Speichern</v-btn>
                <v-btn class="custom-button" @click="saveCNew" v-show="this.Bezeichnung && !this.AktiveContentID" density="compact">Speichern als
                    neu</v-btn>
                <v-btn class="custom-button" @click="saveCNewUnder" v-show="this.Bezeichnung && this.AktiveContentID" density="compact">Speichern als
                    neu unter</v-btn>
                <v-btn class="custom-button" @click="deleteC" v-show="this.AktiveContentID" density="compact"
                    color="rgb(50,0,0)">Löschen</v-btn>
                <v-btn class="custom-button" @click="clearActiveContent" density="compact">Clear</v-btn>

                <v-data-table density="compact" @click:row="trigger" @contextmenu.prevent="trigger" hide-default-footer
                    items-per-page="0" :headers="gefilterteHeader" :items="gefilterteOnSearch" item-key="id"
                    :sort-by="[{ key: 'sort', order: 'asc' }]" />

                <v-spacer v-if="false" style="margin: 20px;">_alle:</v-spacer>
                <v-data-table v-if="false" density="compact" @click:row="trigger" @contextmenu.prevent="trigger" hide-default-footer
                    items-per-page="0" :headers="gefilterteHeader" :items="gefilterteContents" item-key="id"
                    :sort-by="[{ key: 'sort', order: 'asc' }]" />
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { getFirestore, onSnapshot, doc, deleteDoc, updateDoc } from 'firebase/firestore';

export default {
    data() {
        return {
            Bearbeitungsmodus: false,

            unsubscribeActiveKampagne: null,

            Name: '',
            Beschreibung: '',
            User: '',
            CreatedAt: '',

            //Content Überschriften
            AktiveContentID: '',
            Bezeichnung: '',
            Bemerkung: '',
            Sortiernummer: '',
            Suchfeld: '',
            Art: '',
            Langtext: '',

            Überschriften: [
                { title: 'ID', key: 'id', sortable: true, visible: false },
                { title: 'Bezeichnung', key: 'bezeichnung', sortable: true, visible: true },
                { title: 'Bemerkung', key: 'bemerkung', sortable: false, visible: true },
                { title: 'Art', key: 'art', sortable: false, visible: true },
                { title: 'Langtext', key: 'langtext', sortable: false, visible: false },
                { title: '#', key: 'sort', sortable: true, visible: true },
                { title: 'onsearch', key: 'onsearch', sortable: true, visible: false },
            ],
            Contents: [],

            Suche: '',
        };
    },

    computed: {
        gefilterteContents() {
            let contents = this.Contents.filter(content => {
                if (this.Suche) {
                    return content.bezeichnung.toLowerCase().includes(this.Suche.toLowerCase()) || content.bemerkung.toLowerCase().includes(this.Suche.toLocaleLowerCase())
                } else {
                    return true
                }
            })
            return contents
        },

        gefilterteOnSearch() {
            let contents = this.Contents.filter(content => {
                if (this.Bezeichnung) {
                    return content.onsearch && content.onsearch.toLowerCase().includes(this.Bezeichnung.toLowerCase())
                } else {
                    return true
                }
            })
            return contents
        },

        gefilterteHeader() {
            return this.Überschriften.filter(header => header.visible !== false);
        }
    },

    mounted() {
        this.loadKampagne()
        window.addEventListener('keydown', this.handleKeyDown)
    },

    beforeUnmount() {
        if (this.unsubscribeActiveKampagne) {
            this.unsubscribeActiveKampagne();
        }
        window.removeEventListener('keydown', this.handleKeyDown)
    },

    methods: {
        loadKampagne() {
            if (this.unsubscribeActiveKampagne) {
                this.unsubscribeActiveKampagne()
            }

            if (!this.$store.state.activeKampagneID) {
                console.log("activeKampagneID ist nicht gesetzt. Umgeleitet an Home.")
                this.$router.push({ name: 'home' })
                return
            }


            const db = getFirestore();
            const docRef = doc(db, "Kampagnen", this.$store.state.activeKampagneID);
            this.unsubscribeActiveKampagne = onSnapshot(docRef, (docSnapshot) => {
                if (docSnapshot.exists()) {
                    this.$store.commit('setState', { key: 'activeKampagne', value: { id: docSnapshot.id, ...docSnapshot.data() } })
                    this.Name = docSnapshot.data().Name
                    this.Beschreibung = docSnapshot.data().Beschreibung
                    this.User = docSnapshot.data().User
                    this.CreatedAt = docSnapshot.data().CreatedAt
                    this.Contents = docSnapshot.data().Contents
                } else {
                    console.log("Keine Kampagne mit dieser ID gefunden.");
                    this.$store.commit('setState', { key: 'activeKampagne', value: null })
                }
            }, (error) => {
                console.error("Fehler beim Empfangen der Kampagne:", error);
            });
        },

        saveKampagne() {
            if (!this.$store.state.activeKampagneID) {
                console.error("Keine activeKampagneID verfügbar.")
                return
            }
            try {
                const db = getFirestore();
                const docRef = doc(db, "Kampagnen", this.$store.state.activeKampagneID);
                updateDoc(docRef, {
                    Name: this.Name,
                    Beschreibung: this.Beschreibung,
                    Contents: this.Contents,
                })
                this.$store.commit('callSnackbar', this.Name + " wurde gespeichert.")
                this.Bearbeitungsmodus = false
            }
            catch (err) {
                console.error("Fehler beim Speichern des Dokuments: ", err)
            }
        },

        async deleteKampagne() {
            if (!this.$store.state.activeKampagneID) {
                console.error("Keine activeKampagneID verfügbar.")
                return
            }
            try {
                const db = getFirestore();
                const docRef = doc(db, "Kampagnen", this.$store.state.activeKampagneID)
                await deleteDoc(docRef)
                this.$router.push({ name: 'home' })
                this.$store.commit('callSnackbar', this.Name + " wurde gelöscht.")
            }
            catch (err) {
                console.error("Fehler beim Löschen der Kampagne: ", err)
            }
        },


        saveC() {
            if (!this.$store.state.activeKampagneID) {
                console.error("Keine activeKampagneID verfügbar.")
                return
            }

            if (!this.AktiveContentID) {
                console.error("Keine Aktive Content ID verfügbar.")
                return
            }

            try {
                const db = getFirestore()
                const docRef = doc(db, "Kampagnen", this.$store.state.activeKampagneID)

                let Contents = this.Contents || []
                let index = Contents.findIndex(obj => obj.id === this.AktiveContentID)

                Contents[index].bezeichnung = this.Bezeichnung
                Contents[index].bemerkung = this.Bemerkung
                Contents[index].sort = this.Sortiernummer
                Contents[index].onsearch = this.Suchfeld
                Contents[index].langtext = this.Langtext
                Contents[index].art = this.Art

                updateDoc(docRef, {
                    Contents: Contents
                })
                this.$store.commit('callSnackbar', 'Content ' + Contents[index].bezeichnung + ' gespeichert.')
            } catch (err) {
                console.error("Fehler beim Speichern des Dokuments: ", err);
            }
        },


        saveCNewUnder() {
            if (!this.$store.state.activeKampagneID) {
                console.error("Keine activeKampagneID verfügbar.")
                return
            }

            if (!this.AktiveContentID) {
                console.error("Keine Aktive Content ID verfügbar.")
                return
            }

            try {
                const db = getFirestore()
                const docRef = doc(db, "Kampagnen", this.$store.state.activeKampagneID)

                let Contents = this.Contents || []
                let index = Contents.findIndex(obj => obj.id === this.AktiveContentID)

                Contents[index].onsearch = this.Suchfeld + ", " + this.Bezeichnung



                updateDoc(docRef, {
                    Contents: Contents
                })
            } catch (err) {
                console.error("Fehler beim Speichern des Dokuments: ", err);
            }
            this.saveCNew()
        },

        saveCNew() {
            if (!this.$store.state.activeKampagneID) {
                console.error("Keine activeKampagneID verfügbar.")
                return
            }

            try {
                const db = getFirestore()
                const docRef = doc(db, "Kampagnen", this.$store.state.activeKampagneID)
                const nid = Math.random().toString(36).substr(2, 20) + Date.now().toString()
                this.AktiveContentID = nid
                let contents = this.Contents || []

                let neuerContent = {
                    id: nid,
                    bezeichnung: this.Bezeichnung,
                    bemerkung: this.Bemerkung,
                    sort: this.Sortiernummer,
                    onsearch: this.Suchfeld,
                    langtext: this.Langtext,
                    art: this.Art,
                }

                if (this.Art == 'Ort') {neuerContent.sort = 1}
                if (this.Art == 'Kontakt') {neuerContent.sort = 11}
                if (this.Art == 'NPC+') {neuerContent.sort = 21}
                if (this.Art == 'NPC-') {neuerContent.sort = 31}
                if (this.Art == 'Gruppe') {neuerContent.sort = 41}
                if (this.Art == 'Kampagne') {neuerContent.sort = 51}
                if (this.Art == 'Szene') {neuerContent.sort = 61}

                contents.push(neuerContent)

                updateDoc(docRef, {
                    Contents: contents
                })
                this.$store.commit('callSnackbar', neuerContent.bezeichnung + " wurde hinzugefügt.")

                this.clearActiveContent()

            } catch (err) {
                console.error("Fehler beim Speichern des Dokuments: ", err);
            }
        },

        deleteC() {
            try {
                const db = getFirestore()
                const docRef = doc(db, "Kampagnen", this.$store.state.activeKampagneID)
                let contents = this.Contents
                let index = contents.findIndex(obj => obj.id === this.AktiveContentID)
                if (index !== -1) {
                    contents.splice(index, 1)
                }
                this.Contents = contents
                updateDoc(docRef, {
                    Contents: contents
                })

                this.clearActiveContent()

            } catch (err) {
                console.error("Fehler beim Löschen des geispeicherten Contents: ", err)
            }
        },

        clearActiveContent() {
            this.AktiveContentID = null
            this.Bezeichnung = ''
            this.Bemerkung = ''
            this.Sortiernummer = ''
            this.Suchfeld = ''
            this.Langtext = ''
            this.Art = ''
        },

        trigger(event, data) {
            if (event && data && data.item) {

                if (event, data) {
                    this.AktiveContentID = data.item.id
                    this.Bezeichnung = data.item.bezeichnung
                    this.Bemerkung = data.item.bemerkung
                    this.Sortiernummer = data.item.sort
                    this.Suchfeld = data.item.onsearch
                    this.Langtext = data.item.langtext
                    this.Art = data.item.art
                }
            }
        },

        handleKeyDown(event) {
            if ((event.ctrlKey || event.metaKey) && event.key === 's') {
                event.preventDefault()
                if (this.AktiveContentID) {
                    this.saveC()
                }
            }
        }
    },
}

</script>

<style>
.button-wuerfel {
    margin-bottom: 12px;
    cursor: pointer;
    height: 32px;
    opacity: 0.6;
    transition: opacity 0.2s ease;
}

.button-wuerfel:hover {
    opacity: 1.0;
    cursor: pointer;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.fade-enter-to,
.fade-leave-from {
    opacity: 1;
}
</style>