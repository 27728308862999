<template>
  <h1 class="custom-h1" block v-if="SLogin">Login</h1>
  <h1 class="custom-h1" block v-if="SReg">Registrierung</h1>
  <h1 class="custom-h1" block v-if="SPWF">Passwort zurücksetzen</h1>

  <v-container v-if="SLogin">
    <v-form ref="loginForm" @submit.prevent>
      <v-text-field label="E-Mail" prepend-icon="mdi-email" type="email" v-model="email"
        :rules="[rules.required, rules.email]"></v-text-field>
      <v-text-field label="Passwort" prepend-icon="mdi-lock" type="password" v-model="password"
        :rules="[rules.required, rules.minLength]"></v-text-field>
      <v-btn type="submit" class="custom-button" @click="Login">Login</v-btn>
      <v-label class="custom-label" link @click="showReg">Neu Registrieren</v-label>
      <v-label class="custom-label" link @click="showPW">Passwort zurücksetzen</v-label>
    </v-form>
  </v-container>

  <v-container v-if="SReg">
    <v-form ref="regForm" @submit.prevent>
      <v-text-field label="Name" prepend-icon="mdi-account" type="text" v-model="name"
        :rules="[rules.required, rules.minLength2]"></v-text-field>
      <v-text-field label="E-Mail" prepend-icon="mdi-email" type="email" v-model="email"
        :rules="[rules.required, rules.email]"></v-text-field>
      <v-text-field label="Passwort" prepend-icon="mdi-lock" type="password" v-model="password"
        :rules="[rules.required, rules.minLength]"></v-text-field>
      <v-btn type="register" class="custom-button" @click="Register">Neu Registrieren</v-btn>
      <v-label class="custom-label" link @click="showLogin">Login</v-label>
      <v-label class="custom-label" link @click="showPW">Passwort zurücksetzen</v-label>
    </v-form>
  </v-container>

  <v-container v-if="SPWF">

    <v-form ref="pwForm" @submit.prevent>
      <v-text-field label="E-Mail" prepend-icon="mdi-email" type="email" v-model="email"
        :rules="[rules.required, rules.email]"></v-text-field>
      <div class="flex-d">
        <v-btn type="forgotpw" class="custom-button" @click="PasswordSetBack">Passwort zurücksetzen</v-btn>
        <v-label class="custom-label" link @click="showLogin">Login</v-label>
        <v-label class="custom-label" link @click="showReg">Neu Registrieren</v-label>
      </div>

    </v-form>
  </v-container>

</template>

<script>
import {
  getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword,
  updateProfile, sendEmailVerification, sendPasswordResetEmail
} from 'firebase/auth'

const auth = getAuth()

export default {
  data() {
    return {
      SLogin: true,
      SReg: false,
      SPWF: false,
      name: '',
      email: '',
      password: '',

      rules: {
        required: value => !!value || 'Dieses Feld ist erforderlich.',
        email: value => /.+@.+\..+/.test(value) || 'E-Mail muss gültig sein.',
        minLength: value => value.length >= 6 || 'Muss mindestens 6 Zeichen lang sein.',
        minLength2: value => value.length >= 2 || 'Muss mindestens 2 Zeichen lang sein.'
      }
    }
  },

  methods: {
    Login() {
      signInWithEmailAndPassword(auth, this.email, this.password)
        .then((userCredential) => {
          const user = userCredential.user;
          this.$store.commit('callSnackbar', "Hallo " + user.displayName + " du bist eingeloggt.")

          this.$store.dispatch('startUp')

          if (user.emailVerified) {
            setTimeout(() => {
              this.$router.push({ name: 'MeineCharaktereView' })
            }, 500)
          }
        })
        .catch((err) => {
          console.log(err.message)
          this.$store.commit('callSnackbar', err.message)
        })
    },

    Register() {
      createUserWithEmailAndPassword(auth, this.email, this.password)
        .then((userCredential) => {
          const user = userCredential.user;

          updateProfile(user, {
            displayName: this.name
          })
            .then(() => {
              this.$store.dispatch('startUp')
              this.$store.commit('callSnackbar', "Hallo " + user.displayName + " die Registrierung war erfolgreich.")
              this.$store.commit('setState', { key: 'LoginText', value: 'Registriert, E-Mail muss noch bestätigt werden.' })
              setTimeout(() => { this.$store.commit('setState', { key: 'showLogin', value: false }) }, 750)
            })
            .catch((err) => { console.log(err.message) })
          sendEmailVerification(user)
            .then(() => {
              this.$store.dispatch('startUp')
            })
            .catch((err) => { console.log(err.message) })

        })
        .catch((err) => { console.log(err.message) })
    },

    PasswordSetBack() {
      sendPasswordResetEmail(auth, this.email)
        .then(() => {
          this.$store.commit('callSnackbar', "E-Mail zum zurücksetzen des Passworts wurde verschickt.")
        })
    },

    showLogin() {
      this.SLogin = true
      this.SReg = false
      this.SPWF = false
    },

    showReg() {
      this.SLogin = false
      this.SReg = true
      this.SPWF = false
    },

    showPW() {
      this.SLogin = false
      this.SReg = false
      this.SPWF = true
    }

  }
}
</script>

<style>
.custom-button1 {
  margin: 25px;
  background: darkgreen;
}

.custom-label {
  font-size: 0.8em;
  font-style: italic;
  margin: 25px;
}
</style>