<template>
  <h1 class="custom-h1" block>
    <v-container>
      <v-row class="centered-content">
        <v-col cols="11">
          Einstellungen
        </v-col>
        <v-col cols="1" v-if="this.$store.state.User">
          <v-tooltip text="Speichern" location="bottom">
            <template v-slot:activator="{ props }">
              <v-img :src="require('@/assets/OIcon/SpeichernIcon.png')" alt="Speichern" height="45" class="icon-img"
                v-bind="props" @click="saveEinstellungen" />
            </template>
          </v-tooltip>
        </v-col>
      </v-row>
    </v-container>

  </h1>
  <!-- Discord -->
  <h2 class="custom-h2" block>Discord Nutzung</h2>
  <v-container>
    <v-form>
      <v-row class="centered-content">
        <v-col cols="11">
          <v-switch v-model="useDiscordWebhook" :color="useDiscordWebhook ? 'darkgreen' : ''"
            label="Discord Webhook nutzen"></v-switch>
        </v-col>
        <v-col cols="1">
          <v-btn icon class="custom-button" style="margin: 0; padding: 0; width: 30px; height: 30px;"
            @click="openInfoDialog">
            <v-icon>mdi-information-outline</v-icon>
          </v-btn>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="9">
          <v-text-field v-model="discordWebhookURL" label="Discord Webhook URL" :disabled="!useDiscordWebhook" />
        </v-col>
        <v-col cols="3">
          <v-btn class="custom-button" :disabled="!this.$store.state.User || !useDiscordWebhook || !discordWebhookURL"
            @click="sendToDisc">Testmeldung schicken</v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>

  <v-dialog v-model="infoDialog" max-width="600px">
    <v-card>
      <v-card-title>Discord Webhooks</v-card-title>
      <v-card-text>
        <pre>
  Der Discord Webhook kann mit den folgenden Schritten
  vom Admin erstellt werden. Wird er hier eingetragen
  werden Würfelergebnisse automatisch an Discord übermittelt.

  -> Einstellungen des Textkanals öffnen
  -> Integrationen
  -> Webhook benennen und erstellen
      </pre>
      </v-card-text>
      <v-card-actions>
        <v-btn class="custom-button" text @click="infoDialog = false">Schließen</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- Session -->
  <h2 class="custom-h2" block>Historie</h2>
  <v-container>
    <v-row>
      <v-col>
        <v-text-field v-model="sessionId" label="Sitzungs-ID" />
      </v-col>
    </v-row>
  </v-container>

  <!-- Pop Up Roll -->
  <h2 class="custom-h2" block>Popup für Würfel</h2>
  <v-container>
    <v-row>
      <v-col>
        <v-checkbox label="Popup für eigene Würfe" v-model="popupW"></v-checkbox>
        <v-text-field type="number" v-model="popupDuration" label="Popup Dauer in ms (1000 = 1 sec)" />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getFirestore, collection, query, where, getDocs, doc, updateDoc } from 'firebase/firestore';
import { sendMessageToDisc } from '../composables/useDiscord.js'

export default {
  data() {
    return {
      useDiscordWebhook: false,
      discordWebhookURL: '',
      infoDialog: false,
      sessionId: '',
      popupW: false,
      popupDuration: 2000
    };
  },

  setup() {
    return {
      sendMessageToDisc
    };
  },

  mounted() {
    if (this.$store.state.User) {
      this.loadEinstellungen()
    } else {
      this.$router.push({ name: 'home' })
    }
  },

  methods: {
    openInfoDialog() {
      this.infoDialog = true;
    },

    sendToDisc() {
      sendMessageToDisc('Hi, das ist eine Testmeldung von www.rpg-troll.de - dem Würfeltool für Onlinerollenspiel. Die Discord-Schnittstelle scheint zu funktionieren.')
    },

    async loadEinstellungen() {
      const db = getFirestore()
      const ColRefEinst = collection(db, 'Einstellungen')
      const q = query(ColRefEinst, where("User", "==", this.$store.state.User.uid))
      const querySnapshot = await getDocs(q)
      if (!querySnapshot.empty) {
        const docData = querySnapshot.docs[0].data()
        this.discordWebhookURL = docData.discordWebhookURL
        this.useDiscordWebhook = docData.useDiscordWebhook
        this.popupDuration = docData.popupDuration
        this.popupW = docData.popupW
        this.sessionId = docData.sessionId
      } else {
        console.log("Keine passenden Einstellungsdokumente gefunden.")
      }
    },

    async saveEinstellungen() {
      const db = getFirestore()
      const userUid = this.$store.state.User.uid;
      const settingsDocRef = doc(db, "Einstellungen", userUid);
      if (!this.sessionId) { this.sessionId = userUid }
      await updateDoc(settingsDocRef, {
        User: userUid,
        discordWebhookURL: this.discordWebhookURL,
        useDiscordWebhook: this.useDiscordWebhook,
        popupDuration: this.popupDuration,
        popupW: this.popupW,
        sessionId: this.sessionId
      })

      await this.$store.dispatch('startUp')
      this.$store.commit('callSnackbar', "Einstellungen gespeichert.")
    }

  }
};

</script>

<style>
.icon-img {
  opacity: 0.5;
  transition: opacity 0.5s ease;
}

.icon-img:hover {
  opacity: 1.0;
  cursor: pointer;
}
</style>
