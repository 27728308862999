<template>
    <NavBars />
    <CharaktereTab />
</template>

<script>
import { defineComponent } from 'vue';

// Components
import NavBars from '@/components/NavBars.vue';
import CharaktereTab from '@/components/CharaktereTab.vue';

export default defineComponent({
  name: 'mChars',

  components: {
    NavBars, CharaktereTab
  },
});
</script>