<template>
  <div class="overlay"></div>
  <v-snackbar v-if="this.$store.state.showSnackbar" v-model="this.$store.state.showSnackbar" color="success"
    variant="outlined" :timeout="3000">
    {{ this.$store.state.snackbarText }}
  </v-snackbar>

  <v-snackbar v-if="this.$store.state.showDicer" v-model="this.$store.state.showDicer" color="rgba(20,75,20,0.8)"
    elevation="24" width="100%" min-width="800px"
    style="position: fixed; top: 0%; left: 50%; transform: translate(-50%, -50%);"
    :timeout="this.$store.state.Einstellungen && this.$store.state.Einstellungen.popupDuration !== undefined ? this.$store.state.Einstellungen.popupDuration : 2000">
    <div>
      <v-container @click="this.$store.state.showDicer = false">
        <v-row align="center">
          <v-col cols="3">
            <v-img style="max-width: 111px;" :src="require('@/assets/Bilder/rpgTrolls.png')" v-once />
          </v-col>
          <v-col cols="6">
            <v-row align="center">
              <v-col cols="12">
                <p style="color: rgb(245, 255, 245); text-align: center; font-size: 1.2em; white-space: pre-line;">{{
    this.$store.state.DicerText }}</p>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12">
                <p style="color: rgb(245, 255, 245); text-align: center; font-size: 1.8em; font-weight: bold;">{{
    this.$store.state.DicerTotal }}</p>
              </v-col>
            </v-row>
          </v-col>
          <v-col cols="3">
            <v-img style="max-width: 111px;" :src="require('@/assets/Bilder/rpgTrolls.png')" v-once />
          </v-col>
        </v-row>
      </v-container>
    </div>
  </v-snackbar>

  <v-app>
    <v-main>
      <router-view />
    </v-main>
  </v-app>


  <div class="custom-footer" @click="this.$router.push({ name: 'impressum' })">
    © 2024 TNDS | Version {{ this.$store.state.Version }}
  </div>
</template>

<script>

export default {
  name: 'App',

  created() {
    this.$store.dispatch('startUp')
  },
}
</script>


<style>
#app {
  color: darkgreen;

}

body {
  margin: 0;
}

.custom-button {
  background-color: rgba(0, 75, 0, 0.5);
  color: white;
  margin: 10px;
}

.custom-h1 {
  width: 100%;
  letter-spacing: 5px;
  font-size: 45px;
  text-align: center;
  margin-bottom: 22px;
  margin-top: 15px;
  border-bottom: 1px solid darkgreen;
}

.custom-h2 {
  width: 80%;
  letter-spacing: 5px;
  font-size: 26px;
  text-align: center;
  margin: 15px auto 25px;
  border-bottom: 1px solid darkgreen;
}

.custom-h3 {
  width: 55%;
  letter-spacing: 5px;
  font-size: 22px;
  text-align: center;
  margin: 15px auto 25px;
  border-bottom: 1px solid darkgreen;
}

.custom-h4 {
  letter-spacing: 3px;
  font-size: 19px;
  text-align: left;
}

.custom-footer {
  position: fixed;
  cursor: pointer;
  right: 30px;
  bottom: 0;
  background-color: transparent;
  text-align: right;
  padding: 12px;
}

.overlay {
  position: fixed;
  /* Oder 'absolute', falls 'fixed' nicht erwünscht ist */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 15, 0, 0.1);
  /* Semi-transparent weiß */
  pointer-events: none;
  /* Erlaubt Klicks durch das Overlay */
  z-index: 2000;
}

* {
  cursor: url('@/assets/Pointer/tpointer.png'), auto;
}

.custom-sitebuttons {
  position: fixed;
  left: 75px;
  top: 135px;
  background-color: transparent;
  text-align: right;
  z-index: 799;
}
</style>
