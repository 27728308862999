<template>
    <NavBars />
    <LoginTab />
</template>

<script>
import { defineComponent } from 'vue';

// Components
import NavBars from '@/components/NavBars.vue';
import LoginTab from '@/components/LoginTab.vue';


export default defineComponent({
  name: 'wTool',

  components: {
    NavBars, LoginTab
  },
});
</script>