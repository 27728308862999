export function dsaProben() {
    const Proben = [
        { id: 'abrid0001', name: 'Abrichten', w1: 'Mut', w2: 'Intuition', w3: 'Charisma', wP: 'MU/IN/CH', talentwert: null },
        { id: 'ackid0002', name: 'Ackerbau', w1: 'Intuition', w2: 'Fingerfertigkeit', w3: 'Konstitution', wP: 'IN/FF/KO', talentwert: null },
        { id: 'akrid0003', name: 'Akrobatik', w1: 'Mut', w2: 'Gewandtheit', w3: 'Körperkraft', wP: 'MU/GE/KK', talentwert: null },
        { id: 'alcid0004', name: 'Alchimie', w1: 'Mut', w2: 'Klugheit', w3: 'Fingerfertigkeit', wP: 'MU/KL/FF', talentwert: null },
        { id: 'anaid0005', name: 'Anatomie', w1: 'Mut', w2: 'Klugheit', w3: 'Fingerfertigkeit', wP: 'MU/KL/FF', talentwert: null },
        { id: 'athid0006', name: 'Athletik', w1: 'Gewandtheit', w2: 'Konstitution', w3: 'Körperkraft', wP: 'GE/KO/KK', talentwert: 0 },
        { id: 'bauid0007', name: 'Baukunst', w1: 'Klugheit', w2: 'Klugheit', w3: 'Fingerfertigkeit', wP: 'KL/KL/FF', talentwert: null },
        { id: 'berid0008', name: 'Bergbau', w1: 'Intuition', w2: 'Konstitution', w3: 'Körperkraft', wP: 'IN/KO/KK', talentwert: null },
        { id: 'betid0009', name: 'Betören', w1: 'Intuition', w2: 'Charisma', w3: 'Charisma', wP: 'IN/CH/CH', talentwert: null },
        { id: 'bogid0010', name: 'Bogenbau', w1: 'Klugheit', w2: 'Intuition', w3: 'Fingerfertigkeit', wP: 'KL/IN/FF', talentwert: null },
        { id: 'booid0011', name: 'Boote Fahren', w1: 'Gewandtheit', w2: 'Konstitution', w3: 'Körperkraft', wP: 'GE/KO/KK', talentwert: null },
        { id: 'braid0012', name: 'Brauer', w1: 'Klugheit', w2: 'Fingerfertigkeit', w3: 'Körperkraft', wP: 'KL/FF/KK', talentwert: null },
        { id: 'breid0013', name: 'Brett-/Kartenspiel', w1: 'Klugheit', w2: 'Klugheit', w3: 'Intuition', wP: 'KL/KL/IN', talentwert: null },
        { id: 'druid0014', name: 'Drucker', w1: 'Klugheit', w2: 'Fingerfertigkeit', w3: 'Körperkraft', wP: 'KL/FF/KK', talentwert: null },
        { id: 'eisid0015', name: 'Eissegler Fahren', w1: 'Intuition', w2: 'Gewandtheit', w3: 'Körperkraft', wP: 'IN/GE/KK', talentwert: null },
        { id: 'etiid0016', name: 'Etikette', w1: 'Klugheit', w2: 'Intuition', w3: 'Charisma', wP: 'KL/IN/CH', talentwert: null },
        { id: 'fähid0017', name: 'Fährtensuchen', w1: 'Klugheit', w2: 'Intuition', w3: 'Konstitution', wP: 'KL/IN/KO', talentwert: 0 },
        { id: 'fahid0018', name: 'Fahrzeug Lenken', w1: 'Intuition', w2: 'Charisma', w3: 'Fingerfertigkeit', wP: 'IN/CH/FF', talentwert: null },
        { id: 'falid0019', name: 'Fallenstellen', w1: 'Klugheit', w2: 'Fingerfertigkeit', w3: 'Körperkraft', wP: 'KL/FF/KK', talentwert: null },
        { id: 'falid0020', name: 'Falschspiel', w1: 'Mut', w2: 'Charisma', w3: 'Fingerfertigkeit', wP: 'MU/CH/FF', talentwert: null },
        { id: 'feiid0021', name: 'Feinmechanik', w1: 'Klugheit', w2: 'Fingerfertigkeit', w3: 'Fingerfertigkeit', wP: 'KL/FF/FF', talentwert: null },
        { id: 'fesid0022', name: 'Fesseln/Entfesseln', w1: 'Fingerfertigkeit', w2: 'Gewandtheit', w3: 'Körperkraft', wP: 'FF/GE/KK', talentwert: null },
        { id: 'feuid0023', name: 'Feuersteinbearbeitung', w1: 'Klugheit', w2: 'Fingerfertigkeit', w3: 'Fingerfertigkeit', wP: 'KL/FF/FF', talentwert: null },
        { id: 'fisid0024', name: 'Fischen/Angeln', w1: 'Intuition', w2: 'Fingerfertigkeit', w3: 'Körperkraft', wP: 'IN/FF/KK', talentwert: null },
        { id: 'fleid0025', name: 'Fleischer', w1: 'Klugheit', w2: 'Fingerfertigkeit', w3: 'Körperkraft', wP: 'KL/FF/KK', talentwert: null },
        { id: 'fliid0026', name: 'Fliegen', w1: 'Mut', w2: 'Intuition', w3: 'Gewandtheit', wP: 'MU/IN/GE', talentwert: null },
        { id: 'galid0027', name: 'Galanterie', w1: 'Intuition', w2: 'Charisma', w3: 'Fingerfertigkeit', wP: 'IN/CH/FF', talentwert: null },
        { id: 'gasid0028', name: 'Gassenwissen', w1: 'Klugheit', w2: 'Intuition', w3: 'Charisma', wP: 'KL/IN/CH', talentwert: null },
        { id: 'gauid0029', name: 'Gaukeleien', w1: 'Mut', w2: 'Charisma', w3: 'Fingerfertigkeit', wP: 'MU/CH/FF', talentwert: null },
        { id: 'geoid0030', name: 'Geographie', w1: 'Klugheit', w2: 'Klugheit', w3: 'Intuition', wP: 'KL/KL/IN', talentwert: null },
        { id: 'gerid0031', name: 'Gerber/Kürschner', w1: 'Klugheit', w2: 'Fingerfertigkeit', w3: 'Konstitution', wP: 'KL/FF/KO', talentwert: null },
        { id: 'gesid0032', name: 'Geschichtswissen', w1: 'Klugheit', w2: 'Klugheit', w3: 'Intuition', wP: 'KL/KL/IN', talentwert: null },
        { id: 'gesid0033', name: 'Gesteinskunde', w1: 'Klugheit', w2: 'Intuition', w3: 'Fingerfertigkeit', wP: 'KL/IN/FF', talentwert: null },
        { id: 'glaid0034', name: 'Glaskunst', w1: 'Fingerfertigkeit', w2: 'Fingerfertigkeit', w3: 'Konstitution', wP: 'FF/FF/KO', talentwert: null },
        { id: 'götid0035', name: 'Götter/Kulte', w1: 'Klugheit', w2: 'Klugheit', w3: 'Intuition', wP: 'KL/KL/IN', talentwert: 0 },
        { id: 'groid0036', name: 'Grobschmied', w1: 'Fingerfertigkeit', w2: 'Körperkraft', w3: 'Konstitution', wP: 'FF/KK/KO', talentwert: null },
        { id: 'hanid0037', name: 'Handel', w1: 'Klugheit', w2: 'Intuition', w3: 'Charisma', wP: 'KL/IN/CH', talentwert: null },
        { id: 'hauid0038', name: 'Hauswirtschaft', w1: 'Intuition', w2: 'Charisma', w3: 'Fingerfertigkeit', wP: 'IN/CH/FF', talentwert: null },
        { id: 'heiid0039', name: 'Heilkunde Gift', w1: 'Mut', w2: 'Klugheit', w3: 'Intuition', wP: 'MU/KL/IN', talentwert: null },
        { id: 'heiid0040', name: 'Heilkunde Krankheiten', w1: 'Mut', w2: 'Klugheit', w3: 'Charisma', wP: 'MU/KL/CH', talentwert: null },
        { id: 'heiid0041', name: 'Heilkunde Seele', w1: 'Intuition', w2: 'Charisma', w3: 'Charisma', wP: 'IN/CH/CH', talentwert: null },
        { id: 'heiid0042', name: 'Heilkunde Wunden', w1: 'Klugheit', w2: 'Charisma', w3: 'Fingerfertigkeit', wP: 'KL/CH/FF', talentwert: 0 },
        { id: 'herid0043', name: 'Heraldik', w1: 'Klugheit', w2: 'Klugheit', w3: 'Fingerfertigkeit', wP: 'KL/KL/FF', talentwert: null },
        { id: 'holid0044', name: 'Holzbearbeitung', w1: 'Klugheit', w2: 'Fingerfertigkeit', w3: 'Körperkraft', wP: 'KL/FF/KK', talentwert: 0 },
        { id: 'hunid0045', name: 'Hundeschlitten Fahren', w1: 'Charisma', w2: 'Fingerfertigkeit', w3: 'Gewandtheit', wP: 'CH/FF/GE', talentwert: null },
        { id: 'hütid0046', name: 'Hüttenkunde', w1: 'Klugheit', w2: 'Intuition', w3: 'Konstitution', wP: 'KL/IN/KO', talentwert: null },
        { id: 'insid0047', name: 'Instrumentenbauer', w1: 'Klugheit', w2: 'Intuition', w3: 'Fingerfertigkeit', wP: 'KL/IN/FF', talentwert: null },
        { id: 'kapid0048', name: 'Kapellmeister', w1: 'Klugheit', w2: 'Intuition', w3: 'Charisma', wP: 'KL/IN/CH', talentwert: null },
        { id: 'karid0049', name: 'Kartographie', w1: 'Klugheit', w2: 'Klugheit', w3: 'Fingerfertigkeit', wP: 'KL/KL/FF', talentwert: null },
        { id: 'kleid0050', name: 'Klettern', w1: 'Mut', w2: 'Gewandtheit', w3: 'Körperkraft', wP: 'MU/GE/KK', talentwert: 0 },
        { id: 'kocid0051', name: 'Kochen', w1: 'Klugheit', w2: 'Intuition', w3: 'Fingerfertigkeit', wP: 'KL/IN/FF', talentwert: 0 },
        { id: 'körid0052', name: 'Körperbeherrschung', w1: 'Mut', w2: 'Intuition', w3: 'Gewandtheit', wP: 'MU/IN/GE', talentwert: 0 },
        { id: 'kriid0053', name: 'Kriegskunst', w1: 'Mut', w2: 'Klugheit', w3: 'Charisma', wP: 'MU/KL/CH', talentwert: null },
        { id: 'kriid0054', name: 'Kristallzüchter', w1: 'Klugheit', w2: 'Intuition', w3: 'Fingerfertigkeit', wP: 'KL/IN/FF', talentwert: null },
        { id: 'kryid0055', name: 'Kryptographie', w1: 'Klugheit', w2: 'Klugheit', w3: 'Intuition', wP: 'KL/KL/IN', talentwert: null },
        { id: 'ledid0056', name: 'Lederarbeiten', w1: 'Klugheit', w2: 'Fingerfertigkeit', w3: 'Fingerfertigkeit', wP: 'KL/FF/FF', talentwert: 0 },
        { id: 'lehid0057', name: 'Lehren', w1: 'Klugheit', w2: 'Intuition', w3: 'Charisma', wP: 'KL/IN/CH', talentwert: null },
        { id: 'magid0058', name: 'Magiekunde', w1: 'Klugheit', w2: 'Klugheit', w3: 'Intuition', wP: 'KL/KL/IN', talentwert: null },
        { id: 'malid0059', name: 'Malen/Zeichnen', w1: 'Klugheit', w2: 'Intuition', w3: 'Fingerfertigkeit', wP: 'KL/IN/FF', talentwert: 0 },
        { id: 'mauid0060', name: 'Maurer', w1: 'Fingerfertigkeit', w2: 'Gewandtheit', w3: 'Körperkraft', wP: 'FF/GE/KK', talentwert: null },
        { id: 'mecid0061', name: 'Mechanik', w1: 'Klugheit', w2: 'Klugheit', w3: 'Fingerfertigkeit', wP: 'KL/KL/FF', talentwert: null },
        { id: 'menid0062', name: 'Menschenkenntnis', w1: 'Klugheit', w2: 'Intuition', w3: 'Charisma', wP: 'KL/IN/CH', talentwert: 0 },
        { id: 'metid0063', name: 'Metallguss', w1: 'Klugheit', w2: 'Fingerfertigkeit', w3: 'Körperkraft', wP: 'KL/FF/KK', talentwert: null },
        { id: 'musid0064', name: 'Musizieren', w1: 'Intuition', w2: 'Charisma', w3: 'Fingerfertigkeit', wP: 'IN/CH/FF', talentwert: null },
        { id: 'oriid0065', name: 'Orientierung', w1: 'Klugheit', w2: 'Intuition', w3: 'Intuition', wP: 'KL/IN/IN', talentwert: 0 },
        { id: 'pflid0066', name: 'Pflanzenkunde', w1: 'Klugheit', w2: 'Intuition', w3: 'Fingerfertigkeit', wP: 'KL/IN/FF', talentwert: null },
        { id: 'phiid0067', name: 'Philosophie', w1: 'Klugheit', w2: 'Klugheit', w3: 'Intuition', wP: 'KL/KL/IN', talentwert: null },
        { id: 'recid0068', name: 'Rechnen', w1: 'Klugheit', w2: 'Klugheit', w3: 'Intuition', wP: 'KL/KL/IN', talentwert: 0 },
        { id: 'recid0069', name: 'Rechtskunde', w1: 'Klugheit', w2: 'Klugheit', w3: 'Intuition', wP: 'KL/KL/IN', talentwert: null },
        { id: 'reiid0070', name: 'Reiten', w1: 'Charisma', w2: 'Gewandtheit', w3: 'Körperkraft', wP: 'CH/GE/KK', talentwert: null },
        { id: 'sagid0071', name: 'Sagen/Legenden', w1: 'Klugheit', w2: 'Intuition', w3: 'Charisma', wP: 'KL/IN/CH', talentwert: 0 },
        { id: 'schid0072', name: 'Schätzen', w1: 'Klugheit', w2: 'Intuition', w3: 'Intuition', wP: 'KL/IN/IN', talentwert: null },
        { id: 'schid0073', name: 'Schauspielerei', w1: 'Mut', w2: 'Klugheit', w3: 'Charisma', wP: 'MU/KL/CH', talentwert: null },
        { id: 'schid0074', name: 'Schiffbau', w1: 'Klugheit', w2: 'Klugheit', w3: 'Fingerfertigkeit', wP: 'KL/KL/FF', talentwert: null },
        { id: 'schid0075', name: 'Schleichen', w1: 'Mut', w2: 'Intuition', w3: 'Gewandtheit', wP: 'MU/IN/GE', talentwert: 0 },
        { id: 'schid0076', name: 'Schlösser Knacken', w1: 'Intuition', w2: 'Fingerfertigkeit', w3: 'Fingerfertigkeit', wP: 'IN/FF/FF', talentwert: null },
        { id: 'schid0077', name: 'Schnaps Brennen', w1: 'Klugheit', w2: 'Intuition', w3: 'Fingerfertigkeit', wP: 'KL/IN/FF', talentwert: null },
        { id: 'schid0078', name: 'Schneidern', w1: 'Klugheit', w2: 'Fingerfertigkeit', w3: 'Fingerfertigkeit', wP: 'KL/FF/FF', talentwert: 0 },
        { id: 'schid0079', name: 'Schriftl. Ausdruck', w1: 'Klugheit', w2: 'Intuition', w3: 'Intuition', wP: 'KL/IN/IN', talentwert: null },
        { id: 'schid0080', name: 'Schwimmen', w1: 'Gewandtheit', w2: 'Konstitution', w3: 'Körperkraft', wP: 'GE/KO/KK', talentwert: 0 },
        { id: 'seeid0081', name: 'Seefahrt', w1: 'Fingerfertigkeit', w2: 'Gewandtheit', w3: 'Körperkraft', wP: 'FF/GE/KK', talentwert: null },
        { id: 'seeid0082', name: 'Seefischerei', w1: 'Intuition', w2: 'Fingerfertigkeit', w3: 'Körperkraft', wP: 'IN/FF/KK', talentwert: null },
        { id: 'seiid0083', name: 'Seiler', w1: 'Fingerfertigkeit', w2: 'Fingerfertigkeit', w3: 'Körperkraft', wP: 'FF/FF/KK', talentwert: null },
        { id: 'selid0084', name: 'Selbstbeherrschung', w1: 'Mut', w2: 'Konstitution', w3: 'Körperkraft', wP: 'MU/KO/KK', talentwert: 0 },
        { id: 'sicid0085', name: 'Sich Verkleiden', w1: 'Mut', w2: 'Charisma', w3: 'Gewandtheit', wP: 'MU/CH/GE', talentwert: null },
        { id: 'sicid0086', name: 'Sich Verstecken', w1: 'Mut', w2: 'Intuition', w3: 'Gewandtheit', wP: 'MU/IN/GE', talentwert: 0 },
        { id: 'sinid0087', name: 'Singen', w1: 'Intuition', w2: 'Charisma', w3: 'Charisma', wP: 'IN/CH/CH', talentwert: 0 },
        { id: 'sinid0088', name: 'Sinnenschärfe 1', w1: 'Klugheit', w2: 'Intuition', w3: 'Intuition', wP: 'KL/IN/IN', talentwert: 0 },
        { id: 'sinid0089', name: 'Sinnenschärfe 2', w1: 'Klugheit', w2: 'Intuition', w3: 'Fingerfertigkeit', wP: 'KL/IN/FF', talentwert: 0 },
        { id: 'skiid0090', name: 'Skifahren', w1: 'Gewandtheit', w2: 'Gewandtheit', w3: 'Konstitution', wP: 'GE/GE/KO', talentwert: null },
        { id: 'sprid0091', name: 'Sprachenkunde', w1: 'Klugheit', w2: 'Klugheit', w3: 'Intuition', wP: 'KL/KL/IN', talentwert: null },
        { id: 'staid0092', name: 'Staatskunst', w1: 'Klugheit', w2: 'Intuition', w3: 'Charisma', wP: 'KL/IN/CH', talentwert: null },
        { id: 'steid0093', name: 'Steinmetz', w1: 'Fingerfertigkeit', w2: 'Fingerfertigkeit', w3: 'Körperkraft', wP: 'FF/FF/KK', talentwert: null },
        { id: 'steid0094', name: 'Steinschneider/Juwelier', w1: 'Intuition', w2: 'Fingerfertigkeit', w3: 'Fingerfertigkeit', wP: 'IN/FF/FF', talentwert: null },
        { id: 'steid0095', name: 'Stellmacher', w1: 'Klugheit', w2: 'Fingerfertigkeit', w3: 'Körperkraft', wP: 'KL/FF/KK', talentwert: null },
        { id: 'steid0096', name: 'Sternkunde', w1: 'Klugheit', w2: 'Klugheit', w3: 'Intuition', wP: 'KL/KL/IN', talentwert: null },
        { id: 'steid0097', name: 'Steuermann', w1: 'Intuition', w2: 'Charisma', w3: 'Körperkraft', wP: 'IN/CH/KK', talentwert: null },
        { id: 'stiid0098', name: 'Stimmen imitieren', w1: 'Klugheit', w2: 'Intuition', w3: 'Charisma', wP: 'KL/IN/CH', talentwert: null },
        { id: 'stoid0099', name: 'Stoffe Färben', w1: 'Klugheit', w2: 'Fingerfertigkeit', w3: 'Körperkraft', wP: 'KL/FF/KK', talentwert: null },
        { id: 'tanid0100', name: 'Tanzen', w1: 'Charisma', w2: 'Gewandtheit', w3: 'Gewandtheit', wP: 'CH/GE/GE', talentwert: 0 },
        { id: 'tasid0101', name: 'Taschendiebstahl', w1: 'Mut', w2: 'Intuition', w3: 'Fingerfertigkeit', wP: 'MU/IN/FF', talentwert: null },
        { id: 'tätid0102', name: 'Tätowieren', w1: 'Intuition', w2: 'Fingerfertigkeit', w3: 'Fingerfertigkeit', wP: 'IN/FF/FF', talentwert: null },
        { id: 'tieid0103', name: 'Tierkunde', w1: 'Mut', w2: 'Klugheit', w3: 'Intuition', wP: 'MU/KL/IN', talentwert: null },
        { id: 'töpid0104', name: 'Töpfern', w1: 'Klugheit', w2: 'Fingerfertigkeit', w3: 'Fingerfertigkeit', wP: 'KL/FF/FF', talentwert: null },
        { id: 'übeid0105', name: 'Überreden', w1: 'Mut', w2: 'Intuition', w3: 'Charisma', wP: 'MU/IN/CH', talentwert: null },
        { id: 'übeid0106', name: 'Überzeugen', w1: 'Klugheit', w2: 'Intuition', w3: 'Charisma', wP: 'KL/IN/CH', talentwert: 0 },
        { id: 'vieid0107', name: 'Viehzucht', w1: 'Klugheit', w2: 'Intuition', w3: 'Körperkraft', wP: 'KL/IN/KK', talentwert: null },
        { id: 'webid0108', name: 'Webkunst', w1: 'Fingerfertigkeit', w2: 'Fingerfertigkeit', w3: 'Körperkraft', wP: 'FF/FF/KK', talentwert: null },
        { id: 'wetid0109', name: 'Wettervorhersage', w1: 'Klugheit', w2: 'Intuition', w3: 'Intuition', wP: 'KL/IN/IN', talentwert: null },
        { id: 'wilid0110', name: 'Wildnisleben', w1: 'Intuition', w2: 'Gewandtheit', w3: 'Konstitution', wP: 'IN/GE/KO', talentwert: 0 },
        { id: 'winid0111', name: 'Winzer', w1: 'Klugheit', w2: 'Fingerfertigkeit', w3: 'Körperkraft', wP: 'KL/FF/KK', talentwert: null },
        { id: 'zecid0112', name: 'Zechen', w1: 'Intuition', w2: 'Konstitution', w3: 'Körperkraft', wP: 'IN/KO/KK', talentwert: 0 },
        { id: 'zimid0113', name: 'Zimmermann', w1: 'Klugheit', w2: 'Fingerfertigkeit', w3: 'Körperkraft', wP: 'KL/FF/KK', talentwert: null },
        { id: 'abvid0114', name: 'Abvenenum', w1: 'Klugheit', w2: 'Klugheit', w3: 'Fingerfertigkeit', wP: 'KL/KL/FF +Mod', talentwert: null },
        { id: 'accid0115', name: 'Accuratum', w1: 'Klugheit', w2: 'Charisma', w3: 'Fingerfertigkeit', wP: 'KL/CH/FF +Mod', talentwert: null },
        { id: 'adaid0116', name: 'Adamantium', w1: 'Klugheit', w2: 'Fingerfertigkeit', w3: 'Konstitution', wP: 'KL/FF/KO', talentwert: null },
        { id: 'adlid0117', name: 'Adlerauge', w1: 'Klugheit', w2: 'Intuition', w3: 'Fingerfertigkeit', wP: 'KL/IN/FF', talentwert: null },
        { id: 'adlid0118', name: 'Adlerschwinge', w1: 'Mut', w2: 'Intuition', w3: 'Gewandtheit', wP: 'MU/IN/GE +Mod', talentwert: null },
        { id: 'aeoid0119', name: 'Aeolitus', w1: 'Klugheit', w2: 'Charisma', w3: 'Konstitution', wP: 'KL/CH/KO', talentwert: null },
        { id: 'aerid0120', name: 'Aerofugo', w1: 'Mut', w2: 'Konstitution', w3: 'Körperkraft', wP: 'MU/KO/KK', talentwert: null },
        { id: 'aerid0121', name: 'Aerogele', w1: 'Mut', w2: 'Intuition', w3: 'Konstitution', wP: 'MU/IN/KO', talentwert: null },
        { id: 'alpid0122', name: 'Alpgestalt', w1: 'Mut', w2: 'Charisma', w3: 'Gewandtheit', wP: 'MU/CH/GE +MR', talentwert: null },
        { id: 'anaid0123', name: 'Analys', w1: 'Klugheit', w2: 'Klugheit', w3: 'Intuition', wP: 'KL/KL/IN +Mod', talentwert: null },
        { id: 'ängid0124', name: 'Ängste lindern', w1: 'Mut', w2: 'Intuition', w3: 'Charisma', wP: 'MU/IN/CH', talentwert: null },
        { id: 'aniid0125', name: 'Animatio', w1: 'Klugheit', w2: 'Fingerfertigkeit', w3: 'Gewandtheit', wP: 'KL/FF/GE', talentwert: null },
        { id: 'appid0126', name: 'Applicatus', w1: 'Klugheit', w2: 'Fingerfertigkeit', w3: 'Fingerfertigkeit', wP: 'KL/FF/FF', talentwert: null },
        { id: 'araid0127', name: 'Arachnea', w1: 'Mut', w2: 'Intuition', w3: 'Charisma', wP: 'MU/IN/CH', talentwert: null },
        { id: 'arcid0128', name: 'Arcanovi', w1: 'Klugheit', w2: 'Klugheit', w3: 'Fingerfertigkeit', wP: 'KL/KL/FF', talentwert: null },
        { id: 'armid0129', name: 'Armatrutz', w1: 'Intuition', w2: 'Gewandtheit', w3: 'Konstitution', wP: 'IN/GE/KO', talentwert: null },
        { id: 'ateid0130', name: 'Atemnot', w1: 'Mut', w2: 'Konstitution', w3: 'Körperkraft', wP: 'MU/KO/KK +MR', talentwert: null },
        { id: 'attid0131', name: 'Attributo', w1: 'Klugheit', w2: 'Charisma', w3: '', wP: 'KL/CH/Eigenschaft', talentwert: null },
        { id: 'aufid0132', name: 'Aufgeblasen', w1: 'Charisma', w2: 'Körperkraft', w3: 'Konstitution', wP: 'CH/KK/KO +MR', talentwert: null },
        { id: 'augid0133', name: 'Auge des Limbus', w1: 'Mut', w2: 'Konstitution', w3: 'Körperkraft', wP: 'MU/KO/KK', talentwert: null },
        { id: 'aurid0134', name: 'Aureolus', w1: 'Intuition', w2: 'Charisma', w3: 'Fingerfertigkeit', wP: 'IN/CH/FF', talentwert: null },
        { id: 'aurid0135', name: 'Auris Nasus', w1: 'Klugheit', w2: 'Charisma', w3: 'Fingerfertigkeit', wP: 'KL/CH/FF', talentwert: null },
        { id: 'axxid0136', name: 'Axxeleratus', w1: 'Klugheit', w2: 'Gewandtheit', w3: 'Konstitution', wP: 'KL/GE/KO', talentwert: null },
        { id: 'balid0137', name: 'Balsam', w1: 'Klugheit', w2: 'Intuition', w3: 'Charisma', wP: 'KL/IN/CH +Mod', talentwert: null },
        { id: 'banid0138', name: 'Band und Fessel', w1: 'Klugheit', w2: 'Charisma', w3: 'Körperkraft', wP: 'KL/CH/KK +MR', talentwert: null },
        { id: 'banid0139', name: 'Bannbaladin', w1: 'Intuition', w2: 'Charisma', w3: 'Charisma', wP: 'IN/CH/CH +MR', talentwert: null },
        { id: 'bärid0140', name: 'Bärenruhe', w1: 'Mut', w2: 'Körperkraft', w3: 'Konstitution', wP: 'MU/KK/KO +Mod', talentwert: null },
        { id: 'behid0141', name: 'Beherrschung brechen', w1: 'Klugheit', w2: 'Intuition', w3: 'Charisma', wP: 'KL/IN/CH +Mod', talentwert: null },
        { id: 'besid0142', name: 'Beschwörung vereiteln', w1: 'Mut', w2: 'Intuition', w3: 'Charisma', wP: 'MU/IN/CH +Mod', talentwert: null },
        { id: 'bewid0143', name: 'Bewegung stören', w1: 'Klugheit', w2: 'Intuition', w3: 'Fingerfertigkeit', wP: 'KL/IN/FF +Mod', talentwert: null },
        { id: 'bleid0144', name: 'Blendwerk', w1: 'Intuition', w2: 'Charisma', w3: 'Gewandtheit', wP: 'IN/CH/GE', talentwert: null },
        { id: 'bliid0145', name: 'Blick aufs Wesen', w1: 'Klugheit', w2: 'Intuition', w3: 'Charisma', wP: 'KL/IN/CH +MR', talentwert: null },
        { id: 'bliid0146', name: 'Blick durch fremde Augen', w1: 'Mut', w2: 'Intuition', w3: 'Charisma', wP: 'MU/IN/CH +MR', talentwert: null },
        { id: 'bliid0147', name: 'Blick in die Gedanken', w1: 'Klugheit', w2: 'Klugheit', w3: 'Charisma', wP: 'KL/KL/CH +MR', talentwert: null },
        { id: 'bliid0148', name: 'Blick in die Vergangenheit', w1: 'Klugheit', w2: 'Klugheit', w3: 'Intuition', wP: 'KL/KL/IN', talentwert: null },
        { id: 'bliid0149', name: 'Blitz dich find', w1: 'Klugheit', w2: 'Intuition', w3: 'Gewandtheit', wP: 'KL/IN/GE +MR', talentwert: null },
        { id: 'bösid0150', name: 'Böser Blick', w1: 'Mut', w2: 'Charisma', w3: 'Charisma', wP: 'MU/CH/CH +MR', talentwert: null },
        { id: 'breid0151', name: 'Brenne toter Stoff!', w1: 'Mut', w2: 'Klugheit', w3: 'Konstitution', wP: 'MU/KL/KO', talentwert: null },
        { id: 'calid0152', name: 'Caldofrigo', w1: 'Intuition', w2: 'Charisma', w3: 'Konstitution', wP: 'IN/CH/KO', talentwert: null },
        { id: 'chaid0153', name: 'Chamaelioni', w1: 'Intuition', w2: 'Charisma', w3: 'Gewandtheit', wP: 'IN/CH/GE', talentwert: null },
        { id: 'chiid0154', name: 'Chimaeroform', w1: 'Klugheit', w2: 'Intuition', w3: 'Konstitution', wP: 'KL/IN/KO +Mod', talentwert: null },
        { id: 'chrid0155', name: 'Chronoklassis', w1: 'Klugheit', w2: 'Intuition', w3: 'Konstitution', wP: 'KL/IN/KO +Mod', talentwert: null },
        { id: 'chrid0156', name: 'Chrononautos', w1: 'Mut', w2: 'Charisma', w3: 'Konstitution', wP: 'MU/CH/KO +Mod', talentwert: null },
        { id: 'claid0157', name: 'Claudibus', w1: 'Klugheit', w2: 'Fingerfertigkeit', w3: 'Körperkraft', wP: 'KL/FF/KK', talentwert: null },
        { id: 'corid0158', name: 'Corpofesso', w1: 'Klugheit', w2: 'Körperkraft', w3: 'Körperkraft', wP: 'KL/KK/KK +MR', talentwert: null },
        { id: 'corid0159', name: 'Corpofrigo', w1: 'Charisma', w2: 'Gewandtheit', w3: 'Konstitution', wP: 'CH/GE/KO +MR', talentwert: null },
        { id: 'cryid0160', name: 'Cryptographo', w1: 'Klugheit', w2: 'Klugheit', w3: 'Intuition', wP: 'KL/KL/IN', talentwert: null },
        { id: 'cusid0161', name: 'Custodosigil', w1: 'Klugheit', w2: 'Fingerfertigkeit', w3: 'Fingerfertigkeit', wP: 'KL/FF/FF', talentwert: null },
        { id: 'dämid0162', name: 'Dämonenbann', w1: 'Mut', w2: 'Charisma', w3: 'Konstitution', wP: 'MU/CH/KO +Mod', talentwert: null },
        { id: 'delid0163', name: 'Delicioso', w1: 'Klugheit', w2: 'Charisma', w3: 'Fingerfertigkeit', wP: 'KL/CH/FF', talentwert: null },
        { id: 'desid0164', name: 'Desintegratus', w1: 'Klugheit', w2: 'Körperkraft', w3: 'Konstitution', wP: 'KL/KK/KO', talentwert: null },
        { id: 'desid0165', name: 'Destructibo', w1: 'Klugheit', w2: 'Klugheit', w3: 'Fingerfertigkeit', wP: 'KL/KL/FF +Mod', talentwert: null },
        { id: 'dicid0166', name: 'Dichter', w1: 'Klugheit', w2: 'Intuition', w3: 'Charisma', wP: 'KL/IN/CH +MR', talentwert: null },
        { id: 'dscid0167', name: 'Dschinnenruf', w1: 'Mut', w2: 'Klugheit', w3: 'Charisma', wP: 'MU/KL/CH +Mod', talentwert: null },
        { id: 'dunid0168', name: 'Dunkelheit', w1: 'Klugheit', w2: 'Klugheit', w3: 'Fingerfertigkeit', wP: 'KL/KL/FF', talentwert: null },
        { id: 'dupid0169', name: 'Duplicatus', w1: 'Klugheit', w2: 'Charisma', w3: 'Gewandtheit', wP: 'KL/CH/GE', talentwert: null },
        { id: 'eclid0170', name: 'Ecliptifactus', w1: 'Mut', w2: 'Klugheit', w3: 'Konstitution', wP: 'MU/KL/KO', talentwert: null },
        { id: 'eigid0171', name: 'Eigensch. Wiederherstellen', w1: 'Klugheit', w2: 'Intuition', w3: 'Charisma', wP: 'KL/IN/CH +Mod', talentwert: null },
        { id: 'eigid0172', name: 'Eigne Ängste', w1: 'Mut', w2: 'Intuition', w3: 'Charisma', wP: 'MU/IN/CH +MR', talentwert: null },
        { id: 'einid0173', name: 'Einfluss bannen', w1: 'Intuition', w2: 'Charisma', w3: 'Charisma', wP: 'IN/CH/CH +Mod', talentwert: null },
        { id: 'einid0174', name: 'Eins mit der Natur', w1: 'Intuition', w2: 'Gewandtheit', w3: 'Konstitution', wP: 'IN/GE/KO', talentwert: null },
        { id: 'eisid0175', name: 'Eisenrost', w1: 'Klugheit', w2: 'Charisma', w3: 'Gewandtheit', wP: 'KL/CH/GE', talentwert: null },
        { id: 'eisid0176', name: 'Eiseskälte', w1: 'Mut', w2: 'Intuition', w3: 'Konstitution', wP: 'MU/IN/KO', talentwert: null },
        { id: 'eleid0177', name: 'Elementarbann', w1: 'Intuition', w2: 'Charisma', w3: 'Konstitution', wP: 'IN/CH/KO +Mod', talentwert: null },
        { id: 'eleid0178', name: 'Elementarer Diener', w1: 'Mut', w2: 'Klugheit', w3: 'Charisma', wP: 'MU/KL/CH +Mod', talentwert: null },
        { id: 'elfid0179', name: 'Elfenstimme', w1: 'Intuition', w2: 'Charisma', w3: 'Konstitution', wP: 'IN/CH/KO', talentwert: null },
        { id: 'eriid0180', name: 'Erinnerung verlasse dich!', w1: 'Mut', w2: 'Intuition', w3: 'Charisma', wP: 'MU/IN/CH +MR', talentwert: null },
        { id: 'expid0181', name: 'Exposami', w1: 'Klugheit', w2: 'Intuition', w3: 'Intuition', wP: 'KL/IN/IN', talentwert: null },
        { id: 'falid0182', name: 'Falkenauge', w1: 'Intuition', w2: 'Fingerfertigkeit', w3: 'Gewandtheit', wP: 'IN/FF/GE', talentwert: null },
        { id: 'favid0183', name: 'Favilludo', w1: 'Intuition', w2: 'Charisma', w3: 'Fingerfertigkeit', wP: 'IN/CH/FF', talentwert: null },
        { id: 'firid0184', name: 'Firnlauf', w1: 'Mut', w2: 'Klugheit', w3: 'Gewandtheit', wP: 'MU/KL/GE', talentwert: null },
        { id: 'fliid0185', name: 'Flim Flam', w1: 'Klugheit', w2: 'Klugheit', w3: 'Fingerfertigkeit', wP: 'KL/KL/FF', talentwert: null },
        { id: 'fluid0186', name: 'Fluch der Pestilenz', w1: 'Mut', w2: 'Klugheit', w3: 'Charisma', wP: 'MU/KL/CH', talentwert: null },
        { id: 'forid0187', name: 'Foramen', w1: 'Klugheit', w2: 'Klugheit', w3: 'Fingerfertigkeit', wP: 'KL/KL/FF', talentwert: null },
        { id: 'forid0188', name: 'Fortifex', w1: 'Intuition', w2: 'Körperkraft', w3: 'Konstitution', wP: 'IN/KK/KO', talentwert: null },
        { id: 'fulid0189', name: 'Fulminictus', w1: 'Intuition', w2: 'Gewandtheit', w3: 'Konstitution', wP: 'IN/GE/KO', talentwert: null },
        { id: 'garid0190', name: 'Gardianum', w1: 'Klugheit', w2: 'Intuition', w3: 'Konstitution', wP: 'KL/IN/KO', talentwert: null },
        { id: 'gedid0191', name: 'Gedankenbilder', w1: 'Klugheit', w2: 'Intuition', w3: 'Charisma', wP: 'KL/IN/CH', talentwert: null },
        { id: 'gefid0192', name: 'Gefäß der Jahre', w1: 'Mut', w2: 'Klugheit', w3: 'Konstitution', wP: 'MU/KL/KO', talentwert: null },
        { id: 'gefid0193', name: 'Gefunden!', w1: 'Klugheit', w2: 'Intuition', w3: 'Gewandtheit', wP: 'KL/IN/GE +Mod', talentwert: null },
        { id: 'geiid0194', name: 'Geisterbann', w1: 'Mut', w2: 'Mut', w3: 'Charisma', wP: 'MU/MU/CH +Mod', talentwert: null },
        { id: 'geiid0195', name: 'Geisterruf', w1: 'Mut', w2: 'Mut', w3: 'Charisma', wP: 'MU/MU/CH +Mod', talentwert: null },
        { id: 'graid0196', name: 'Granit und Marmor', w1: 'Mut', w2: 'Charisma', w3: 'Konstitution', wP: 'MU/CH/KO +MR', talentwert: null },
        { id: 'groid0197', name: 'Große Gier', w1: 'Klugheit', w2: 'Klugheit', w3: 'Charisma', wP: 'KL/KL/CH +MR', talentwert: null },
        { id: 'groid0198', name: 'Große Verwirrung', w1: 'Klugheit', w2: 'Klugheit', w3: 'Charisma', wP: 'KL/KL/CH +MR', talentwert: null },
        { id: 'halid0199', name: 'Halluzination', w1: 'Klugheit', w2: 'Intuition', w3: 'Charisma', wP: 'KL/IN/CH +MR', talentwert: null },
        { id: 'harid0200', name: 'Harmlose Gestalt', w1: 'Klugheit', w2: 'Charisma', w3: 'Gewandtheit', wP: 'KL/CH/GE', talentwert: null },
        { id: 'harid0201', name: 'Hartes schmelze!', w1: 'Mut', w2: 'Klugheit', w3: 'Körperkraft', wP: 'MU/KL/KK', talentwert: null },
        { id: 'hasid0202', name: 'Haselbusch', w1: 'Charisma', w2: 'Fingerfertigkeit', w3: 'Konstitution', wP: 'CH/FF/KO', talentwert: null },
        { id: 'heiid0203', name: 'Heilkraft bannen', w1: 'Klugheit', w2: 'Charisma', w3: 'Fingerfertigkeit', wP: 'KL/CH/FF +Mod', talentwert: null },
        { id: 'helid0204', name: 'Hellsicht trüben', w1: 'Klugheit', w2: 'Intuition', w3: 'Charisma', wP: 'KL/IN/CH +MR', talentwert: null },
        { id: 'herid0205', name: 'Herbeirufung vereiteln', w1: 'Mut', w2: 'Intuition', w3: 'Charisma', wP: 'MU/IN/CH', talentwert: null },
        { id: 'herid0206', name: 'Herr über das Tierreich', w1: 'Mut', w2: 'Mut', w3: 'Charisma', wP: 'MU/MU/CH +MR', talentwert: null },
        { id: 'hezid0207', name: 'Hezschlag', w1: 'Mut', w2: 'Charisma', w3: 'Körperkraft', wP: 'MU/CH/KK +MR', talentwert: null },
        { id: 'hexid0208', name: 'Hexenblick', w1: 'Intuition', w2: 'Intuition', w3: 'Charisma', wP: 'IN/IN/CH', talentwert: null },
        { id: 'hexid0209', name: 'Hexengalle', w1: 'Mut', w2: 'Intuition', w3: 'Charisma', wP: 'MU/IN/CH', talentwert: null },
        { id: 'hexid0210', name: 'Hexenholz', w1: 'Klugheit', w2: 'Fingerfertigkeit', w3: 'Körperkraft', wP: 'KL/FF/KK', talentwert: null },
        { id: 'hexid0211', name: 'Hexenknoten', w1: 'Klugheit', w2: 'Intuition', w3: 'Charisma', wP: 'KL/IN/CH', talentwert: null },
        { id: 'hexid0212', name: 'Hexenkrallen', w1: 'Mut', w2: 'Intuition', w3: 'Konstitution', wP: 'MU/IN/KO', talentwert: null },
        { id: 'hexid0213', name: 'Hexenspeichel', w1: 'Intuition', w2: 'Charisma', w3: 'Fingerfertigkeit', wP: 'IN/CH/FF', talentwert: null },
        { id: 'hilid0214', name: 'Hilfreiche Tatze', w1: 'Mut', w2: 'Intuition', w3: 'Charisma', wP: 'MU/IN/CH +MR', talentwert: null },
        { id: 'hölid0215', name: 'Höllenpein', w1: 'Klugheit', w2: 'Charisma', w3: 'Konstitution', wP: 'KL/CH/KO +MR', talentwert: null },
        { id: 'holid0216', name: 'Holterdipolter', w1: 'Intuition', w2: 'Intuition', w3: 'Fingerfertigkeit', wP: 'IN/IN/FF', talentwert: null },
        { id: 'horid0217', name: 'Horriphobus', w1: 'Intuition', w2: 'Intuition', w3: 'Fingerfertigkeit', wP: 'IN/IN/FF', talentwert: null },
        { id: 'ignid0218', name: 'Ignifaxius', w1: 'Klugheit', w2: 'Fingerfertigkeit', w3: 'Konstitution', wP: 'KL/FF/KO', talentwert: null },
        { id: 'ignid0219', name: 'Ignisphaero', w1: 'Klugheit', w2: 'Fingerfertigkeit', w3: 'Konstitution', wP: 'KL/FF/KO', talentwert: null },
        { id: 'ignid0220', name: 'Ignorantia', w1: 'Intuition', w2: 'Charisma', w3: 'Gewandtheit', wP: 'IN/CH/GE', talentwert: null },
        { id: 'illid0221', name: 'Illusion auflösen', w1: 'Klugheit', w2: 'Intuition', w3: 'Charisma', wP: 'KL/IN/CH', talentwert: null },
        { id: 'immid0222', name: 'Immortalis', w1: 'Mut', w2: 'Charisma', w3: 'Konstitution', wP: 'MU/CH/KO', talentwert: null },
        { id: 'impid0223', name: 'Imperavi', w1: 'Klugheit', w2: 'Charisma', w3: 'Charisma', wP: 'KL/CH/CH +MR', talentwert: null },
        { id: 'impid0224', name: 'Impersona', w1: 'Klugheit', w2: 'Intuition', w3: 'Fingerfertigkeit', wP: 'KL/IN/FF', talentwert: null },
        { id: 'infid0225', name: 'Infinitum', w1: 'Klugheit', w2: 'Charisma', w3: 'Konstitution', wP: 'KL/CH/KO +Mod', talentwert: null },
        { id: 'invid0226', name: 'Invercano', w1: 'Mut', w2: 'Intuition', w3: 'Fingerfertigkeit', wP: 'MU/IN/FF', talentwert: null },
        { id: 'invid0227', name: 'Invocatio maior', w1: 'Mut', w2: 'Mut', w3: 'Charisma', wP: 'MU/MU/CH +Mod', talentwert: null },
        { id: 'invid0228', name: 'Invocatio minor', w1: 'Mut', w2: 'Mut', w3: 'Charisma', wP: 'MU/MU/CH +Mod', talentwert: null },
        { id: 'iriid0229', name: 'Iribaars Hand', w1: 'Mut', w2: 'Mut', w3: 'Intuition', wP: 'MU/MU/IN +MR', talentwert: null },
        { id: 'jucid0230', name: 'Juckreiz dämlicher', w1: 'Mut', w2: 'Intuition', w3: 'Charisma', wP: 'MU/IN/CH +MR', talentwert: null },
        { id: 'karid0231', name: 'Karnifilo', w1: 'Mut', w2: 'Intuition', w3: 'Charisma', wP: 'MU/IN/CH +MR', talentwert: null },
        { id: 'katid0232', name: 'Katzenaugen', w1: 'Klugheit', w2: 'Fingerfertigkeit', w3: 'Konstitution', wP: 'KL/FF/KO', talentwert: null },
        { id: 'klaid0233', name: 'Klamm Purum', w1: 'Klugheit', w2: 'Klugheit', w3: 'Charisma', wP: 'KL/KL/CH +Mod', talentwert: null },
        { id: 'kliid0234', name: 'Klickeradomms', w1: 'Klugheit', w2: 'Fingerfertigkeit', w3: 'Körperkraft', wP: 'KL/FF/KK', talentwert: null },
        { id: 'kobid0235', name: 'Koboldgeschenk', w1: 'Intuition', w2: 'Charisma', w3: 'Fingerfertigkeit', wP: 'IN/CH/FF +MR', talentwert: null },
        { id: 'kobid0236', name: 'Koboldovision', w1: 'Mut', w2: 'Charisma', w3: 'Charisma', wP: 'MU/CH/CH', talentwert: null },
        { id: 'komid0237', name: 'Komm Kobold', w1: 'Intuition', w2: 'Intuition', w3: 'Charisma', wP: 'IN/IN/CH', talentwert: null },
        { id: 'körid0238', name: 'Körperlose Reise', w1: 'Mut', w2: 'Klugheit', w3: 'Intuition', wP: 'MU/KL/IN', talentwert: null },
        { id: 'kraid0239', name: 'Krabbelnder Schrecken', w1: 'Mut', w2: 'Mut', w3: 'Charisma', wP: 'MU/MU/CH', talentwert: null },
        { id: 'kraid0240', name: 'Kraft des Erzes', w1: 'Klugheit', w2: 'Körperkraft', w3: 'Konstitution', wP: 'KL/KK/KO', talentwert: null },
        { id: 'kräid0241', name: 'Krähenruf', w1: 'Mut', w2: 'Charisma', w3: 'Charisma', wP: 'MU/CH/CH', talentwert: null },
        { id: 'kröid0242', name: 'Krötensprung', w1: 'Intuition', w2: 'Gewandtheit', w3: 'Körperkraft', wP: 'IN/GE/KK', talentwert: null },
        { id: 'kulid0243', name: 'Kulminatio', w1: 'Mut', w2: 'Intuition', w3: 'Fingerfertigkeit', wP: 'MU/IN/FF', talentwert: null },
        { id: 'kusid0244', name: 'Kusch!', w1: 'Mut', w2: 'Intuition', w3: 'Charisma', wP: 'MU/IN/CH +MR', talentwert: null },
        { id: 'lacid0245', name: 'Lach dich gesund', w1: 'Intuition', w2: 'Charisma', w3: 'Charisma', wP: 'IN/CH/CH', talentwert: null },
        { id: 'lacid0246', name: 'Lachkrampf', w1: 'Charisma', w2: 'Charisma', w3: 'Fingerfertigkeit', wP: 'CH/CH/FF +MR', talentwert: null },
        { id: 'lanid0247', name: 'Langer Lulatsch', w1: 'Charisma', w2: 'Gewandtheit', w3: 'Körperkraft', wP: 'CH/GE/KK +MR', talentwert: null },
        { id: 'lasid0248', name: 'Last des Alters', w1: 'Intuition', w2: 'Charisma', w3: 'Konstitution', wP: 'IN/CH/KO +MR', talentwert: null },
        { id: 'leiid0249', name: 'Leib der Erde', w1: 'Mut', w2: 'Intuition', w3: 'Gewandtheit', wP: 'MU/IN/GE', talentwert: null },
        { id: 'leiid0250', name: 'Leib der Wogen', w1: 'Mut', w2: 'Klugheit', w3: 'Gewandtheit', wP: 'MU/KL/GE', talentwert: null },
        { id: 'leiid0251', name: 'Leib des Eises', w1: 'Mut', w2: 'Klugheit', w3: 'Gewandtheit', wP: 'MU/KL/GE', talentwert: null },
        { id: 'leiid0252', name: 'Leib des Erzes', w1: 'Mut', w2: 'Gewandtheit', w3: 'Körperkraft', wP: 'MU/GE/KK', talentwert: null },
        { id: 'leiid0253', name: 'Leib des Feuers', w1: 'Mut', w2: 'Mut', w3: 'Gewandtheit', wP: 'MU/MU/GE', talentwert: null },
        { id: 'leiid0254', name: 'Leib des Windes', w1: 'Mut', w2: 'Gewandtheit', w3: 'Körperkraft', wP: 'MU/GE/KK', talentwert: null },
        { id: 'leiid0255', name: 'Leidensbund', w1: 'Mut', w2: 'Intuition', w3: 'Konstitution', wP: 'MU/IN/KO', talentwert: null },
        { id: 'levid0256', name: 'Levthans Feuer', w1: 'Intuition', w2: 'Charisma', w3: 'Charisma', wP: 'IN/CH/CH +MR', talentwert: null },
        { id: 'limid0257', name: 'Limbus versiegeln', w1: 'Klugheit', w2: 'Intuition', w3: 'Konstitution', wP: 'KL/IN/KO +Mod', talentwert: null },
        { id: 'locid0258', name: 'Lockruf', w1: 'Intuition', w2: 'Charisma', w3: 'Fingerfertigkeit', wP: 'IN/CH/FF', talentwert: null },
        { id: 'lunid0259', name: 'Lunge des Leviatan', w1: 'Intuition', w2: 'Charisma', w3: 'Konstitution', wP: 'IN/CH/KO', talentwert: null },
        { id: 'madid0260', name: 'Madas Spiegel', w1: 'Mut', w2: 'Klugheit', w3: 'Intuition', wP: 'MU/KL/IN', talentwert: null },
        { id: 'magid0261', name: 'Magischer Raub', w1: 'Mut', w2: 'Klugheit', w3: 'Konstitution', wP: 'MU/KL/KO +MR', talentwert: null },
        { id: 'mahid0262', name: 'Mahlstrom', w1: 'Mut', w2: 'Intuition', w3: 'Körperkraft', wP: 'MU/IN/KK', talentwert: null },
        { id: 'manid0263', name: 'Manifesto', w1: 'Klugheit', w2: 'Intuition', w3: 'Charisma', wP: 'KL/IN/CH +Mod', talentwert: null },
        { id: 'meiid0264', name: 'Meister der Elemente', w1: 'Mut', w2: 'Klugheit', w3: 'Charisma', wP: 'MU/KL/CH +Mod', talentwert: null },
        { id: 'meiid0265', name: 'Meister minderer Geister', w1: 'Mut', w2: 'Charisma', w3: 'Charisma', wP: 'MU/CH/CH', talentwert: null },
        { id: 'memid0266', name: 'Memorabia', w1: 'Klugheit', w2: 'Intuition', w3: 'Charisma', wP: 'KL/IN/CH +Mod', talentwert: null },
        { id: 'memid0267', name: 'Memorans', w1: 'Klugheit', w2: 'Klugheit', w3: 'Intuition', wP: 'KL/KL/IN', talentwert: null },
        { id: 'menid0268', name: 'Menetekel', w1: 'Klugheit', w2: 'Charisma', w3: 'Fingerfertigkeit', wP: 'KL/CH/FF', talentwert: null },
        { id: 'metid0269', name: 'Metamagie neutralisieren', w1: 'Klugheit', w2: 'Klugheit', w3: 'Konstitution', wP: 'KL/KL/KO +Mod', talentwert: null },
        { id: 'metid0270', name: 'Metamorpho', w1: 'Klugheit', w2: 'Fingerfertigkeit', w3: 'Körperkraft', wP: 'KL/FF/KK', talentwert: null },
        { id: 'motid0271', name: 'Motoricus', w1: 'Klugheit', w2: 'Fingerfertigkeit', w3: 'Körperkraft', wP: 'KL/FF/KK +Mod', talentwert: null },
        { id: 'movid0272', name: 'Movimento', w1: 'Intuition', w2: 'Gewandtheit', w3: 'Konstitution', wP: 'IN/GE/KO', talentwert: null },
        { id: 'murid0273', name: 'Murks und Patz', w1: 'Intuition', w2: 'Intuition', w3: 'Fingerfertigkeit', wP: 'IN/IN/FF +Mod', talentwert: null },
        { id: 'nacid0274', name: 'Nackedei', w1: 'Klugheit', w2: 'Intuition', w3: 'Fingerfertigkeit', wP: 'KL/IN/FF +Mod', talentwert: null },
        { id: 'nebid0275', name: 'Nebelleib', w1: 'Mut', w2: 'Intuition', w3: 'Konstitution', wP: 'MU/IN/KO', talentwert: null },
        { id: 'nebid0276', name: 'Nebelwand', w1: 'Klugheit', w2: 'Fingerfertigkeit', w3: 'Konstitution', wP: 'KL/FF/KO', talentwert: null },
        { id: 'nekid0277', name: 'Nekropathia', w1: 'Mut', w2: 'Klugheit', w3: 'Charisma', wP: 'MU/KL/CH', talentwert: null },
        { id: 'nihid0278', name: 'Nihilogravo', w1: 'Klugheit', w2: 'Konstitution', w3: 'Körperkraft', wP: 'KL/KO/KK', talentwert: null },
        { id: 'nunid0279', name: 'Nuntiovolo', w1: 'Mut', w2: 'Klugheit', w3: 'Charisma', wP: 'MU/KL/CH', talentwert: null },
        { id: 'objid0280', name: 'Objecto Obscuro', w1: 'Klugheit', w2: 'Fingerfertigkeit', w3: 'Konstitution', wP: 'KL/FF/KO', talentwert: null },
        { id: 'objid0281', name: 'Objectofixo', w1: 'Klugheit', w2: 'Klugheit', w3: 'Körperkraft', wP: 'KL/KL/KK', talentwert: null },
        { id: 'objid0282', name: 'Objectovoco', w1: 'Klugheit', w2: 'Intuition', w3: 'Charisma', wP: 'KL/IN/CH', talentwert: null },
        { id: 'objid0283', name: 'Objekt entzaubern', w1: 'Klugheit', w2: 'Intuition', w3: 'Fingerfertigkeit', wP: 'KL/IN/FF', talentwert: null },
        { id: 'ocuid0284', name: 'Oculus Astralis', w1: 'Klugheit', w2: 'Intuition', w3: 'Charisma', wP: 'KL/IN/CH', talentwert: null },
        { id: 'odeid0285', name: 'Odem', w1: 'Klugheit', w2: 'Intuition', w3: 'Intuition', wP: 'KL/IN/IN', talentwert: null },
        { id: 'panid0286', name: 'Pandaemonium', w1: 'Mut', w2: 'Mut', w3: 'Charisma', wP: 'MU/MU/CH', talentwert: null },
        { id: 'panid0287', name: 'Panik', w1: 'Mut', w2: 'Charisma', w3: 'Charisma', wP: 'MU/CH/CH', talentwert: null },
        { id: 'papid0288', name: 'Papperlapapp', w1: 'Intuition', w2: 'Fingerfertigkeit', w3: 'Fingerfertigkeit', wP: 'IN/FF/FF +MR', talentwert: null },
        { id: 'parid0289', name: 'Paralysis', w1: 'Intuition', w2: 'Charisma', w3: 'Körperkraft', wP: 'IN/CH/KK +MR', talentwert: null },
        { id: 'pecid0290', name: 'Pectetondo', w1: 'Klugheit', w2: 'Charisma', w3: 'Fingerfertigkeit', wP: 'KL/CH/FF', talentwert: null },
        { id: 'penid0291', name: 'Penetrizzel', w1: 'Klugheit', w2: 'Klugheit', w3: 'Konstitution', wP: 'KL/KL/KO', talentwert: null },
        { id: 'penid0292', name: 'Pentagramma', w1: 'Mut', w2: 'Mut', w3: 'Charisma', wP: 'MU/MU/CH +Mod', talentwert: null },
        { id: 'pesid0293', name: 'Pestilenz erspüren', w1: 'Klugheit', w2: 'Intuition', w3: 'Charisma', wP: 'KL/IN/CH', talentwert: null },
        { id: 'pfeid0294', name: 'Pfeil des [Elememts]', w1: 'Klugheit', w2: 'Intuition', w3: 'Charisma', wP: 'KL/IN/CH', talentwert: null },
        { id: 'plaid0295', name: 'Planastrale', w1: 'Mut', w2: 'Mut', w3: 'Konstitution', wP: 'MU/MU/KO', talentwert: null },
        { id: 'pluid0296', name: 'Plumbumbarum', w1: 'Charisma', w2: 'Gewandtheit', w3: 'Körperkraft', wP: 'CH/GE/KK +MR', talentwert: null },
        { id: 'proid0297', name: 'Projektimago', w1: 'Mut', w2: 'Intuition', w3: 'Charisma', wP: 'MU/IN/CH +Mod', talentwert: null },
        { id: 'proid0298', name: 'Protectionis', w1: 'Klugheit', w2: 'Charisma', w3: 'Konstitution', wP: 'KL/CH/KO', talentwert: null },
        { id: 'psyid0299', name: 'Psychostabilis', w1: 'Mut', w2: 'Klugheit', w3: 'Konstitution', wP: 'MU/KL/KO', talentwert: null },
        { id: 'radid0300', name: 'Radau', w1: 'Mut', w2: 'Charisma', w3: 'Konstitution', wP: 'MU/CH/KO', talentwert: null },
        { id: 'refid0301', name: 'Reflectomago', w1: 'Klugheit', w2: 'Charisma', w3: 'Fingerfertigkeit', wP: 'KL/CH/FF', talentwert: null },
        { id: 'repid0302', name: 'Reptilea', w1: 'Mut', w2: 'Intuition', w3: 'Charisma', wP: 'MU/IN/CH', talentwert: null },
        { id: 'resid0303', name: 'Respondami', w1: 'Mut', w2: 'Intuition', w3: 'Charisma', wP: 'MU/IN/CH', talentwert: null },
        { id: 'revid0304', name: 'Reversalis', w1: 'Klugheit', w2: 'Intuition', w3: 'Charisma', wP: 'KL/IN/CH', talentwert: null },
        { id: 'ruhid0305', name: 'Ruhe Körper', w1: 'Klugheit', w2: 'Charisma', w3: 'Konstitution', wP: 'KL/CH/KO', talentwert: null },
        { id: 'salid0306', name: 'Salander', w1: 'Klugheit', w2: 'Charisma', w3: 'Konstitution', wP: 'KL/CH/KO +MR', talentwert: null },
        { id: 'sanid0307', name: 'Sanftmut', w1: 'Mut', w2: 'Charisma', w3: 'Charisma', wP: 'MU/CH/CH +MR', talentwert: null },
        { id: 'sapid0308', name: 'Sapefacta', w1: 'Klugheit', w2: 'Charisma', w3: 'Fingerfertigkeit', wP: 'KL/CH/FF', talentwert: null },
        { id: 'satid0309', name: 'Saturias Herrlichkeit', w1: 'Intuition', w2: 'Charisma', w3: 'Charisma', wP: 'IN/CH/CH', talentwert: null },
        { id: 'schid0310', name: 'Schabernack', w1: 'Klugheit', w2: 'Intuition', w3: 'Charisma', wP: 'KL/IN/CH +MR', talentwert: null },
        { id: 'schid0311', name: 'Schadenszauber bannen', w1: 'Mut', w2: 'Intuition', w3: 'Konstitution', wP: 'MU/IN/KO +Mod', talentwert: null },
        { id: 'schid0312', name: 'Schelmenkleister', w1: 'Intuition', w2: 'Gewandtheit', w3: 'Fingerfertigkeit', wP: 'IN/GE/FF', talentwert: null },
        { id: 'schid0313', name: 'Schelmenlaune', w1: 'Mut', w2: 'Intuition', w3: 'Charisma', wP: 'MU/IN/CH +Mod', talentwert: null },
        { id: 'schid0314', name: 'Schelmenmaske', w1: 'Intuition', w2: 'Charisma', w3: 'Gewandtheit', wP: 'IN/CH/GE', talentwert: null },
        { id: 'schid0315', name: 'Schelmenrausch', w1: 'Intuition', w2: 'Charisma', w3: 'Charisma', wP: 'IN/CH/CH +MR', talentwert: null },
        { id: 'schid0316', name: 'Schleier der Unwissenheit', w1: 'Klugheit', w2: 'Klugheit', w3: 'Fingerfertigkeit', wP: 'KL/KL/FF', talentwert: null },
        { id: 'schid0317', name: 'Schwarz und Rot', w1: 'Mut', w2: 'Charisma', w3: 'Konstitution', wP: 'MU/CH/KO +MR', talentwert: null },
        { id: 'schid0318', name: 'Schwarzer Schrecken', w1: 'Mut', w2: 'Intuition', w3: 'Charisma', wP: 'MU/IN/CH +MR', talentwert: null },
        { id: 'seeid0319', name: 'Seelentier erkennen', w1: 'Intuition', w2: 'Intuition', w3: 'Charisma', wP: 'IN/IN/CH +MR', talentwert: null },
        { id: 'seeid0320', name: 'Seelenwanderung', w1: 'Mut', w2: 'Charisma', w3: 'Konstitution', wP: 'MU/CH/KO', talentwert: null },
        { id: 'seiid0321', name: 'Seidenweich', w1: 'Intuition', w2: 'Fingerfertigkeit', w3: 'Fingerfertigkeit', wP: 'IN/FF/FF', talentwert: null },
        { id: 'seiid0322', name: 'Seidenzunge', w1: 'Klugheit', w2: 'Intuition', w3: 'Charisma', wP: 'KL/IN/CH +MR', talentwert: null },
        { id: 'senid0323', name: 'Sensattacco', w1: 'Mut', w2: 'Intuition', w3: 'Gewandtheit', wP: 'MU/IN/GE', talentwert: null },
        { id: 'senid0324', name: 'Sensibar', w1: 'Klugheit', w2: 'Intuition', w3: 'Charisma', wP: 'KL/IN/CH +MR', talentwert: null },
        { id: 'serid0325', name: 'Serpentialis', w1: 'Mut', w2: 'Charisma', w3: 'Gewandtheit', wP: 'MU/CH/GE', talentwert: null },
        { id: 'silid0326', name: 'Silentium', w1: 'Klugheit', w2: 'Intuition', w3: 'Charisma', wP: 'KL/IN/CH', talentwert: null },
        { id: 'sinid0327', name: 'Sinesigil', w1: 'Klugheit', w2: 'Intuition', w3: 'Fingerfertigkeit', wP: 'KL/IN/FF', talentwert: null },
        { id: 'skeid0328', name: 'Skelettarius', w1: 'Mut', w2: 'Mut', w3: 'Charisma', wP: 'MU/MU/CH +Mod', talentwert: null },
        { id: 'solid0329', name: 'Solidirid', w1: 'Intuition', w2: 'Konstitution', w3: 'Körperkraft', wP: 'IN/KO/KK', talentwert: null },
        { id: 'somid0330', name: 'Somnigravis', w1: 'Klugheit', w2: 'Charisma', w3: 'Charisma', wP: 'KL/CH/CH +MR', talentwert: null },
        { id: 'spiid0331', name: 'Spinnenlauf', w1: 'Intuition', w2: 'Gewandtheit', w3: 'Körperkraft', wP: 'IN/GE/KK', talentwert: null },
        { id: 'spuid0332', name: 'Spurlos', w1: 'Intuition', w2: 'Gewandtheit', w3: 'Gewandtheit', wP: 'IN/GE/GE', talentwert: null },
        { id: 'staid0333', name: 'Standfest', w1: 'Intuition', w2: 'Gewandtheit', w3: 'Fingerfertigkeit', wP: 'IN/GE/FF', talentwert: null },
        { id: 'staid0334', name: 'Staub wandle!', w1: 'Mut', w2: 'Klugheit', w3: 'Charisma', wP: 'MU/KL/CH +Mod', talentwert: null },
        { id: 'steid0335', name: 'Stein wandle!', w1: 'Mut', w2: 'Charisma', w3: 'Körperkraft', wP: 'MU/CH/KK +Mod', talentwert: null },
        { id: 'stiid0336', name: 'Stillstand', w1: 'Mut', w2: 'Intuition', w3: 'Gewandtheit', wP: 'MU/IN/GE', talentwert: null },
        { id: 'sumid0337', name: 'Sumus Elixiere', w1: 'Intuition', w2: 'Charisma', w3: 'Fingerfertigkeit', wP: 'IN/CH/FF', talentwert: null },
        { id: 'tauid0338', name: 'Tauschrausch', w1: 'Intuition', w2: 'Fingerfertigkeit', w3: 'Konstitution', wP: 'IN/FF/KO', talentwert: null },
        { id: 'temid0339', name: 'Tempus Stasis', w1: 'Mut', w2: 'Klugheit', w3: 'Körperkraft', wP: 'MU/KL/KK +Mod', talentwert: null },
        { id: 'tieid0340', name: 'Tiere besprechen', w1: 'Mut', w2: 'Intuition', w3: 'Charisma', wP: 'MU/IN/CH +Mod', talentwert: null },
        { id: 'tieid0341', name: 'Tiergedanken', w1: 'Mut', w2: 'Intuition', w3: 'Charisma', wP: 'MU/IN/CH +MR', talentwert: null },
        { id: 'tlaid0342', name: 'Tlalucs Odem', w1: 'Mut', w2: 'Intuition', w3: 'Gewandtheit', wP: 'MU/IN/GE', talentwert: null },
        { id: 'totid0343', name: 'Totes handIe!', w1: 'Mut', w2: 'Charisma', w3: 'Konstitution', wP: 'MU/CH/KO', talentwert: null },
        { id: 'traid0344', name: 'Transformatio', w1: 'Klugheit', w2: 'Fingerfertigkeit', w3: 'Körperkraft', wP: 'KL/FF/KK +Mod', talentwert: null },
        { id: 'traid0345', name: 'Transmutare', w1: 'Charisma', w2: 'Gewandtheit', w3: 'Konstitution', wP: 'CH/GE/KO +MR', talentwert: null },
        { id: 'traid0346', name: 'Transversalis', w1: 'Klugheit', w2: 'Intuition', w3: 'Konstitution', wP: 'KL/IN/KO', talentwert: null },
        { id: 'traid0347', name: 'Traumgestalt', w1: 'Intuition', w2: 'Charisma', w3: 'Charisma', wP: 'IN/CH/CH +MR', talentwert: null },
        { id: 'unbid0348', name: 'Unberührt von Satinav', w1: 'Klugheit', w2: 'Fingerfertigkeit', w3: 'Konstitution', wP: 'KL/FF/KO +Mod', talentwert: null },
        { id: 'uniid0349', name: 'Unitatio', w1: 'Intuition', w2: 'Charisma', w3: 'Konstitution', wP: 'IN/CH/KO', talentwert: null },
        { id: 'unsid0350', name: 'Unsichtbarer Jäger', w1: 'Intuition', w2: 'Fingerfertigkeit', w3: 'Gewandtheit', wP: 'IN/FF/GE', talentwert: null },
        { id: 'verid0351', name: 'Veränderung aufheben', w1: 'Klugheit', w2: 'Intuition', w3: 'Konstitution', wP: 'KL/IN/KO +Mod', talentwert: null },
        { id: 'verid0352', name: 'Verschwindibus', w1: 'Intuition', w2: 'Charisma', w3: 'Gewandtheit', wP: 'IN/CH/GE', talentwert: null },
        { id: 'verid0353', name: 'Verständigung stören', w1: 'Klugheit', w2: 'Klugheit', w3: 'Intuition', wP: 'KL/KL/IN +MR', talentwert: null },
        { id: 'verid0354', name: 'Verwandlung beenden', w1: 'Klugheit', w2: 'Charisma', w3: 'Fingerfertigkeit', wP: 'KL/CH/FF +Mod', talentwert: null },
        { id: 'vipid0355', name: 'Vipernblick', w1: 'Mut', w2: 'Mut', w3: 'Charisma', wP: 'MU/MU/CH +MR', talentwert: null },
        { id: 'visid0356', name: 'Visibili', w1: 'Klugheit', w2: 'Intuition', w3: 'Gewandtheit', wP: 'KL/IN/GE', talentwert: null },
        { id: 'vocid0357', name: 'Vocolimbo', w1: 'Klugheit', w2: 'Charisma', w3: 'Fingerfertigkeit', wP: 'KL/CH/FF', talentwert: null },
        { id: 'vogid0358', name: 'Vogelzwitschern', w1: 'Mut', w2: 'Intuition', w3: 'Gewandtheit', wP: 'MU/IN/GE', talentwert: null },
        { id: 'wanid0359', name: 'Wand aus Dornen', w1: 'Mut', w2: 'Klugheit', w3: 'Charisma', wP: 'MU/KL/CH', talentwert: null },
        { id: 'warid0360', name: 'Warmes Blut', w1: 'Mut', w2: 'Intuition', w3: 'Intuition', wP: 'MU/IN/IN', talentwert: null },
        { id: 'wasid0361', name: 'Wasseratem', w1: 'Mut', w2: 'Klugheit', w3: 'Konstitution', wP: 'MU/KL/KO', talentwert: null },
        { id: 'weiid0362', name: 'Weiches erstarre!', w1: 'Mut', w2: 'Klugheit', w3: 'Körperkraft', wP: 'MU/KL/KK', talentwert: null },
        { id: 'weiid0363', name: 'Weihrauchwolke', w1: 'Intuition', w2: 'Charisma', w3: 'Fingerfertigkeit', wP: 'IN/CH/FF', talentwert: null },
        { id: 'weiid0364', name: 'Weisheit', w1: 'Mut', w2: 'Intuition', w3: 'Konstitution', wP: 'MU/IN/KO +Mod', talentwert: null },
        { id: 'weiid0365', name: 'Weiße Man', w1: 'Klugheit', w2: 'Intuition', w3: 'Charisma', wP: 'KL/IN/CH', talentwert: null },
        { id: 'welid0366', name: 'Wellenlauf', w1: 'Mut', w2: 'Gewandtheit', w3: 'Gewandtheit', wP: 'MU/GE/GE', talentwert: null },
        { id: 'wetid0367', name: 'Wettermeisterschaft', w1: 'Mut', w2: 'Charisma', w3: 'Gewandtheit', wP: 'MU/CH/GE +Mod', talentwert: null },
        { id: 'widid0368', name: 'Widerwille', w1: 'Mut', w2: 'Intuition', w3: 'Gewandtheit', wP: 'MU/IN/GE', talentwert: null },
        { id: 'winid0369', name: 'Windhose', w1: 'Mut', w2: 'Intuition', w3: 'Körperkraft', wP: 'MU/IN/KK', talentwert: null },
        { id: 'winid0370', name: 'Windstille', w1: 'Klugheit', w2: 'Charisma', w3: 'Körperkraft', wP: 'KL/CH/KK +Mod', talentwert: null },
        { id: 'wipid0371', name: 'Wipfellauf', w1: 'Mut', w2: 'Intuition', w3: 'Gewandtheit', wP: 'MU/IN/GE', talentwert: null },
        { id: 'xenid0372', name: 'Xenographus', w1: 'Klugheit', w2: 'Klugheit', w3: 'Intuition', wP: 'KL/KL/IN', talentwert: null },
        { id: 'zagid0373', name: 'Zagibu', w1: 'Intuition', w2: 'Charisma', w3: 'Fingerfertigkeit', wP: 'IN/CH/FF', talentwert: null },
        { id: 'zapid0374', name: 'Zappenduster', w1: 'Intuition', w2: 'Intuition', w3: 'Fingerfertigkeit', wP: 'IN/IN/FF', talentwert: null },
        { id: 'zauid0375', name: 'Zauberklinge', w1: 'Klugheit', w2: 'Fingerfertigkeit', w3: 'Konstitution', wP: 'KL/FF/KO', talentwert: null },
        { id: 'zauid0376', name: 'Zaubernahrung', w1: 'Mut', w2: 'Mut', w3: 'Konstitution', wP: 'MU/MU/KO +Mod', talentwert: null },
        { id: 'zauid0377', name: 'Zauberwesen', w1: 'Mut', w2: 'Intuition', w3: 'Charisma', wP: 'MU/IN/CH', talentwert: null },
        { id: 'zauid0378', name: 'Zauberzwang', w1: 'Mut', w2: 'Charisma', w3: 'Charisma', wP: 'MU/CH/CH +MR', talentwert: null },
        { id: 'zorid0379', name: 'Zorn der Elemente', w1: 'Mut', w2: 'Charisma', w3: 'Konstitution', wP: 'MU/CH/KO', talentwert: null },
        { id: 'zunid0380', name: 'Zunge lähmen', w1: 'Mut', w2: 'Charisma', w3: 'Fingerfertigkeit', wP: 'MU/CH/FF +MR', talentwert: null },
        { id: 'zwiid0381', name: 'Zwingtanz', w1: 'Mut', w2: 'Klugheit', w3: 'Charisma', wP: 'MU/KL/CH +MR', talentwert: null },
        { id: 'nahid0382', name: 'Nahrung Sammeln', w1: 'Mut', w2: 'Intuition', w3: 'Fingerfertigkeit', wP: 'MU/IN/FF', talentwert: null },
        { id: 'kräid0383', name: 'Kräuter Sammeln', w1: 'Mut', w2: 'Intuition', w3: 'Fingerfertigkeit', wP: 'MU/IN/FF', talentwert: null },
        { id: 'pirid0384', name: 'Pirschjagd', w1: 'Mut', w2: 'Intuition', w3: 'Gewandtheit', wP: 'MU/IN/GE', talentwert: null },
        { id: 'ansid0385', name: 'Ansitzjagd', w1: 'Mut', w2: 'Intuition', w3: 'Gewandtheit', wP: 'MU/IN/GE', talentwert: null },
        { id: 'litid0386', name: 'Liturgiekenntnis', w1: 'Mut', w2: 'Intuition', w3: 'Charisma', wP: 'MU/IN/CH', talentwert: null },
    ]
    return Proben
}