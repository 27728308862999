import { createRouter, createWebHistory } from 'vue-router'
import WillkommenView from '../views/WillkommenView.vue'
import AboutView from '../views/AboutView.vue'
import AdminView from '../views/AdminView.vue'
import CharakterView from '../views/CharakterView.vue'
import CharDNDView from '../views/CharDNDView.vue'
import CharDSAView from '../views/CharDSAView.vue'
import CharSRView from '../views/CharSRView.vue'
import EinstellungenView from '../views/EinstellungenView.vue'
import MeineCharaktereView from '../views/MeineCharaktereView.vue'
import WürfeltoolView from '../views/WürfeltoolView.vue'
import LoginView from '../views/LoginView.vue'
import GamemasterView from '../views/GamemasterView.vue'
import KampagneView from '../views/KampagneView.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: WillkommenView
  },
  {
    path: '/about',
    name: 'AboutView',
    component: AboutView
  },
  {
    path: '/impressum',
    name: 'impressum',
    component: AboutView
  },
  {
    path: '/admin',
    name: 'AdminView',
    component: AdminView
  },
  {
    path: '/charakter',
    name: 'CharakterView',
    component: CharakterView
  },
  {
    path: '/dndcharakter',
    name: 'CharDNDView',
    component: CharDNDView
  },
  {
    path: '/dsacharakter',
    name: 'CharDSAView',
    component: CharDSAView
  },
  {
    path: '/srcharakter',
    name: 'CharSRView',
    component: CharSRView
  },
  {
    path: '/einstellungen',
    name: 'EinstellungenView',
    component: EinstellungenView
  },
  {
    path: '/charaktere',
    name: 'MeineCharaktereView',
    component: MeineCharaktereView
  },
  {
    path: '/wtool',
    name: 'WürfeltoolView',
    component: WürfeltoolView
  },
  {
    path: '/login',
    name: 'LoginView',
    component: LoginView
  },
  {
    path: '/gamemaster',
    name: 'GamemasterView',
    component: GamemasterView
  },
  {
    path: '/kampagne',
    name: 'KampagneView',
    component: KampagneView
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
