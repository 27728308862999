<template>
  <NavBars />
  <CharTabDSA />
</template>

<script>
import { defineComponent } from 'vue';

// Components
import NavBars from '@/components/NavBars.vue';
import CharTabDSA from '@/components/CharTabDSA.vue';

export default defineComponent({
  components: {
    NavBars, CharTabDSA
    },
});
</script>