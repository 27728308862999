import store from '@/store/store.js';

export function sendMessageToDisc(Message, Username = 'Anonymous') {
    if (store.state.Einstellungen
        && store.state.Einstellungen.useDiscordWebhook
        && store.state.Einstellungen.discordWebhookURL) {
        let username = Username
        if (store.state.User && username == 'Anonymous') {
            username = store.state.User.displayName
            console.log(store.state.User.displayName)
        }
        var data = {
            content: Message,
            username: username
        }
        try {
            var xhr = new XMLHttpRequest();
            xhr.open('POST', store.state.Einstellungen.discordWebhookURL, true);
            xhr.setRequestHeader('Content-Type', 'application/json');
            xhr.send(JSON.stringify(data));
            return true
        }
        catch (err) {
            console.log(err.message)
            return false
        }
    }
}
