<template>
    <NavBars />
    <KampagneTab />
</template>

<script>
import { defineComponent } from 'vue';

// Components
import NavBars from '@/components/NavBars.vue';
import KampagneTab from '@/components/KampagneTab.vue';

export default defineComponent({
  name: 'mKampagne',

  components: {
    NavBars, KampagneTab
  },
});
</script>