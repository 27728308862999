<template>
    <h1 class="custom-h1" block>Admin</h1>
    <v-container d-flex>
        <v-row>
            <v-col>
                <v-text-field v-model="this.sessionId" label="Session ID" />
                <v-btn @click="loadCollection">Nachrichten Laden</v-btn>
            </v-col>
        </v-row>
        <v-row no-gutters>
            <v-col cols="12" xs="12" sm="6" md="4" v-for="nachricht in this.nachrichten" :key="nachricht.id">
                <v-card link style="margin: 8px" @click="deleteDokument(nachricht.id)">
                    <v-card-title class="d-flex justify-space-between align-center">
                        <div>{{ nachricht.name }}</div>
                    </v-card-title>
                    <v-card-subtitle>{{ nachricht.message }}</v-card-subtitle>
                    <v-card-text style="font-size: 0.5em; text-align: right;">ID: {{ nachricht.id }}</v-card-text>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { getFirestore, collection, query, where, orderBy, getDocs, doc, deleteDoc } from 'firebase/firestore';

export default {
    data() {
        return {
            sessionId: 'Taverne Sternhagel',
            nachrichten: [],
        }
    },

    mounted() {
        if (!this.$store.state.User || this.$store.state.User.uid !== 'n3t7E4a3TJfrx2EPoTlqGdS5G9u2') {
            console.log('Admin Access Denied. Umleitung auf die billigen Plätze.')
            this.$router.push({ name: 'home' })
        }
    },

    methods: {
        async loadCollection() {
            try {
                const db = getFirestore()
                const q = query(
                    collection(db, "Nachrichten"),
                    where("sessionId", "==", this.sessionId),
                    orderBy("CreatedAt", "asc")
                )
                const querySnapshot = await getDocs(q)
                this.nachrichten = querySnapshot.docs.map(
                    doc => ({
                        id: doc.id,
                        ...doc.data()
                    })
                )
            } catch (err) {
                console.error("Fehler beim Laden der Sammlung:", err)
            }
        },

        async deleteDokument(id) {
            try {
                const db = getFirestore()
                const docRef = doc(db, "Nachrichten", id)
                await deleteDoc(docRef)
            } catch (err) {
                console.error("Fehler beim Löschen des Charakters: ", err)
            }
        },
    }
}  
</script>