<template>
  <h1 class="custom-h1" block>Meine Charaktere</h1>
  <v-container d-flex>
    <v-row no-gutters>
      <v-col cols="12" xs="12" sm="6" md="4" v-for="charakter in charaktere" :key="charakter.id">
        <v-card link style="margin: 8px" @click="OpenCharacter(charakter.id, charakter.System)">
          <v-card-title class="d-flex justify-space-between align-center">
            <div>{{ charakter.Name }}</div>
            <v-avatar size="55">
              <v-img :src="imagePath(charakter)" />
            </v-avatar>
          </v-card-title>
          <v-card-subtitle>{{ charakter.System }}</v-card-subtitle>
          <v-card-text style="font-size: 0.5em; text-align: right;">ID: {{ charakter.id }}</v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <h2 class="custom-h2" block>Neuen Charakter anlegen</h2>
    <v-row>
      <v-col>
        <v-card style="margin: 8px; margin-top: 25px; display: flex; flex-direction: column;">
          <v-card-title>Neuer Charakter</v-card-title>
          <v-text-field label="Name" v-model="newCharName" />
          <v-select v-model="newCharSystem" label="System" :items="this.$store.state.systeme" />
          <v-btn @click="newChar" :disabled="!newCharName || !newCharSystem" class="custom-button"
            style="margin: 10px;">Neu</v-btn>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { getFirestore, collection, query, where, /*orderBy,*/ onSnapshot, addDoc, serverTimestamp } from 'firebase/firestore';
import { dsaProben } from '@/composables/useDSAProben.js'
import { dndProben } from '@/composables/useDNDProben.js'
import { srProben } from '@/composables/useSRProben.js'

export default {
  data() {
    return {
      charaktere: [],
      newCharName: '',
      newCharSystem: '',
      newCharDSAProben: [],
      newCharDNDProben: [],
      newCharSRProben: [],
    };
  },

  mounted() {
    if (!this.$store.state.User) {      
      console.log('Auf Willkommensseite umgeleitet.')
      this.$router.push({ name: 'home' })
    } else {
      this.loadCharaktere()
    }
  },

  methods: {
    loadCharaktere() {
      const db = getFirestore()
      const q = query(
        collection(db, "Charaktere"),
        where("User", "==", this.$store.state.User.uid),
        //       orderBy("CreatedAt", "desc")
      )
      onSnapshot(q, (querySnapshot) => {
        this.charaktere = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
      });
    },

    async newChar() {
      const db = getFirestore();
      const colRefChars = collection(db, 'Charaktere')

      if (this.newCharSystem == 'D&D 5e') {
        this.newCharDNDProben = dndProben()
        await addDoc(colRefChars, {
          Name: this.newCharName,
          System: this.newCharSystem,
          User: this.$store.state.User.uid,
          CreatedAt: serverTimestamp(),
          Str: 0,
          Ges: 0,
          Kon: 0,
          Int: 0,
          Wei: 0,
          Cha: 0,
          Notizen: '',
          Notizen1: '',
          Notizen2: '',
          Notizen3: '',
          Übungsbonus: 2,
          customRolls: this.newCharDNDProben
        })

      } else if (this.newCharSystem == 'DSA 4.1') {
        this.newCharDSAProben = dsaProben()
        await addDoc(colRefChars, {
          Name: this.newCharName,
          System: this.newCharSystem,
          User: this.$store.state.User.uid,
          CreatedAt: serverTimestamp(),
          Mut: 10,
          Klugheit: 10,
          Intuition: 10,
          Charisma: 10,
          Fingerfertigkeit: 10,
          Gewandtheit: 10,
          Konstitution: 10,
          Körperkraft: 10,
          Proben: this.newCharDSAProben
        })

      } else if (this.newCharSystem == 'Shadowrun 5e') {
        this.newCharSRProben = srProben()
        await addDoc(colRefChars, {
          Name: this.newCharName,
          System: this.newCharSystem,
          User: this.$store.state.User.uid,
          CreatedAt: serverTimestamp(),
          Konstitution: 0,
          Geschicklichkeit: 0,
          Reaktion: 0,
          Stärke: 0,
          Charisma: 0,
          Intuition: 0,
          Logik: 0,
          Willenskraft: 0,
          Edge: 0,
          Magie: 0,
          HatMagie: false,
          Resonanz: 0,
          HatResonanz: false,
          Panzerung: 0,
          Gerätestufe: 0,
          Datenverarbeitung: 0,
          Firewall: 0,
          Schleicher: 0,
          Angriff: 0,
          Proben: this.newCharSRProben
        })
      } else {
        await addDoc(colRefChars, {
          Name: this.newCharName,
          System: this.newCharSystem,
          User: this.$store.state.User.uid,
          CreatedAt: serverTimestamp()
        })
      }
      this.$store.commit('callSnackbar', "Charakter erfolgreich angelegt.")
      this.newCharName = ""
      this.newCharSystem = ""
    },

    OpenCharacter(id, system) {
      this.$store.commit('setState', { key: 'activeCharID', value: id })
      if (system == 'D&D 5e') {
        this.$router.push({ name: 'CharDNDView' })
      }
      else if (system == 'DSA 4.1') {
        this.$router.push({ name: 'CharDSAView' })
      }
      else if (system == 'Shadowrun 5e') {
        this.$router.push({ name: 'CharSRView' })
      }
      else {
        this.$router.push({ name: 'CharakterView' })
      }
    },

    imagePath(charakter) {
      try {
        let result = ''
        if (charakter.picURL) {
          result = charakter.picURL
        }
        else {
          result = require(`@/assets/SysPic/${charakter.System}.png`)
        }
        return result;
      } catch (e) {
        console.error(e);
        return '';
      }
    }

  }
}
</script>
