<template>
    <h1 class="custom-h1" block v-if="this.$store.state.activeChar" style="height: 80px; padding: 0;">
        <v-container style="padding: 0;">
            <v-row class="centered-content" style="padding: 0;">
                <v-col cols="7" style="padding: 0; margin-bottom: 15px;">
                    {{ Name }}
                </v-col>
                <v-col cols="1" v-if="this.$store.state.User">
                    <v-tooltip text="Speichern" location="bottom">
                        <template v-slot:activator="{ props }">
                            <v-img :src="require('@/assets/OIcon/SpeichernIcon.png')" alt="Speichern" height="45"
                                class="icon-img" v-bind="props" @click="saveChar" v-show="this.Bearbeitungsmodus" />
                        </template>
                    </v-tooltip>
                </v-col>
                <v-col cols="1" v-if="this.$store.state.User">
                    <v-tooltip text="Zum Löschen des ! Charakters ! ALT gedrückt halten" location="bottom">
                        <template v-slot:activator="{ props }">
                            <v-img :src="require('@/assets/OIcon/LöschenIcon.png')" alt="Löschen" height="45"
                                class="icon-img" v-bind="props" @click.alt="deleteChar"
                                v-show="this.Bearbeitungsmodus" />
                        </template>
                    </v-tooltip>
                </v-col>
                <v-col cols="3">
                    <v-switch label="Bearbeitungsmodus" color=green v-model="Bearbeitungsmodus" />
                </v-col>
            </v-row>
        </v-container>
    </h1>

    <div class="custom-sitebuttons">
        <v-container>
            <v-row>
                <v-col>
                    <v-btn @click="this.site = 1" class="custom-pointer"
                        :style="this.site == 1 ? 'background-color: rgba(0,50,0,0.5)' : 'background-color: rgba(50,50,50,0.5)'">1</v-btn>
                </v-col>
                <v-col>
                    <v-btn @click="this.site = 2" class="custom-pointer"
                        :style="this.site == 2 ? 'background-color: rgba(0,50,0,0.5)' : 'background-color: rgba(50,50,50,0.5)'">2</v-btn>
                </v-col>
                <v-col>
                    <v-btn @click="this.site = 3" class="custom-pointer"
                        :style="this.site == 3 ? 'background-color: rgba(0,50,0,0.5)' : 'background-color: rgba(50,50,50,0.5)'">3</v-btn>
                </v-col>
                <v-col>
                    <v-btn @click="this.site = 4" class="custom-pointer"
                        :style="this.site == 4 ? 'background-color: rgba(0,50,0,0.5)' : 'background-color: rgba(50,50,50,0.5)'">4</v-btn>
                </v-col>
                <v-col>
                    <v-btn @click="this.site = 5" class="custom-pointer"
                        :style="this.site == 5 ? 'background-color: rgba(0,50,0,0.5)' : 'background-color: rgba(50,50,50,0.5)'">5</v-btn>
                </v-col>
            </v-row>
        </v-container>
    </div>

    <!-- SEITE 1 -->
    <v-container v-show="this.site == 1">
        <v-row v-show="this.Bearbeitungsmodus">
            <v-col cols="6">
                <v-text-field label="Name" v-model="Name"></v-text-field>
            </v-col>
            <v-col cols="4">
                <v-file-input label="Charakter Bild hochladen" v-model="fileHandle" ref="fileInput"></v-file-input>
            </v-col>
            <v-col cols="2">
                <v-btn @click="fileHandler('Bild')" style="margin-bottom: 15px;">Upload</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="10"></v-col>
            <v-col cols="2">
                <v-img :src='charPicurl' style="margin-bottom: -999px; margin-top: -25px; margin-right: -80px;" once
                    max-height="145px" max-width="250px"></v-img>
            </v-col>
        </v-row>
        <h2 class="custom-h2" block>Proben</h2>
        <!-- Bonustabelle -->
        <v-row align="center">
            <v-col cols="6">
                <v-text-field type="text" label="Aktion" v-model="Aktion"></v-text-field>
                <v-text-field type="number" label="Übungsbonus" v-model="Übungsbonus"
                    :disabled="!this.Bearbeitungsmodus"></v-text-field>
                <v-text-field type="number" label="Modifikator" v-model="Modifikator"></v-text-field>
            </v-col>
            <v-col cols="2">
                <v-checkbox label="+W4" v-model="w4" style="max-height: 45px;" />
                <v-checkbox label="+W6" v-model="w6" style="max-height: 45px;" />
                <v-checkbox label="+W8" v-model="w8" style="max-height: 45px;" />
                <v-checkbox label="+W10" v-model="w10" style="max-height: 45px;" />
                <v-checkbox label="+W12" v-model="w12" style="max-height: 45px;" />
            </v-col>
            <v-col cols="2">
                <v-checkbox label="Vorteil" v-model="Vorteil" style="max-height: 55px;" />
                <v-checkbox label="Nachteil" v-model="Nachteil" style="max-height: 55px;" />
            </v-col>
            <v-col cols="2">
                <v-checkbox label="Übung" v-model="Übung" style="max-height: 55px;" />
                <v-checkbox label="Expertise" v-model="Expertise" style="max-height: 55px;" />
            </v-col>
        </v-row>
        <!-- Hauptproben -->
        <v-row>
            <v-col cols="2">
                <v-card
                    :style="this.newCustomRollAttribut == 'Str' ? 'border: 1px solid aquamarine; border-radius: 5px; border-width: 1px;' : ''">
                    <v-tooltip text="Stärkemodifikator" location="left" style="font-style: italic;">
                        <template v-slot:activator="{ props }">
                            <v-text-field type="number" v-bind="props" label="Stärke" v-model="Str"
                                :disabled="!this.Bearbeitungsmodus"></v-text-field>
                        </template>
                    </v-tooltip>
                    <v-img class="button-wuerfel" @click="roll('Str')" :src="require('@/assets/OIcon/Wuerfeln.png')" />
                </v-card>
            </v-col>
            <v-col cols="2">
                <v-card
                    :style="this.newCustomRollAttribut == 'Ges' ? 'border: 1px solid aquamarine; border-radius: 5px; border-width: 1px;' : ''">
                    <v-tooltip text="Geschickmodifikator" location="left" style="font-style: italic;">
                        <template v-slot:activator="{ props }">
                            <v-text-field type="number" v-bind="props" label="Geschick" v-model="Ges"
                                :disabled="!this.Bearbeitungsmodus"></v-text-field>
                        </template>
                    </v-tooltip>
                    <v-img class="button-wuerfel" @click="roll('Ges')" :src="require('@/assets/OIcon/Wuerfeln.png')" />
                </v-card>
            </v-col>
            <v-col cols="2">
                <v-card
                    :style="this.newCustomRollAttribut == 'Kon' ? 'border: 1px solid aquamarine; border-radius: 5px; border-width: 1px;' : ''">
                    <v-tooltip text="Konstitutionsmodifikator" location="left" style="font-style: italic;">
                        <template v-slot:activator="{ props }">
                            <v-text-field type="number" v-bind="props" label="Konstitution" v-model="Kon"
                                :disabled="!this.Bearbeitungsmodus"></v-text-field>
                        </template>
                    </v-tooltip>
                    <v-img class="button-wuerfel" @click="roll('Kon')" :src="require('@/assets/OIcon/Wuerfeln.png')" />
                </v-card>
            </v-col>
            <v-col cols="2">
                <v-card
                    :style="this.newCustomRollAttribut == 'Int' ? 'border: 1px solid aquamarine; border-radius: 5px; border-width: 1px;' : ''">
                    <v-tooltip text="Intelligenzmodifikator" location="left" style="font-style: italic;">
                        <template v-slot:activator="{ props }">
                            <v-text-field type="number" v-bind="props" label="Intelligenz" v-model="Int"
                                :disabled="!this.Bearbeitungsmodus"></v-text-field>
                        </template>
                    </v-tooltip>
                    <v-img class="button-wuerfel" @click="roll('Int')" :src="require('@/assets/OIcon/Wuerfeln.png')" />
                </v-card>
            </v-col>
            <v-col cols="2">
                <v-card
                    :style="this.newCustomRollAttribut == 'Wei' ? 'border: 1px solid aquamarine; border-radius: 5px; border-width: 1px;' : ''">
                    <v-tooltip text="Weisheitsmodifikator" location="left" style="font-style: italic;">
                        <template v-slot:activator="{ props }">
                            <v-text-field type="number" v-bind="props" label="Weisheit" v-model="Wei"
                                :disabled="!this.Bearbeitungsmodus"></v-text-field>
                        </template>
                    </v-tooltip>
                    <v-img class="button-wuerfel" @click="roll('Wei')" :src="require('@/assets/OIcon/Wuerfeln.png')" />
                </v-card>
            </v-col>
            <v-col cols="2">
                <v-card
                    :style="this.newCustomRollAttribut == 'Cha' ? 'border: 1px solid aquamarine; border-radius: 5px; border-width: 1px;' : ''">
                    <v-tooltip text="Charismamodifikator" location="left" style="font-style: italic;">
                        <template v-slot:activator="{ props }">
                            <v-text-field type="number" v-bind="props" label="Charisma" v-model="Cha"
                                :disabled="!this.Bearbeitungsmodus"></v-text-field>
                        </template>
                    </v-tooltip>
                    <v-img class="button-wuerfel" @click="roll('Cha')" :src="require('@/assets/OIcon/Wuerfeln.png')" />
                </v-card>
            </v-col>
        </v-row>
        <!-- Sonstige Würfel -->
        <h2 class="custom-h2" block>Andere Würfel</h2>
        <v-row>
            <v-col>
                <v-text-field type="number" label="w20" v-model="fw20"></v-text-field>
            </v-col>
            <v-col>
                <v-text-field type="number" label="w12" v-model="fw12"></v-text-field>
            </v-col>
            <v-col>
                <v-text-field type="number" label="w10" v-model="fw10"></v-text-field>
            </v-col>
            <v-col>
                <v-text-field type="number" label="w8" v-model="fw8"></v-text-field>
            </v-col>
            <v-col>
                <v-text-field type="number" label="w6" v-model="fw6"></v-text-field>
            </v-col>
            <v-col>
                <v-text-field type="number" label="w4" v-model="fw4"></v-text-field>
            </v-col>
            <v-col>
                <v-text-field type="number" label="Mod" v-model="fMod"></v-text-field>
            </v-col>
            <v-col>
                <v-img class="button-wuerfel" @click="roll('ohne')" :src="require('@/assets/OIcon/Wuerfeln.png')" />
            </v-col>
        </v-row>
        <!-- Gespeicherte Proben -->
        <h2 class="custom-h2" block>
            <v-row>
                <v-col cols="10">
                    Gespeicherte Proben
                </v-col>
                <v-col cols="2">
                    <v-btn icon class="custom-button" style="margin: 0; padding: 0; width: 30px; height: 30px;"
                        @click="openInfoDialog">
                        <v-icon>mdi-information-outline</v-icon>
                    </v-btn>
                </v-col>
            </v-row>
        </h2>
        <v-row>
            <v-col>
                <v-container>
                    <v-row>
                        <v-col v-for="customRoll in this.customRolls" :key="customRoll"
                            :cols="customRoll.cddShowHeadline ? 12 : 3">

                            <div
                                :style="this.activeCustomRollId == customRoll.id && this.Bearbeitungsmodus ? 'border: 1px solid aquamarine; border-radius: 5px; border-width: 1px;' : ''">
                                <template v-if="customRoll.cddShowHeadline">
                                    <v-card link @click="triggerCustomRoll($event, customRoll)"
                                        @contextmenu.prevent="triggerCustomRoll($event, customRoll)">
                                        <v-card-title>{{ customRoll.cddname }}</v-card-title>
                                    </v-card>
                                </template>

                                <template v-else>
                                    <v-card link :title="customRoll.cddname"
                                        :append-avatar="require('@/assets/ProbenSymbole/' + customRoll.cddPicture + '.png')"
                                        @click="triggerCustomRoll($event, customRoll)"
                                        @contextmenu.prevent="triggerCustomRoll($event, customRoll)" :subtitle="(customRoll.cddattribut !== 'ohne' ? customRoll.cddattribut : '') +
        (customRoll.cddgeübt ? ' Ü' : '') +
        (customRoll.cddexpertise ? ' E' : '') +
        (customRoll.cddNachteil ? ' N' : '') +
        (customRoll.cddVorteil ? ' V' : '') +
        (+customRoll.cddModifikator !== 0 ? ' +' + customRoll.cddModifikator : '') +
        (+customRoll.cddfw20 !== 0 ? ' ' + customRoll.cddfw20 + 'W20' : '') +
        (+customRoll.cddfw12 !== 0 ? ' ' + customRoll.cddfw12 + 'W12' : '') +
        (+customRoll.cddfw10 !== 0 ? ' ' + customRoll.cddfw10 + 'W10' : '') +
        (+customRoll.cddfw8 !== 0 ? ' ' + customRoll.cddfw8 + 'W8' : '') +
        (+customRoll.cddfw6 !== 0 ? ' ' + customRoll.cddfw6 + 'W6' : '') +
        (+customRoll.cddfw4 !== 0 ? ' ' + customRoll.cddfw4 + 'W4' : '') +
        (+customRoll.cddfMod !== 0 ? '+' + customRoll.cddfMod : '') +
        (customRoll.cddfAttribut !== 'ohne' ? ' +' + customRoll.cddfAttribut : '')" />
                                </template>
                            </div>

                        </v-col>

                    </v-row>
                </v-container>
            </v-col>
            <v-col cols="6" v-show="this.Bearbeitungsmodus">
                <v-card style="margin: 8px; margin-top: 25px; display: flex; flex-direction: column;">
                    <v-card-title>Neuen Wurf speichern:</v-card-title>
                    <v-text-field type="text" label="Name" v-model="Aktion"></v-text-field>
                    <v-tooltip text="Für Fertiggkeitsproben, Angrffe, Zauber usw." location="left"
                        style="font-style: italic;">
                        <template v-slot:activator="{ props }">
                            <v-select v-model="newCustomRollAttribut" v-bind="props" label="Attributsprobe"
                                :items="['Str', 'Ges', 'Kon', 'Int', 'Wei', 'Cha', 'ohne']"></v-select>
                        </template>
                    </v-tooltip>
                    <v-tooltip text="Attributsbonus für Schaden und Heilung usw." location="left"
                        style="font-style: italic;">
                        <template v-slot:activator="{ props }">
                            <v-select v-model="newCustomRollAttributB" v-bind="props" label="Attributsbonus"
                                :items="['Str', 'Ges', 'Kon', 'Int', 'Wei', 'Cha', 'ohne']"></v-select>
                        </template>
                    </v-tooltip>
                    <v-tooltip text="Von 0 (oben) bis n (unten), es gehen auch Kommazahlen" location="left"
                        style="font-style: italic;">
                        <template v-slot:activator="{ props }">
                            <v-text-field type="number" label="Sortiernummer" v-bind="props"
                                v-model="newCustomRollSort"></v-text-field>
                        </template>
                    </v-tooltip>
                    <v-tooltip text="Legt eine Überschrift an" location="left" style="font-style: italic;">
                        <template v-slot:activator="{ props }">
                            <v-checkbox type="number" label="Überschrift" v-bind="props"
                                v-model="showHeadline"></v-checkbox>
                        </template>
                    </v-tooltip>
                    <v-container>
                        <v-row>
                            <v-col cols="2" v-for="symbol in symbols" :key="symbol.filename">
                                <v-card :elevation="symbol.filename === newCustomRollPic ? 12 : 2"
                                    @click="selectSymbol(symbol.filename)">
                                    <v-img :src="require('@/assets/ProbenSymbole/' + symbol.filename + '.png')"
                                        max-height="40" aspect-ratio="1" contain />
                                </v-card>
                            </v-col>
                        </v-row>
                        <v-row>
                            <v-col>
                                <v-text-field v-model="newCustomRollPic" disabled></v-text-field>
                            </v-col>
                        </v-row>
                    </v-container>

                    <v-btn @click="addCustomRoll"
                        :disabled="!this.Aktion || !this.newCustomRollAttribut || !this.newCustomRollAttributB || !newCustomRollPic"
                        class="custom-button" style="margin: 5px;">Neu</v-btn>

                    <v-tooltip text="Überschreibt aktuell ausgewählten Wurf" location="left"
                        style="font-style: italic;">
                        <template v-slot:activator="{ props }">
                            <v-btn @click="saveCustomRoll" v-bind="props"
                                :disabled="!this.Aktion || !this.newCustomRollAttribut || !this.newCustomRollAttributB || !newCustomRollPic || !this.activeCustomRollId || !this.Bearbeitungsmodus"
                                class="custom-button" style="margin: 5px;">Speichern</v-btn>
                        </template>
                    </v-tooltip>
                </v-card>
            </v-col>
        </v-row>


        <v-dialog v-model="infoDialog" max-width="600px">
            <v-card>
                <v-card-title>Gespeicherte Proben</v-card-title>
                <v-card-text>
                    <pre>
Wird eine neue Probe gespeichert werden die
folgenden aktuell gewählten Einstellungen gespeichert:
- Aktion / Name
- Vorteil / Nachteil
- Übung und Expertise
- Modifikator
- Attribut als Probe oder Bonus
- Freie Würfel + Modifikator

Die gespeicherte Probe kann dann:
- geladen werden (Linksklick)
- direkt gewürfelt werden (Rechtsklick)
- gelöscht werden (ALT + Rechtsklick)
                </pre>
                </v-card-text>
                <v-card-actions>
                    <v-btn class="custom-button" text @click="infoDialog = false">Schließen</v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </v-container>

    <!-- SEITE 2 -->
    <v-container v-show="this.site == 2">
        <h2 class="custom-h2" block>Notizen</h2>
        <v-row>
            <v-col cols="12">
                <v-btn @click="saveNotizen">speichern</v-btn>
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="12">
                <v-textarea label="Notiz" v-model="Notizen" auto-grow rows="1" />
            </v-col>
        </v-row>
        <v-row>
            <v-col cols="8">
                <v-textarea label="Notiz" v-model="Notizen1" auto-grow />
            </v-col>
            <v-col cols="3">
                <v-textarea label="Notiz" v-model="Notizen2" auto-grow />
            </v-col>
            <v-col cols="1">
                <v-textarea label="Notiz" v-model="Notizen3" auto-grow />
            </v-col>
        </v-row>
    </v-container>

    <!-- SEITE 3 -->
    <v-container v-show="this.site == 3">
        <h2 class="custom-h2" block>Charakterbogen</h2>
        <v-row v-show="this.Bearbeitungsmodus || !this.charPDFurl">
            <v-col>
                <v-file-input label="Charakterbogen hochladen" v-model="fileHandle" ref="fileInput"></v-file-input>
            </v-col>
            <v-col>
                <v-btn @click="fileHandler('Char')" style="margin-bottom: 15px;">Upload</v-btn>
            </v-col>
        </v-row>
        <iframe v-if="charPDFurl" :src="charPDFurl" width="100%" height="1200px"></iframe>
    </v-container>

    <!-- SEITE 4 -->
    <v-container v-show="this.site == 4">
        <h2 class="custom-h2" block>Weitere Datei</h2>
        <v-row v-show="this.Bearbeitungsmodus || !this.charPDF1url">
            <v-col>
                <v-file-input label="Datei hochladen" v-model="fileHandle" ref="fileInput"></v-file-input>
            </v-col>
            <v-col>
                <v-btn @click="fileHandler('PDF1')" style="margin-bottom: 15px;">Upload</v-btn>
            </v-col>
        </v-row>
        <iframe v-if="charPDF1url" :src="charPDF1url" width="100%" height="1200px"></iframe>
    </v-container>

    <!-- SEITE 5 -->
    <v-container v-show="this.site == 5">
        <h2 class="custom-h2" block>Weitere Datei</h2>
        <v-row v-show="this.Bearbeitungsmodus || !this.charPDF2url">
            <v-col>
                <v-file-input label="Datei hochladen" v-model="fileHandle" ref="fileInput"></v-file-input>
            </v-col>
            <v-col>
                <v-btn @click="fileHandler('PDF2')" style="margin-bottom: 15px;">Upload</v-btn>
            </v-col>
        </v-row>
        <iframe v-if="charPDF2url" :src="charPDF2url" width="100%" height="1200px"></iframe>
    </v-container>

</template>

<script>
import { getFirestore, onSnapshot, doc, deleteDoc, updateDoc, setDoc } from 'firebase/firestore';
import { sendMessageToDisc } from '../composables/useDiscord.js'
import { sendMessageToHistory } from '../composables/useSendHistory.js'

import { storage } from '@/firebase.js'
import { getDownloadURL, ref as storageRef, uploadBytes } from 'firebase/storage'

export default {
    data() {
        return {
            unsubscribeActiveChar: null,
            site: 1,
            infoDialog: false,
            Bearbeitungsmodus: false,
            symbols: [
                { filename: 'Leer' },
                { filename: 'Auge' },
                { filename: 'Lupe' },
                { filename: 'Waage' },
                { filename: 'Wagenrad' },
                { filename: 'Schiff' },
                { filename: 'Stiefel' },
                { filename: 'Intelligenz' },
                { filename: 'Sozial' },
                { filename: 'Mund' },
                { filename: 'Schrei' },
                { filename: 'Umhang' },
                { filename: 'Baum' },
                { filename: 'Uberleben' },
                { filename: 'Wolf' },
                { filename: 'Meditation' },
                { filename: 'Alchemie' },
                { filename: 'Holz' },
                { filename: 'Hammer' },
                { filename: 'Faust' },
                { filename: 'Bogen' },
                { filename: 'Schwert' },
                { filename: 'Schild' },
                { filename: 'Axt' },
                { filename: 'Zauberbuch' },
                { filename: 'Blaues Kreuz' },
                { filename: 'Herz' },
                { filename: 'Feuer' },
                { filename: 'Eis' },
                { filename: 'Gift' },
                { filename: 'Heilig' },
                { filename: 'Totenkopf' },
                { filename: 'Blutstropfen' },
                { filename: 'Schatten' },
                { filename: 'Energie' },
                { filename: 'Blitz' },
                { filename: 'Psycho' },
                { filename: 'Schall' },
            ],

            Name: '',
            System: '',
            User: '',
            CreatedAt: '',
            Str: 0,
            Ges: 0,
            Kon: 0,
            Int: 0,
            Wei: 0,
            Cha: 0,
            Aktion: '',
            Übungsbonus: 2,
            customRolls: [],
            newCustomRollAttribut: 'ohne',
            newCustomRollAttributB: 'ohne',
            newCustomRollPic: '',
            newCustomRollSort: 0,

            Notizen: '',
            Notizen1: '',
            Notizen2: '',
            Notizen3: '',

            activeCustomRollId: '',

            Modifikator: 0,
            w4: false,
            w6: false,
            w8: false,
            w10: false,
            w12: false,
            Vorteil: false,
            Nachteil: false,
            Übung: false,
            Expertise: false,
            showHeadline: false,

            fw20: 0,
            fw12: 0,
            fw10: 0,
            fw8: 0,
            fw6: 0,
            fw4: 0,
            fMod: 0,

            fileHandle: null,
            fileHandle1: null,
            fileHandle2: null,
            charPDFurl: '',
            charPicurl: '',
            charPDF1url: '',
            charPDF2url: '',
        };
    },

    watch: {
        Vorteil(newVal) {
            if (newVal) this.Nachteil = false
        },
        Nachteil(newVal) {
            if (newVal) this.Vorteil = false
        },
        newCustomRollAttribut(newValue) {
            if (newValue !== 'ohne') {
                this.newCustomRollAttributB = 'ohne';
            }
        },
        newCustomRollAttributB(newValue) {
            if (newValue !== 'ohne') {
                this.newCustomRollAttribut = 'ohne';
            }
        },
    },



    mounted() {
        this.loadChar()
    },

    beforeUnmount() { // beforeDestroy() für Vue 2
        if (this.unsubscribeActiveChar) {
            this.unsubscribeActiveChar();
        }
    },

    methods: {
        loadChar() {
            if (this.unsubscribeActiveChar) {
                this.unsubscribeActiveChar();
            }

            if (!this.$store.state.activeCharID) {
                this.$router.push({ name: 'home' })
                console.log("activeCharID ist nicht gesetzt. Umgeleitet an home.");
                return;
            }

            const db = getFirestore();
            const docRef = doc(db, "Charaktere", this.$store.state.activeCharID);

            this.unsubscribeActiveChar = onSnapshot(docRef, (docSnapshot) => {
                if (docSnapshot.exists()) {
                    this.$store.commit('setState', { key: 'activeChar', value: { id: docSnapshot.id, ...docSnapshot.data() } })
                    this.Name = docSnapshot.data().Name
                    this.System = docSnapshot.data().System
                    this.CreatedAt = docSnapshot.data().CreatedAt
                    this.User = docSnapshot.data().User
                    this.CreatedAt = docSnapshot.data().CreatedAt
                    this.Str = docSnapshot.data().Str
                    this.Ges = docSnapshot.data().Ges
                    this.Kon = docSnapshot.data().Kon
                    this.Int = docSnapshot.data().Int
                    this.Wei = docSnapshot.data().Wei
                    this.Cha = docSnapshot.data().Cha
                    if (docSnapshot.data().Notizen == undefined) { this.Notizen = '' } else { this.Notizen = docSnapshot.data().Notizen }
                    if (docSnapshot.data().Notizen1 == undefined) { this.Notizen1 = '' } else { this.Notizen1 = docSnapshot.data().Notizen1 }
                    if (docSnapshot.data().Notizen2 == undefined) { this.Notizen2 = '' } else { this.Notizen2 = docSnapshot.data().Notizen2 }
                    if (docSnapshot.data().Notizen3 == undefined) { this.Notizen3 = '' } else { this.Notizen3 = docSnapshot.data().Notizen3 }
                    if (docSnapshot.data().pdfURL == undefined) { this.charPDFurl = '' } else { this.charPDFurl = docSnapshot.data().pdfURL }
                    if (docSnapshot.data().picURL == undefined) { this.charPicurl = '' } else { this.charPicurl = docSnapshot.data().picURL }
                    if (docSnapshot.data().PDF1URL == undefined) { this.charPDF1url = '' } else { this.charPDF1url = docSnapshot.data().PDF1URL }
                    if (docSnapshot.data().PDF2URL == undefined) { this.charPDF2url = '' } else { this.charPDF2url = docSnapshot.data().PDF2URL }
                    this.Übungsbonus = docSnapshot.data().Übungsbonus
                    if (docSnapshot.data() && Array.isArray(docSnapshot.data().customRolls)) {
                        this.customRolls = docSnapshot.data().customRolls.sort((a, b) => {
                            return a.cddSort - b.cddSort;
                        })
                    }

                } else {
                    console.log("Kein Dokument mit dieser ID gefunden.")
                    this.$store.commit('setState', { key: 'activeChar', value: null })

                }
            }, (error) => {
                console.error("Fehler beim Empfangen des Charakters:", error)
            });
        },

        saveChar() {
            if (!this.$store.state.activeCharID) {
                console.error("Keine activeCharID verfügbar.")
                return
            }
            try {
                const db = getFirestore()
                const docRef = doc(db, "Charaktere", this.$store.state.activeCharID)
                updateDoc(docRef, {
                    Name: this.Name,
                    Str: this.Str,
                    Ges: this.Ges,
                    Kon: this.Kon,
                    Int: this.Int,
                    Wei: this.Wei,
                    Cha: this.Cha,
                    Übungsbonus: this.Übungsbonus,
                    Notizen: this.Notizen,
                    Notizen1: this.Notizen1,
                    Notizen2: this.Notizen2,
                    Notizen3: this.Notizen3,
                })
                this.$store.commit('callSnackbar', this.Name + " wurde gespeichert.")
            } catch (err) {
                console.error("Fehler beim Speichern des Dokuments: ", err)
            }
        },

        saveNotizen() {
            if (!this.$store.state.activeCharID) {
                console.error("Keine activeCharID verfügbar.")
                return
            }
            try {
                const db = getFirestore()
                const docRef = doc(db, "Charaktere", this.$store.state.activeCharID)
                updateDoc(docRef, {
                    Notizen: this.Notizen,
                    Notizen1: this.Notizen1,
                    Notizen2: this.Notizen2,
                    Notizen3: this.Notizen3,
                })
                this.$store.commit('callSnackbar', "Notiz gespeichert.")
            } catch (err) {
                console.error("Fehler beim Speichern der Notiz: ", err)
            }
        },

        async deleteChar() {
            if (!this.$store.state.activeCharID) {
                console.error("Keine activeCharID verfügbar.")
                return
            }
            try {
                const db = getFirestore();
                const docRef = doc(db, "Charaktere", this.$store.state.activeCharID)
                await deleteDoc(docRef)
                this.$router.push({ name: 'home' })
                this.$store.commit('callSnackbar', this.Name + " wurde gelöscht.")
            } catch (err) {
                console.error("Fehler beim Löschen des Charakters: ", err)
            }
        },

        roll(e) {
            let result = 'wirft'
            if (this.Aktion) { result += ' ' + this.Aktion + ": " }
            else { result += ": " }
            let total = 0
            if (e !== 'ohne') {
                // w20 inkl Vorteil / Nachteil
                let w20_1 = Math.floor(Math.random() * 20) + 1
                let w20_2 = Math.floor(Math.random() * 20) + 1
                let w20 = w20_1
                if (this.Vorteil) {
                    w20 = Math.max(w20_1, w20_2)
                    total += +w20
                    result += "w20v (~~" + Math.min(w20_1, w20_2) + "~~|" + w20 + ")"
                } else if (this.Nachteil) {
                    w20 = Math.min(w20_1, w20_2)
                    total += +w20
                    result += "w20n (~~" + Math.max(w20_1, w20_2) + "~~|" + w20 + ")"
                } else {
                    total += +w20
                    result += "w20 (" + w20 + ")"
                }
                if (+w20 == 1) {
                    result += " \u{1F4A9}" // emoji poop
                }
                if (+w20 == 20) {
                    result += " \u{1F929}" // emoji star-struck
                }

                // Attribut
                total += +this[e]
                result += " +" + e + " (" + this[e] + ")"
            }

            if (e == 'ohne') {
                if (this.fw20 > 0) {
                    let amount = this.fw20
                    result += this.fw20 + "w20 ("
                    for (var i = 0; i < amount; i++) {
                        let cur = Math.floor(Math.random() * 20) + 1
                        total += +cur
                        result += cur + ", "
                    }
                    result = result.slice(0, -2)
                    result += ") "
                }
                if (this.fw12 > 0) {
                    let amount = this.fw12
                    result += this.fw12 + "w12 ("
                    for (i = 0; i < amount; i++) {
                        let cur = Math.floor(Math.random() * 12) + 1
                        total += +cur
                        result += cur + ", "
                    }
                    result = result.slice(0, -2)
                    result += ") "
                }
                if (this.fw10 > 0) {
                    let amount = this.fw10
                    result += this.fw10 + "w10 ("
                    for (i = 0; i < amount; i++) {
                        let cur = Math.floor(Math.random() * 10) + 1
                        total += +cur
                        result += cur + ", "
                    }
                    result = result.slice(0, -2)
                    result += ") "
                }
                if (this.fw8 > 0) {
                    let amount = this.fw8
                    result += this.fw8 + "w8 ("
                    for (i = 0; i < amount; i++) {
                        let cur = Math.floor(Math.random() * 8) + 1
                        total += +cur
                        result += cur + ", "
                    }
                    result = result.slice(0, -2)
                    result += ") "
                }
                if (this.fw6 > 0) {
                    let amount = this.fw6
                    result += this.fw6 + "w6 ("
                    for (i = 0; i < amount; i++) {
                        let cur = Math.floor(Math.random() * 6) + 1
                        total += +cur
                        result += cur + ", "
                    }
                    result = result.slice(0, -2)
                    result += ") "
                }
                if (this.fw4 > 0) {
                    let amount = this.fw4
                    result += this.fw4 + "w4 ("
                    for (i = 0; i < amount; i++) {
                        let cur = Math.floor(Math.random() * 4) + 1
                        total += +cur
                        result += cur + ", "
                    }
                    result = result.slice(0, -2)
                    result += ") "
                }
                // Attributsbonus
                if (this.newCustomRollAttributB && this.newCustomRollAttributB !== 'ohne') {
                    total += +this[this.newCustomRollAttributB]
                    result += " +" + this.newCustomRollAttributB + " (" + this[this.newCustomRollAttributB] + ")"
                }
                // fMod
                if (+this.fMod !== 0) {
                    result += "+Mod (" + this.fMod + ")"
                    total += +this.fMod
                }
            }




            if (e !== 'ohne') {
                //Expertise / Übung
                if (this.Expertise) {
                    let Ex = +this.Übungsbonus * 2
                    total += +Ex
                    result += " + E (" + Ex + ")"
                } else if (this.Übung) {
                    total += +this.Übungsbonus
                    result += " + Ü (" + this.Übungsbonus + ")"
                }
                // Modifikator
                if (+this.Modifikator !== 0) {
                    total += +this.Modifikator
                    result += " + Mod (" + this.Modifikator + ")"
                }
                // w4 Bonus
                if (this.w4) {
                    let w4r = Math.floor(Math.random() * 4) + 1
                    total += +w4r
                    result += " + w4 (" + w4r + ")"
                }
                // w6 Bonus
                if (this.w6) {
                    let w6r = Math.floor(Math.random() * 6) + 1
                    total += +w6r
                    result += " + w6 (" + w6r + ")"
                }
                // w8 Bonus
                if (this.w8) {
                    let w8r = Math.floor(Math.random() * 8) + 1
                    total += +w8r
                    result += " + w8 (" + w8r + ")"
                }
                // w10 Bonus
                if (this.w10) {
                    let w10r = Math.floor(Math.random() * 10) + 1
                    total += +w10r
                    result += " + w10 (" + w10r + ")"
                }
                // w12 Bonus
                if (this.w12) {
                    let w12r = Math.floor(Math.random() * 12) + 1
                    total += +w12r
                    result += " + w12 (" + w12r + ")"
                }

            }

            this.$store.commit('callDicer', { result: result, total: total })
            // total
            result += " = " + total
            sendMessageToDisc(result, this.Name)
            sendMessageToHistory(result)
        },

        addCustomRoll() {
            if (!this.$store.state.activeCharID) {
                console.error("Keine activeCharID verfügbar.")
                return
            }

            try {
                const db = getFirestore()
                const docRef = doc(db, "Charaktere", this.$store.state.activeCharID)
                const nid = Math.random().toString(36).substr(2, 20) + Date.now().toString()

                let customRolls = this.customRolls || []
                let newRoll = {
                    cddname: this.Aktion,
                    cddgeübt: this.Übung,
                    cddexpertise: this.Expertise,
                    cddattribut: this.newCustomRollAttribut,
                    cddVorteil: this.Vorteil,
                    cddNachteil: this.Nachteil,
                    cddModifikator: this.Modifikator,
                    cddfw20: this.fw20,
                    cddfw12: this.fw12,
                    cddfw10: this.fw10,
                    cddfw8: this.fw8,
                    cddfw6: this.fw6,
                    cddfw4: this.fw4,
                    cddfMod: this.fMod,
                    cddShowHeadline: this.showHeadline,
                    cddfAttribut: this.newCustomRollAttributB,
                    cddSort: this.newCustomRollSort,
                    id: nid,
                    cddPicture: this.newCustomRollPic
                }
                customRolls.push(newRoll)

                updateDoc(docRef, {
                    customRolls: customRolls
                })
                this.$store.commit('callSnackbar', newRoll.cddname + " wurde gespeichert.")
            } catch (err) {
                console.error("Fehler beim Speichern des Dokuments: ", err);
            }
        },

        saveCustomRoll() {
            if (!this.$store.state.activeCharID) {
                console.error("Keine activeCharID verfügbar.")
                return
            }

            if (!this.activeCustomRollId) {
                console.error("Keine ActiveCustomRollId verfügbar.")
                return
            }

            try {
                const db = getFirestore()
                const docRef = doc(db, "Charaktere", this.$store.state.activeCharID)

                let customRolls = this.customRolls || []
                let index = customRolls.findIndex(obj => obj.id === this.activeCustomRollId)

                customRolls[index].cddname = this.Aktion
                customRolls[index].cddgeübt = this.Übung
                customRolls[index].cddexpertise = this.Expertise
                customRolls[index].cddattribut = this.newCustomRollAttribut
                customRolls[index].cddVorteil = this.Vorteil
                customRolls[index].cddNachteil = this.Nachteil
                customRolls[index].cddModifikator = this.Modifikator
                customRolls[index].cddfw20 = this.fw20
                customRolls[index].cddfw12 = this.fw12
                customRolls[index].cddfw10 = this.fw10
                customRolls[index].cddfw8 = this.fw8
                customRolls[index].cddfw6 = this.fw6
                customRolls[index].cddfw4 = this.fw4
                customRolls[index].cddfMod = this.fMod
                customRolls[index].cddShowHeadline = this.showHeadline
                customRolls[index].cddfAttribut = this.newCustomRollAttributB
                customRolls[index].cddSort = this.newCustomRollSort
                customRolls[index].cddPicture = this.newCustomRollPic

                updateDoc(docRef, {
                    customRolls: customRolls
                })
                this.$store.commit('callSnackbar', customRolls[index].cddname + " wurde gespeichert.")
            } catch (err) {
                console.error("Fehler beim Speichern des Dokuments: ", err);
            }
        },

        triggerCustomRoll(event, cRoll = []) {
            if (!this.$store.state.activeCharID) {
                console.error("Keine activeCharID verfügbar.")
                return
            }

            try {
                if (event.button === 2 && event.altKey) {
                    const db = getFirestore()
                    const docRef = doc(db, "Charaktere", this.$store.state.activeCharID)
                    let customRolls = this.customRolls
                    let index = customRolls.findIndex(obj => obj.id === cRoll.id)
                    if (index !== -1) {
                        customRolls.splice(index, 1)
                    }
                    this.customRolls = customRolls
                    updateDoc(docRef, {
                        customRolls: customRolls
                    })
                    return
                }
            } catch (err) {
                console.error("Fehler beim Löschen der Geispeicherten Probe: ", err)
            }

            this.Übung = cRoll.cddgeübt
            this.Expertise = cRoll.cddexpertise
            this.Vorteil = cRoll.cddVorteil
            this.Nachteil = cRoll.cddNachteil
            this.Modifikator = cRoll.cddModifikator
            this.Aktion = cRoll.cddname
            this.fw20 = cRoll.cddfw20
            this.fw12 = cRoll.cddfw12
            this.fw10 = cRoll.cddfw10
            this.fw8 = cRoll.cddfw8
            this.fw6 = cRoll.cddfw6
            this.fw4 = cRoll.cddfw4
            this.fMod = cRoll.cddfMod
            this.newCustomRollAttributB = cRoll.cddfAttribut
            this.newCustomRollPic = cRoll.cddPicture
            this.newCustomRollSort = cRoll.cddSort
            this.newCustomRollAttribut = cRoll.cddattribut
            this.showHeadline = cRoll.cddShowHeadline
            this.activeCustomRollId = cRoll.id

            if (event.button === 2) {
                this.roll(cRoll.cddattribut)
            }
        },

        openInfoDialog() {
            this.infoDialog = true;
        },

        selectSymbol(filename) {
            this.newCustomRollPic = filename;
        },

        async fileHandler(Dateiart) {
            if (!this.fileHandle) return

            const file = this.fileHandle
            const fileRef = storageRef(storage, `${this.$store.state.activeCharID}/${file.name}`)
            try {
                await uploadBytes(fileRef, file)
                const url = await getDownloadURL(fileRef)
                this.fileHandle = null
                if (Dateiart == 'Char') { this.charPDFurl = url }
                else if (Dateiart == 'Bild') { this.charPicurl = url }
                else if (Dateiart == 'PDF1') { this.charPDF1url = url }
                else if (Dateiart == 'PDF2') { this.charPDF2url = url }
                //         this.$refs.fileInput.reset();
            } catch (err) {
                console.error('Fehler beim Hochladen der PDF', err)
            }

            if (!this.$store.state.activeCharID) {
                console.error("Keine activeCharID verfügbar.")
                return
            }
            try {
                const db = getFirestore()
                const docRef = doc(db, "Charaktere", this.$store.state.activeCharID)
                setDoc(docRef, {
                    pdfURL: this.charPDFurl,
                    picURL: this.charPicurl,
                    PDF1URL: this.charPDF1url,
                    PDF2URL: this.charPDF2url,
                }, { merge: true })
            } catch (err) {
                console.error("Fehler beim Speichern des Dokuments: ", err)
            }
        }
    },
}


</script>

<style>
.button-wuerfel {
    margin-bottom: 12px;
    cursor: pointer;
    height: 32px;
    opacity: 0.6;
    transition: opacity 0.2s ease;
}

.button-wuerfel:hover {
    opacity: 1.0;
    cursor: pointer;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}

.fade-enter-from,
.fade-leave-to {
    opacity: 0;
}

.fade-enter-to,
.fade-leave-from {
    opacity: 1;
}

.activeCustomRoll {
    background-color: #ccff90;
}
</style>