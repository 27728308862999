<template>
      <NavBars />
      <WuerfeltoolTab />
</template>

<script>
import { defineComponent } from 'vue';

// Components
import NavBars from '@/components/NavBars.vue';
import WuerfeltoolTab from '@/components/WuerfeltoolTab.vue';

export default defineComponent({
  name: 'wTool',

  components: {
    NavBars, WuerfeltoolTab
  },
});
</script>
