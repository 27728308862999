import store from '@/store/store.js';
import { getFirestore, collection, addDoc, serverTimestamp } from 'firebase/firestore';

export async function sendMessageToHistory(Message) {
    let name = 'Annonym'
    let sessionID = ''
    let userid = ''

    if (store.state.User) {
        userid = store.state.User.uid
        name = store.state.User.displayName
    }

    if (store.state.activeChar) {
        name = store.state.activeChar.Name
    }

    if (store.state.Einstellungen && store.state.Einstellungen.sessionId) {
        sessionID = store.state.Einstellungen.sessionId
    } else {
        sessionID = userid
    }

    const db = getFirestore();
    const colRefMsg = collection(db, 'Nachrichten')
    await addDoc(colRefMsg, {
        message: Message,
        name: name,
        sessionId: sessionID,
        userid: userid,
        CreatedAt: serverTimestamp()
    })
}