export function dndProben() {
    const Proben = [
        {
            id: 'U_id_0010', cddname: 'Angriffe', cddgeübt: false, cddexpertise: false, cddattribut: 'ohne', cddVorteil: false,
            cddNachteil: false, cddModifikator: '', cddfw20: 0, cddfw12: 0, cddfw10: 0, cddfw8: 0, cddfw6: 0, cddfw4: 0, cddfMod: 0,
            cddShowHeadline: true, cddfAttribut: 'ohne', cddSort: '10', cddPicture: 'Leer'
        },
        {
            id: 'A_id_0011', cddname: 'Initiative', cddgeübt: false, cddexpertise: false, cddattribut: 'Ges', cddVorteil: false,
            cddNachteil: false, cddModifikator: '', cddfw20: 0, cddfw12: 0, cddfw10: 0, cddfw8: 0, cddfw6: 0, cddfw4: 0, cddfMod: 0,
            cddShowHeadline: false, cddfAttribut: 'ohne', cddSort: '11', cddPicture: 'Stiefel'
        },


        {
            id: 'U_id_0030', cddname: 'Rettungswürfe', cddgeübt: false, cddexpertise: false, cddattribut: 'ohne', cddVorteil: false,
            cddNachteil: false, cddModifikator: '', cddfw20: 0, cddfw12: 0, cddfw10: 0, cddfw8: 0, cddfw6: 0, cddfw4: 0, cddfMod: 0,
            cddShowHeadline: true, cddfAttribut: 'ohne', cddSort: '30', cddPicture: 'Leer'
        },
        {
            id: 'R_id_0031', cddname: 'RW Stärke', cddgeübt: false, cddexpertise: false, cddattribut: 'Str', cddVorteil: false,
            cddNachteil: false, cddModifikator: '', cddfw20: 0, cddfw12: 0, cddfw10: 0, cddfw8: 0, cddfw6: 0, cddfw4: 0, cddfMod: 0,
            cddShowHeadline: false, cddfAttribut: 'ohne', cddSort: '31', cddPicture: 'Faust'
        },
        {
            id: 'R_id_0032', cddname: 'RW Geschick', cddgeübt: false, cddexpertise: false, cddattribut: 'Ges', cddVorteil: false,
            cddNachteil: false, cddModifikator: '', cddfw20: 0, cddfw12: 0, cddfw10: 0, cddfw8: 0, cddfw6: 0, cddfw4: 0, cddfMod: 0,
            cddShowHeadline: false, cddfAttribut: 'ohne', cddSort: '32', cddPicture: 'Stiefel'
        },
        {
            id: 'R_id_0033', cddname: 'RW Konstitution', cddgeübt: false, cddexpertise: false, cddattribut: 'Kon', cddVorteil: false,
            cddNachteil: false, cddModifikator: '', cddfw20: 0, cddfw12: 0, cddfw10: 0, cddfw8: 0, cddfw6: 0, cddfw4: 0, cddfMod: 0,
            cddShowHeadline: false, cddfAttribut: 'ohne', cddSort: '33', cddPicture: 'Herz'
        },
        {
            id: 'R_id_0034', cddname: 'RW Intelligenz', cddgeübt: false, cddexpertise: false, cddattribut: 'Int', cddVorteil: false,
            cddNachteil: false, cddModifikator: '', cddfw20: 0, cddfw12: 0, cddfw10: 0, cddfw8: 0, cddfw6: 0, cddfw4: 0, cddfMod: 0,
            cddShowHeadline: false, cddfAttribut: 'ohne', cddSort: '34', cddPicture: 'Intelligenz'
        },
        {
            id: 'R_id_0035', cddname: 'RW Weisheit', cddgeübt: false, cddexpertise: false, cddattribut: 'Wei', cddVorteil: false,
            cddNachteil: false, cddModifikator: '', cddfw20: 0, cddfw12: 0, cddfw10: 0, cddfw8: 0, cddfw6: 0, cddfw4: 0, cddfMod: 0,
            cddShowHeadline: false, cddfAttribut: 'ohne', cddSort: '35', cddPicture: 'Meditation'
        },
        {
            id: 'R_id_0036', cddname: 'RW Charisma', cddgeübt: false, cddexpertise: false, cddattribut: 'Cha', cddVorteil: false,
            cddNachteil: false, cddModifikator: '', cddfw20: 0, cddfw12: 0, cddfw10: 0, cddfw8: 0, cddfw6: 0, cddfw4: 0, cddfMod: 0,
            cddShowHeadline: false, cddfAttribut: 'ohne', cddSort: '36', cddPicture: 'Sozial'
        },


        {
            id: 'U_id_0040', cddname: 'Fertigkeiten', cddgeübt: false, cddexpertise: false, cddattribut: 'ohne', cddVorteil: false,
            cddNachteil: false, cddModifikator: '', cddfw20: 0, cddfw12: 0, cddfw10: 0, cddfw8: 0, cddfw6: 0, cddfw4: 0, cddfMod: 0,
            cddShowHeadline: true, cddfAttribut: 'ohne', cddSort: '40', cddPicture: 'Leer'
        },
        {
            id: 'F_id_0041', cddname: 'Akrobatik', cddgeübt: false, cddexpertise: false, cddattribut: 'Ges', cddVorteil: false,
            cddNachteil: false, cddModifikator: '', cddfw20: 0, cddfw12: 0, cddfw10: 0, cddfw8: 0, cddfw6: 0, cddfw4: 0, cddfMod: 0,
            cddShowHeadline: false, cddfAttribut: 'ohne', cddSort: '41', cddPicture: 'Stiefel'
        },
        {
            id: 'F_id_0042', cddname: 'Arkane Kunde', cddgeübt: false, cddexpertise: false, cddattribut: 'Int', cddVorteil: false,
            cddNachteil: false, cddModifikator: '', cddfw20: 0, cddfw12: 0, cddfw10: 0, cddfw8: 0, cddfw6: 0, cddfw4: 0, cddfMod: 0,
            cddShowHeadline: false, cddfAttribut: 'ohne', cddSort: '42', cddPicture: 'Psycho'
        },
        {
            id: 'F_id_0043', cddname: 'Athletik', cddgeübt: false, cddexpertise: false, cddattribut: 'Str', cddVorteil: false,
            cddNachteil: false, cddModifikator: '', cddfw20: 0, cddfw12: 0, cddfw10: 0, cddfw8: 0, cddfw6: 0, cddfw4: 0, cddfMod: 0,
            cddShowHeadline: false, cddfAttribut: 'ohne', cddSort: '43', cddPicture: 'Stiefel'
        },
        {
            id: 'F_id_0044', cddname: 'Auftreten', cddgeübt: false, cddexpertise: false, cddattribut: 'Cha', cddVorteil: false,
            cddNachteil: false, cddModifikator: '', cddfw20: 0, cddfw12: 0, cddfw10: 0, cddfw8: 0, cddfw6: 0, cddfw4: 0, cddfMod: 0,
            cddShowHeadline: false, cddfAttribut: 'ohne', cddSort: '44', cddPicture: 'Sozial'
        },
        {
            id: 'F_id_0045', cddname: 'Einschüchtern', cddgeübt: false, cddexpertise: false, cddattribut: 'Cha', cddVorteil: false,
            cddNachteil: false, cddModifikator: '', cddfw20: 0, cddfw12: 0, cddfw10: 0, cddfw8: 0, cddfw6: 0, cddfw4: 0, cddfMod: 0,
            cddShowHeadline: false, cddfAttribut: 'ohne', cddSort: '45', cddPicture: 'Mund'
        },
        {
            id: 'F_id_0046', cddname: 'Fingerfertigkeit', cddgeübt: false, cddexpertise: false, cddattribut: 'Ges', cddVorteil: false,
            cddNachteil: false, cddModifikator: '', cddfw20: 0, cddfw12: 0, cddfw10: 0, cddfw8: 0, cddfw6: 0, cddfw4: 0, cddfMod: 0,
            cddShowHeadline: false, cddfAttribut: 'ohne', cddSort: '46', cddPicture: 'Faust'
        },
        {
            id: 'F_id_0047', cddname: 'Geschichte', cddgeübt: false, cddexpertise: false, cddattribut: 'Int', cddVorteil: false,
            cddNachteil: false, cddModifikator: '', cddfw20: 0, cddfw12: 0, cddfw10: 0, cddfw8: 0, cddfw6: 0, cddfw4: 0, cddfMod: 0,
            cddShowHeadline: false, cddfAttribut: 'ohne', cddSort: '47', cddPicture: 'Zauberbuch'
        },
        {
            id: 'F_id_0048', cddname: 'Heilkunde', cddgeübt: false, cddexpertise: false, cddattribut: 'Wei', cddVorteil: false,
            cddNachteil: false, cddModifikator: '', cddfw20: 0, cddfw12: 0, cddfw10: 0, cddfw8: 0, cddfw6: 0, cddfw4: 0, cddfMod: 0,
            cddShowHeadline: false, cddfAttribut: 'ohne', cddSort: '48', cddPicture: 'Herz'
        },
        {
            id: 'F_id_0049', cddname: 'Heimlichkeit', cddgeübt: false, cddexpertise: false, cddattribut: 'Ges', cddVorteil: false,
            cddNachteil: false, cddModifikator: '', cddfw20: 0, cddfw12: 0, cddfw10: 0, cddfw8: 0, cddfw6: 0, cddfw4: 0, cddfMod: 0,
            cddShowHeadline: false, cddfAttribut: 'ohne', cddSort: '49', cddPicture: 'Umhang'
        },
        {
            id: 'F_id_0050', cddname: 'Mit Tieren umgehen', cddgeübt: false, cddexpertise: false, cddattribut: 'Wei', cddVorteil: false,
            cddNachteil: false, cddModifikator: '', cddfw20: 0, cddfw12: 0, cddfw10: 0, cddfw8: 0, cddfw6: 0, cddfw4: 0, cddfMod: 0,
            cddShowHeadline: false, cddfAttribut: 'ohne', cddSort: '50', cddPicture: 'Wolf'
        },
        {
            id: 'F_id_0051', cddname: 'Motiv erkennen', cddgeübt: false, cddexpertise: false, cddattribut: 'Wei', cddVorteil: false,
            cddNachteil: false, cddModifikator: '', cddfw20: 0, cddfw12: 0, cddfw10: 0, cddfw8: 0, cddfw6: 0, cddfw4: 0, cddfMod: 0,
            cddShowHeadline: false, cddfAttribut: 'ohne', cddSort: '51', cddPicture: 'Sozial'
        },
        {
            id: 'F_id_0052', cddname: 'Nachforschungen', cddgeübt: false, cddexpertise: false, cddattribut: 'Int', cddVorteil: false,
            cddNachteil: false, cddModifikator: '', cddfw20: 0, cddfw12: 0, cddfw10: 0, cddfw8: 0, cddfw6: 0, cddfw4: 0, cddfMod: 0,
            cddShowHeadline: false, cddfAttribut: 'ohne', cddSort: '52', cddPicture: 'Auge'
        },
        {
            id: 'F_id_0053', cddname: 'Naturkunde', cddgeübt: false, cddexpertise: false, cddattribut: 'Int', cddVorteil: false,
            cddNachteil: false, cddModifikator: '', cddfw20: 0, cddfw12: 0, cddfw10: 0, cddfw8: 0, cddfw6: 0, cddfw4: 0, cddfMod: 0,
            cddShowHeadline: false, cddfAttribut: 'ohne', cddSort: '53', cddPicture: 'Uberleben'
        },
        {
            id: 'F_id_0054', cddname: 'Religion', cddgeübt: false, cddexpertise: false, cddattribut: 'Int', cddVorteil: false,
            cddNachteil: false, cddModifikator: '', cddfw20: 0, cddfw12: 0, cddfw10: 0, cddfw8: 0, cddfw6: 0, cddfw4: 0, cddfMod: 0,
            cddShowHeadline: false, cddfAttribut: 'ohne', cddSort: '54', cddPicture: 'Heilig'
        },
        {
            id: 'F_id_0055', cddname: 'Täuschen', cddgeübt: false, cddexpertise: false, cddattribut: 'Cha', cddVorteil: false,
            cddNachteil: false, cddModifikator: '', cddfw20: 0, cddfw12: 0, cddfw10: 0, cddfw8: 0, cddfw6: 0, cddfw4: 0, cddfMod: 0,
            cddShowHeadline: false, cddfAttribut: 'ohne', cddSort: '55', cddPicture: 'Umhang'
        },
        {
            id: 'F_id_0056', cddname: 'Überlebenskunst', cddgeübt: false, cddexpertise: false, cddattribut: 'Wei', cddVorteil: false,
            cddNachteil: false, cddModifikator: '', cddfw20: 0, cddfw12: 0, cddfw10: 0, cddfw8: 0, cddfw6: 0, cddfw4: 0, cddfMod: 0,
            cddShowHeadline: false, cddfAttribut: 'ohne', cddSort: '56', cddPicture: 'Uberleben'
        },
        {
            id: 'F_id_0057', cddname: 'Überzeugen', cddgeübt: false, cddexpertise: false, cddattribut: 'Cha', cddVorteil: false,
            cddNachteil: false, cddModifikator: '', cddfw20: 0, cddfw12: 0, cddfw10: 0, cddfw8: 0, cddfw6: 0, cddfw4: 0, cddfMod: 0,
            cddShowHeadline: false, cddfAttribut: 'ohne', cddSort: '57', cddPicture: 'Sozial'
        },
        {
            id: 'F_id_0058', cddname: 'Wahrnehmung', cddgeübt: false, cddexpertise: false, cddattribut: 'Wei', cddVorteil: false,
            cddNachteil: false, cddModifikator: '', cddfw20: 0, cddfw12: 0, cddfw10: 0, cddfw8: 0, cddfw6: 0, cddfw4: 0, cddfMod: 0,
            cddShowHeadline: false, cddfAttribut: 'ohne', cddSort: '58', cddPicture: 'Auge'
        },

        /*leere roll
            { id: '', cddname: '', cddgeübt: false, cddexpertise: false, cddattribut: 'ohne', cddVorteil: false, 
                cddNachteil: false, cddModifikator: '', cddfw20: 0, cddfw12: 0, cddfw10: 0, cddfw8: 0, cddfw6: 0, cddfw4: 0, cddfMod: 0, 
                cddShowHeadline: false, cddfAttribut: 'ohne', cddSort: '0', cddPicture: 'Leer' },
                */
    ]
    return Proben
}